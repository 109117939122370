<template>
	<!--  -->
	<!-- <el-dialog :title="$t('i18nn_2a2e2fbcc567efc6')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1000px" top="0" v-loading="loadingUpload"> -->
	<el-drawer :wrapperClosable="false" :title="'批量上传附件'" append-to-body :visible.sync="dialogFile" :direction="'rtl'"
		size="1000px" v-loading="loadingUpload">

		<div style="width: 800px; margin: 0 auto;">
			<el-steps :active="stepActive">
				<el-step :title="'开始匹配'" description="文件名包含对应编号"></el-step>
				<el-step :title="'开始上传'"></el-step>
				<el-step :title="'上传完成'"></el-step>
			</el-steps>
		</div>

		<div style="padding: 10px;" v-if="0==stepActive">
			<div style="padding: 10px;  line-height: 180%;">
				<el-alert type="warning">
					<div slot="title" style="font-size: 16px; ">{{$t('i18nn_daaaeb1b7b22b126')}}</div>
					<div style="font-size: 14px; ">
						<div>
							1、如果文件是快递面单，优先匹配明细中的发货编号；
						</div>
						<div>
							2、如果是箱唛，会优先匹配明细中的箱唛号，如果没有匹配到，最后会去匹配发货编号；
						</div>
						<div>
							3、如果是SKU标签，优先匹配SKU编号，匹配不到最后会匹配发货编号；
						</div>
						<div>
							4、其他的附件只匹配发货编号；
						</div>
					</div>
				</el-alert>
			</div>


			<el-card>
				<div slot="header" class="">
					<h3>
						<i class="el-icon-folder-opened"></i>
						<span>选择附件</span>
					</h3>
				</div>
				<div style="">

					<div style="padding: 10px 0; ">
						<el-radio-group v-model="wh_file_type" size="medium" style="margin-bottom: 10px;" @change="fileTypeChange">
							<el-radio border v-for="item in selectOption.wh_file_type" :key="item.code" :label="item.code">
								{{ $Utils.i18nCodeText(item) }}
							</el-radio>
						</el-radio-group>
					</div>

					<div style="">
						<HyElWhUpLoad ref="HyElWhUpLoad" :listType="'picture'" :showFileList="false" :watchKey="fileWatchKey"
							:fileListShow="fileList" :fileId="fileId" :folder="'storage/attachment/'" :fileKey="fileKey"
							:bucket="'hytx-oss-wh'" :accept="''" :maxSizeMB="50" :limit="20" :fileTagData="fileTagData"
							v-on:UploadSuccessUrl="changeFileUrl">
						</HyElWhUpLoad>
					</div>
				</div>
			</el-card>
			<!-- <el-card>
				<div slot="header" class="">
					<h3>
						<i class="el-icon-tickets"></i>
						<span>待匹配的发货编号</span>
					</h3>
				</div>
				<div style="">
					<el-tag effect="plain" v-for="(item,index) in selTableData" :key="index">
						{{item.sendNo}}
					</el-tag>
				</div>
			</el-card> -->
			<div style="text-align: center; margin-top: 10px;">
				<el-button type="warning" @click="startMatchAction()" icon="el-icon-bottom">
					开始匹配
				</el-button>
			</div>
		</div>

		<div style="margin-top: 10px;" v-else-if="1==stepActive">
			<el-card :body-style="{ padding: '0px' }">
				<div slot="header" class="">
					<h3>
						<i class="el-icon-tickets"></i>
						<span>匹配结果</span>
					</h3>
				</div>
				<div>

					<el-tabs type="border-card">
						<el-tab-pane>
							<div slot="label">
								<span>
									成功
								</span>
								<el-badge :value="matcingSuccessTotal"></el-badge>
							</div>
							<div>
								<el-table ref="multipleTable1" :data="tableData.matcingSuccess" stripe :border="true"
									style="width: 100%" size="small" :max-height="$store.state.frameConHeightWh3">
									<el-table-column type="index" fixed="left" width="50" align="center"
										:label="$t('Storage.tableColumn.no')"></el-table-column>

									<!-- <el-table-column prop="matchingStatusName" :label="$t('i18nn_582446272516f554')" width="100">
										<template slot-scope="scope">
											<div>
												<el-tag type="success" v-if="'1'==scope.row.matchingStatus">
													{{ $Utils.i18nKeyText(scope.row,'matchingStatusName') }}</el-tag>
												<el-tag type="danger" v-else-if="'0'==scope.row.matchingStatus">
													{{ $Utils.i18nKeyText(scope.row,'matchingStatusName') }}</el-tag>
												<el-tag type="danger" v-else>{{ $Utils.i18nKeyText(scope.row,'matchingStatusName') }}</el-tag>
											</div>
										</template>
									</el-table-column> -->

									<el-table-column prop="sendNo" :label="'发货编号'"></el-table-column>
									<el-table-column prop="sysOrderNo" :label="'系统编号'"></el-table-column>
									<el-table-column prop="matchTotal" :label="'匹配数量'"></el-table-column>

									<el-table-column prop="matchAttachments" :label="'附件'" min-width="400">
										<template slot-scope="scope">
											<div>
												<ul class="file-list-customize2">
													<li v-for="(file,index) in scope.row.matchAttachments" :key="index">
														<el-image style="width: 100px; height: 100px" :z-index="9999" :fit="'contain'"
															:src="file.url"
															:preview-src-list="scope.row.matchAttachments.map(itemPre=>{ return itemPre.url})">
															<div slot="error" class="image-slot">
																<i class="el-icon-document"></i>
															</div>
														</el-image>
														<span class="file-tag-new">
															新
														</span>
														<span class="file-tag-type">
															<el-tag :effect="'plain'" size="mini" type="warning" v-if="'10'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag :effect="'plain'" size="mini" type="success" v-else-if="'11'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag :effect="'light'" size="mini" type="warning" v-else-if="'15'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag :effect="'plain'" size="mini" type="danger" v-else-if="'20'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag :effect="'light'" size="mini" type="primary" v-else-if="'30'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag :effect="'plain'" size="mini" type="primary" v-else-if="'90'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag :effect="'plain'" size="mini" type="info" v-else>
																{{file.typeName}}
															</el-tag>
														</span>
														<span class="file-c-name">{{file.fileName}}</span>
														<!-- <el-link type="warning" class="" @click="handleRemove(file,index)">
															<i class="el-icon-delete"></i>{{$t('i18nn_e33c9b93c36fd250')}}</el-link> -->
													</li>
												</ul>
												<ul class="file-list-customize2">
													<li v-for="(file,index) in scope.row.attachments" :key="index">
														<el-image style="width: 100px; height: 100px;" :z-index="9999" :fit="'contain'"
															:src="file.url"
															:preview-src-list="scope.row.attachments.map(itemPre=>{ return itemPre.url})">
															<div slot="error" class="image-slot">
																<i class="el-icon-document"></i>
															</div>
														</el-image>

														<span class="file-tag-type">
															<el-tag :effect="'plain'" size="mini" type="warning" v-if="'10'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag :effect="'plain'" size="mini" type="success" v-else-if="'11'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag :effect="'light'" size="mini" type="warning" v-else-if="'15'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag :effect="'plain'" size="mini" type="danger" v-else-if="'20'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag :effect="'light'" size="mini" type="primary" v-else-if="'30'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag :effect="'plain'" size="mini" type="primary" v-else-if="'90'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag :effect="'plain'" size="mini" type="info" v-else>
																{{file.typeName}}
															</el-tag>
														</span>
														<span class="file-c-name">{{file.fileName}}</span>
													</li>
												</ul>
											</div>
										</template>
									</el-table-column>

									<!-- <el-table-column prop="attachments" :label="'原附件'" min-width="150">
										<template slot-scope="scope">
											<div>
												<ul class="file-list-customize2">
													<li v-for="(file,index) in scope.row.attachments" :key="index">
														<el-image style="width: 50px; height: 50px" :z-index="9999" :fit="'contain'" :src="file.url"
															:preview-src-list="scope.row.attachments.map(itemPre=>{ return itemPre.url})">
															<div slot="error" class="image-slot">
																<i class="el-icon-document"></i>
															</div>
														</el-image>
														<span>
															<el-tag :effect="'plain'" type="warning" v-if="'10'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag  :effect="'plain'" type="success" v-else-if="'11'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag  :effect="'light'" type="warning" v-else-if="'15'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag  :effect="'plain'" type="danger" v-else-if="'20'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag  :effect="'light'" type="primary" v-else-if="'30'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag  :effect="'plain'" type="primary" v-else-if="'90'==file.type">
																{{file.typeName}}
															</el-tag>
															<el-tag  :effect="'plain'" type="info" v-else>
																{{file.typeName}}
															</el-tag>
														</span>
														<span class="file-c-name">{{file.fileName}}</span>
													</li>
												</ul>
											</div>
										</template>
									</el-table-column> -->



									<!-- <el-table-column prop="type" :label="'类型'"></el-table-column>
									<el-table-column prop="fileName" :label="'文件名'"></el-table-column>
									
									<el-table-column prop="file" :label="'预览'">
										<template slot-scope="scope">
											<el-image style="width: 50px; height: 50px" :z-index="9999" :fit="'contain'" :src="scope.row.url"
												:preview-src-list="tableData.matcingSuccess.map(itemPre=>{ return itemPre.url})">
												<div slot="error" class="image-slot">
													<i class="el-icon-document"></i>
												</div>
											</el-image>
										</template>
									</el-table-column> -->

									<!-- <el-table-column prop="url" :label="'链接'" show-overflow-tooltip>
										<template slot-scope="scope">
											<a :href="scope.row.url" target="_blank">{{ scope.row.url }}</a>
										</template>
									</el-table-column> -->
								</el-table>
							</div>
						</el-tab-pane>
						<el-tab-pane>
							<div slot="label">
								<span>
									失败
								</span>
								<el-badge :value="tableData.matchingFail.length"></el-badge>
							</div>
							<div>
								<el-table ref="multipleTable2" :data="tableData.matchingFail" stripe :border="true" style="width: 100%"
									size="small" :max-height="$store.state.frameConHeightWh3">
									<el-table-column type="index" fixed="left" width="50" align="center"
										:label="$t('Storage.tableColumn.no')"></el-table-column>

									<el-table-column prop="type" :label="'类型'"></el-table-column>
									<el-table-column prop="fileName" :label="'文件名'"></el-table-column>

									<el-table-column prop="file" :label="'预览'">
										<template slot-scope="scope">
											<el-image style="width: 50px; height: 50px" :z-index="9999" :fit="'contain'" :src="scope.row.url"
												:preview-src-list="tableData.matchingFail.map(itemPre=>{ return itemPre.url})">
												<div slot="error" class="image-slot">
													<i class="el-icon-document"></i>
												</div>
											</el-image>
										</template>
									</el-table-column>

									<el-table-column prop="url" :label="'链接'" show-overflow-tooltip>
										<template slot-scope="scope">
											<a :href="scope.row.url" target="_blank">{{ scope.row.url }}</a>
										</template>
									</el-table-column>

								</el-table>
							</div>
						</el-tab-pane>
					</el-tabs>


				</div>
			</el-card>
			<div style="text-align: center; margin-top: 10px;">
				<el-button type="warning" plain @click="reUploadAction()" icon="el-icon-top">
					<span>{{$t('i18nn_70e058bc23d52c1e')}}</span>(<span>{{$t('i18nn_aaf7f7f3df61a6a5')}}</span>)</el-button>
				<el-button type="primary" @click="submitSaveFormAction()" icon="el-icon-bottom">
					<span>{{$t('i18nn_4639f3a6e07c00b3')}}</span>(<span>保存匹配成功结果</span>)</el-button>
			</div>
		</div>
		<div v-else-if="2==stepActive">
			<div style="font-size: 24px;line-height: 200%; margin: 20px; text-align: center;">
				<div>
					<i class="el-icon-circle-check"></i>
					<span>提交成功</span>~
				</div>
				<el-button type="primary" @click="closeAction()">{{$t('i18nn_8f2e8ec212959586')}}</el-button>
			</div>

		</div>



		<!-- <div class="drawer-footer">
			<el-button type="primary" icon="el-icon-tickets"
				@click="submitExcelFormAction()">{{ $t('FormMsg.Save') }}</el-button>
		</div> -->
	</el-drawer>
	<!-- </el-dialog> -->
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';

	import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	export default {
		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		props: {
			// mobile:"",
			// isShow: {
			//   default: function() {
			//     return false;
			//   },
			//   type: Boolean
			// },
			// fileId: {
			//     default: function() {
			//       return this.$store.getters.getUserInfo.id;
			//     },
			//     type: String
			//   },
			fileKey: {
				default: function() {
					return 'file';
				},
				type: String
			},
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			// relationId: {
			// 	default: function() {
			// 		return '';
			// 	},
			// 	type: String
			// },
			selTableData: {
				default: function() {
					return [];
				},
				type: Array
			},
			// isShowSelTypes:{
			// 	default: function() {
			// 	  return false;
			// 	},
			// 	type: Boolean
			// }
		},
		components: {
			HyElWhUpLoad
		},
		data() {
			return {
				stepActive: 0,

				dialogFile: false,
				loadingUpload: false,
				//附件
				fileWatchKey: '1',
				fileList: [],
				fileId: this.$store.getters.getUserInfo.id,

				fileTagData: {
					type: "90",
					typeName: "其他附件"
				},

				wh_file_type: "90",

				saveErr: false,

				listUrl: [],
				// ready_fileList: [],
				matcingSuccessTotal:0,
				tableData: {
					matchingFail: [],
					matcingSuccess: []
				},
				fileCountAll: '',
				selectOption: {
					wh_file_type: []
				},
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();

		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			// if(this.isShowSelTypes){
			getDicData(['wh_file_type'],
				(data) => {
					this.selectOption.wh_file_type = data['wh_file_type'];
				});
			// }

			this.initData();
		},
		methods: {
			initData() {
				this.stepActive = 0;

				//初始化上传控件
				this.listUrl = [];
				this.tableData = {
					matchingFail: [],
					matcingSuccess: []
				};
				this.fileCountAll = '';
				this.wh_file_type = "90";

				this.$nextTick(() => {
					try {
						this.$refs.HyElWhUpLoad.initData();
						this.$refs.HyElWhUpLoad.clearFileList();
					} catch (err) {
						console.log(err);
					}
				});
				// if (this.relationId) {
				// 	this.getListData();
				// }
			},
			//文件类型改变
			fileTypeChange() {
				let typeObj = this.selectOption.wh_file_type.find(item => {
					return item.code == this.wh_file_type
				});
				this.fileTagData = {
					type: this.wh_file_type ? this.wh_file_type : '90',
					typeName: typeObj.codeText
				}
			},

			//开始匹配
			startMatchAction() {
				if (this.saveErr) {
					//保存到后台有误时
					this.submitExcelFormData();
				} else {
					//重新执行-oss 文件上传及回调
					this.$nextTick(() => {
						try {
							this.$refs.HyElWhUpLoad.submitUpload();
						} catch (err) {
							console.log(err);
						}
					});
				}
			},
			//上传回调
			changeFileUrl(val) {
				console.log('changeFileUrl', val);
				let listUrl = val.map(item => {
					return {
						type: item.fileTagData ? item.fileTagData.type : '90',
						filePath: item.url,
						fileName: item.name
					};
				});
				console.log('listUrl', listUrl);
				this.listUrl = listUrl;
				this.submitExcelFormData();
			},
			submitExcelFormData() {
				let formData = [];
				this.listUrl.forEach(item => {
					if (item.fileName && item.filePath) {
						formData.push({
							type: item.type,
							fileName: item.fileName,
							url: item.filePath
						});
					}
				});
				console.log('formData', formData);
				// return;
				this.submitExcelForm(formData);
			},
			//开始匹配
			submitExcelForm(fileList) {
				this.matcingSuccessTotal = 0;
				this.tableData = {
					matchingFail: [],
					matcingSuccess: []
				}
				this.loadingUpload = true;
				let formData = {
					"matchAttachments": fileList,
					"ids": this.selTableData.map(item => {
						return item.id
					})
				}
				this.$http
					.put(this.$urlConfig.WhShipmentsUploadFileMatch, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						this.loadingUpload = false;
						if (200 == data.code) {
							this.stepActive = 1;
							this.saveErr = false;
							this.tableData = data.data;
							
							if(this.tableData.matcingSuccess){
								let matcingSuccessTotal = 0;
								this.tableData.matcingSuccess.map(item=>{
									matcingSuccessTotal = matcingSuccessTotal+item.matchAttachments.length;
								});
								this.matcingSuccessTotal = matcingSuccessTotal;
							}
							
						} else {
							this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
							this.saveErr = true;
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loadingUpload = false;
						this.saveErr = true;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					});
			},
			//重新上传
			reUploadAction() {
				this.initData();
			},
			//保存
			submitSaveFormAction() {
				let formData = [];
				this.tableData.matcingSuccess.forEach(item => {
					item.matchAttachments && item.matchAttachments.forEach(item2 => {
						if (item.id && item2.url) {
							formData.push({
								"type": item2.type, //"数据ID",
								"fileName": item2.fileName, //this.$t('i18nn_ae61193590bd513d'),
								"url": item2.url,
								"relationId": item.id, //"label文件地址"
							});
						}
					})

				});
				if (formData.length <= 0) {
					this.$message.warning(this.$t('tips.no_match_label'))
					return;
				}
				// this.$confirm()
				this.$confirm(`成功匹配 ${formData.length} 个附件，是否继续?`, this.$t('tips.tipsTitle'), {
						type: 'success'
					})
					.then(() => {
						this.submitSaveForm(formData);
					})
					.catch(() => {

					});


			},
			//保存
			submitSaveForm(formData) {
				this.loadingUpload = true;
				this.$http
					.put(this.$urlConfig.WhShipmentsUploadFileMatchRel, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						this.loadingUpload = false;
						if (200 == data.code) {
							this.stepActive = 2;
							this.$message.success(this.$t('tips.submitSuccess'));
							this.$emit('success');
						} else {
							this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
								type: 'warning'
								//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
							});
							// this.saveErr = true;
							// this.initData();
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loadingUpload = false;
						// this.saveErr = true;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					});
			},

			closeAction() {
				this.$emit('close');
				this.dialogFile = false;
			},

			//删除
			// delAction(event, row) {
			// 	event.stopPropagation();
			// 	this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			this.delData(this.$urlConfig.WhFileUploadDel + '/' + row.id, row);
			// 		})
			// 		.catch(() => {

			// 		});
			// },
			//提交信息
			// delData(url, formData) {
			// 	this.loadingUpload = true;
			// 	this.$http
			// 		.delete(url, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			this.loadingUpload = false;
			// 			if (200 == data.code) {
			// 				this.getListData();
			// 			} else {
			// 				this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.submitError'));
			// 			this.loadingUpload = false;
			// 			this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			});
			// 		});
			// },
			//查询 /wh/bizWhFile/query/{relationId}
			// getListData() {
			// 	this.loadingUpload = true;
			// 	let url = '';
			// 	url = this.$urlConfig.WhFileUploadList + '/all/' + this.relationId;

			// 	this.$http
			// 		.get(url, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_4195755cd53e871a'));
			// 			console.log(data);
			// 			this.loadingUpload = false;
			// 			if (200 == data.code) {
			// 				// if (data.rows) {
			// 				this.tableData = data.rows;
			// 				this.fileCountAll = this.tableData.reduce((prev, curr, idx, arr) => {
			// 					return prev + curr.records.length;
			// 				}, 0);
			// 			} else {
			// 				this.$alert(this.$t('tips.queryErrorFailed'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.submitError'));
			// 			this.loadingUpload = false;
			// 			this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },
			//查询数据字典
			// getDicData() {
			//   // let _this = this;
			//   // console.log(keyword);

			//   // this.loading_load = true;
			//   this.$http
			//     .put(this.$urlConfig.HyDicQueryList, ['wh_file_type'])
			//     .then(({ data }) => {
			//       console.log('查询数据字典，请求成功');
			//       console.log(data);
			//       if (200 == data.code && data.data) {
			//         this.selectOption.wh_file_type = data.data['wh_file_type'];
			//       } else {
			//         // if (!data.msg) {
			//         //   data.msg = this.$t("tips.submitError");
			//         // }
			//         // this.$message.warning(data.msg);
			//       }
			//     })
			//     .catch(error => {
			//       console.log(error);
			//       console.log('查询数据字典接口，请求失败');
			//       // this.$message.error(this.$t("tips.submitRequestError"));
			//     });
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>