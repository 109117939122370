<template>
	<div class="">
		<el-drawer :wrapperClosable="false" :title="$t('i18nn_10bc8a2b45aa5636')" append-to-body :visible.sync="dialogFile" :custom-class="'drawerStyle1'" v-loading="loadingFile">
			<!-- <el-dialog :title="$t('i18nn_10bc8a2b45aa5636')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1000px" top="0" v-loading="loadingFile"> -->

			<!--本页切换列表-->
			<el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
				<el-tab-pane name="second">
					<div slot="label">
						<span>
							{{$t('i18nn_f80dedf7c601059f')}}
						</span>
						<el-badge :value="secondTotal"></el-badge>
					</div>
					
					<ValueAddService :openTime="openTime2" :whNo="whNo" :relationId="relationId" :qaType="type" @success="valueAddSuccessBack"></ValueAddService>
				</el-tab-pane>
				<el-tab-pane name="first">
					<div slot="label">
						<span>
							{{$t('i18nn_74e7a7ab1181812c')}}
						</span>
						<el-badge :value="firstTotal"></el-badge>
					</div>
					
			<div>
				<div class="tableCon" v-loading="loading_load" element-loading-text="loading...">
					<div class="tableConTable" v-loading="loading">
						<div class="tableConTop">
							<el-row>
								<el-col :span="5" class="tableConTopLeft">
									<el-button type="primary" icon="el-icon-plus" size="small" @click="openAddNew()">
										{{ $t('i18nn_e8564657bbe9ca53') }}
									</el-button>
								</el-col>
								<el-col :span="19" class="tableConTopRig">
									<el-button type="success" circle icon="el-icon-refresh" size="small"
										@click="initData()"></el-button>
								</el-col>
							</el-row>
						</div>

						<div>
							<div class="filterCon" style="">
								<!-- <div class="filterBarList">
								<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
								  <el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">{{item.label}}</el-menu-item>
								</el-menu>
							</div> -->
								<ul class="filterConList">
									<!-- <li>
										<span>
											{{ $t('i18nn_29991afa9781d554') }}
										</span>
										<cusSelFuzzy ref="cusSelFuzzy" :disabled="true" @changeData="changCus">
										</cusSelFuzzy>
									</li> -->
									<li>
										<span>
											{{ $t('i18nn_c944a6686d996ab3') }}
										</span>
										<whNoSelect ref="whNoSelect" :disabled="true" @changeData="changWhNo">
										</whNoSelect>
									</li>
									<!-- <li>
									<span>{{$t('i18nn_4dc6f04018e0f73b')}}</span>
									<el-select filterable clearable v-model="filterData.qaType"
										:placeholder="$t('i18nn_2ad108ab2c560530')" size="small" style="width: 100px;"
										@change="initData()">
										<el-option v-for="item in selectOption.wh_qa_type" :key="item.code"
											:label="$Utils.i18nCodeText(item)" :value="item.code">
											<div class="sel_option_s1" style="">
												<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
												<span class="sel_option_code" style="">{{ item.code }}</span>
											</div>
										</el-option>
									</el-select>
								</li> -->

									<li>
										<span>{{$t('i18nn_9666c59aedbfa70e')}}</span>
										<el-select filterable clearable v-model="filterData.wh_qa_biz_type"
											:placeholder="$t('i18nn_2ad108ab2c560530')" size="small" :disabled="true"
											style="width: 100px;" @change="initData()">
											<el-option v-for="item in selectOption.wh_qa_biz_type" :key="item.code"
												:label="$Utils.i18nCodeText(item)" :value="item.code">
												<div class="sel_option_s1" style="">
													<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
													<span class="sel_option_code" style="">{{ item.code }}</span>
												</div>
											</el-option>
										</el-select>
									</li>

									<li>
										<span>{{$t('i18nn_9168144190f66f5d')}}</span>:
										{{filterData.relationNo}}
										<!-- <el-input type="text" v-model="filterData.relationNo" size="small"
											:disabled="true" clearable @keyup.enter.native="initData()" maxlength="50"
											:placeholder="$t('i18nn_5a9aefbc03c778f7')" style="width: 220px;" /> -->
									</li>
									<!-- <li>
									<span>QA编号</span>
									<el-input type="text" v-model="filterData.qaNo" size="small" clearable
										@keyup.enter.native="initData()" maxlength="50"
										:placeholder="$t('i18nn_5a9aefbc03c778f7')" style="width: 220px;" />
								</li> -->

									<!-- <li>
										<el-button icon="el-icon-search" size="small" type="primary"
											@click="initData()">
											{{ $t('i18nn_1e7246dd6ccc5539') }}
										</el-button>
									</li> -->
								</ul>
								<!-- <el-select filterable clearable size="small" v-model="queryParamObj.feeType" :placeholder="$t('i18nn_5a9aefbc03c778f7')" style="width: 150px;">
                    <el-option v-for="item in selectOption.matching_fee" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ item.companyName }}</span>
				<span class="sel_option_code" style="">{{ item.cusNo }}</span>
			</div></el-option>       </el-select> -->
								<!-- </el-col> -->
								<!-- <el-col :span="5">
                  <span>undefined</span>
                  <el-select filterable clearable size="small" v-model="queryParamObj.goodsType" placeholder="undefined" style="width: 120px;">
                    <el-option v-for="item in selectOption.goods_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code">
                    </el-option>
                  </el-select>
                </el-col> -->
								<!-- <el-col :span="2"><el-button type="primary" @click="serPageData" size="small">undefined</el-button></el-col> -->
								<!-- <el-col :span="6" class="tableConTopRig">

                </el-col> -->
								<!-- </el-row> -->
							</div>
						</div>

						<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
							:max-height="$store.state.frameConHeightWh3" @row-click="handleCurrentChange"
							@selection-change="handleSelectionChange" style="width: 100%" size="small">

							<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
								:label="$t('7b1c2b1adc920d9c')"></el-table-column>
							<!-- <el-table-column type="selection" fixed="left" align="center" width="50"
							label-class-name="nodra"></el-table-column> -->

							<el-table-column prop="status" :label="$t('i18nn_6cdece641436d7ab')">
								<template slot-scope="scope">
									<div>
										<el-tag type="warning" v-if="'10' == scope.row.status">
											{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
										<el-tag type="success" v-else-if="'20' == scope.row.status">
											{{ $Utils.i18nKeyText(scope.row,'statusName') }}
										</el-tag>
										<el-tag type="primary" v-else-if="'30' == scope.row.status">
											{{ $Utils.i18nKeyText(scope.row,'statusName') }}
										</el-tag>
										<el-tag type="danger" v-else-if="'40' == scope.row.status">
											{{ $Utils.i18nKeyText(scope.row,'statusName') }}
										</el-tag>
										<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
									</div>
								</template>
							</el-table-column>


							<el-table-column prop="qaNo" :label="$t('i18nn_5e7740c52e22eefc')">
								<template slot-scope="scope">
								  <div>
								    <!-- <el-link type="primary" @click="toQA($event,scope.row)">{{scope.row.qaNo}}</el-link> -->
										
										<div class="link-copy">
											<span class="link-text" @click="toQA($event, scope.row, scope.$index)">
												{{ scope.row.qaNo }}
											</span>
											<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')" placement="top">
											  <i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.qaNo)"></i>
											</el-tooltip>
										</div>
								  </div>
								</template>
							</el-table-column>
							
							<el-table-column prop="client" :label="$t('i18nn_3af42243b3f54e9b')">
								<template slot-scope="scope">
									<div>
										<el-tag type="success" effect="plain" size="small" v-if="'SYS'==scope.row.client">{{$t('i18nn_05682dbef5eeb0a8')}}</el-tag>
										<el-tag type="warning" effect="plain" size="small" v-else-if="'BIZ'==scope.row.client">{{$t('i18nn_18d7892498938435')}}</el-tag>
										<el-tag type="info" effect="plain" size="small" v-else-if="scope.row.client">{{scope.row.client}}</el-tag>
									</div>
								</template>
							</el-table-column>
							
							<el-table-column prop="qaTypeName" :label="$t('i18nn_4dc6f04018e0f73b')"></el-table-column>
							

							<el-table-column prop="title" :label="$t('i18nn_7431e76029678ec7')"></el-table-column>

							<el-table-column prop="progressCount" :label="$t('i18nn_f4c5829c71fcd8bf')"></el-table-column>

							<el-table-column prop="applyName" :label="$t('i18nn_627df7b14df32cdb')"></el-table-column>
							<el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')"></el-table-column>

							<!-- <el-table-column prop="plCount" label="undefined"></el-table-column>

            <el-table-column prop="realPlCount" label="undefined"></el-table-column> -->

							<!-- <el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')"></el-table-column> -->

							<!-- <el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')"></el-table-column> -->

							<el-table-column prop="createTime" :label="$t('i18nn_2594059036dfede5')" min-width="150">
								<template slot-scope="scope">
									<div>
										<div><span>{{$t('i18nn_e1911e9360047dcb')}}</span>：{{ scope.row.updateTime }}</div>
										<div><span>{{$t('i18nn_e8564657bbe9ca53')}}</span>：{{ scope.row.createTime }}</div>
									</div>

								</template>
							</el-table-column>
							
							<!-- <el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column>
							
							<el-table-column prop="whNo" :label="$t('i18nn_5acbec83efb27445')"></el-table-column>
							
							<el-table-column prop="qaBizTypeName" :label="$t('i18nn_9666c59aedbfa70e')"></el-table-column>
							<el-table-column prop="relationNo" :label="$t('i18nn_9168144190f66f5d')"></el-table-column>
 -->
							<!-- <el-table-column prop="opTime" :label="$t('i18nn_d99d790ec4383bfb')"></el-table-column> -->
							<el-table-column :label="$t('i18nn_93f5ca01b006206c')" width="300px" fixed="right">
								<template slot-scope="scope">
									<div>

										<!-- <el-button @click="openEdit($event, scope.row)" type="warning" size="mini" icon="el-icon-edit"
									 v-if="'2' != scope.row.opStatus && '10'==scope.row.bizType">
										{{ 'undefined' }}
									</el-button> -->

										<el-button @click="openRecProgress($event, scope.row)" type="success"
											size="mini" icon="el-icon-view">{{$t('i18nn_10a6a2a6124bbb9a')}}</el-button>

										<el-button type="danger" size="mini" icon="el-icon-minus"
											@click="delAction($event, scope.row)" v-if="'10' == scope.row.status">
											{{ $t('i18nn_e33c9b93c36fd250') }}
										</el-button>



										<!-- <el-button @click="completeAction($event, scope.row)" type="success" size="mini"
										icon="el-icon-finished" v-if="'2' != scope.row.opStatus">
										{{ 'undefined' }}
									</el-button> -->
									</div>
									<!-- <el-button @click="startAction($event, scope.row)" type="success" size="mini" icon="el-icon-video-play">undefined</el-button> -->




								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="tableConPagination">
						<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData">
						</hy-page-pagination>
					</div>
				</div>
			</div>
			</el-tab-pane>
				
			</el-tabs>
			<!-- <div slot="footer" class="dialog-footer">
		    <el-button type="primary" plain @click="dialogFile = false">{{ $t('FormMsg.Close') }}</el-button>
		  </div>
		</el-dialog> -->
		</el-drawer>
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	// import cusSelFuzzy from '@/components/StorageCenter/components/cusSelFuzzy.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	// import BillingRecords from '@/components/WarehouseCenter2/FinanceMana/BillingRecords.vue';
	import ValueAddService from '@/components/StorageCenter/QA/ValueAddService.vue';
	export default {
		// name: 'HomeConfFrame',
		//meta信息seo用
		// metaInfo: {
		// 	title: 'HYTX-WMS-ADMIN'
		// },
		props: {
			openTime: {},
			type: {
				default: function() {
					return '';
				},
				type: String
			},
			relationId: {
				default: function() {
					return '';
				},
				type: String
			},
			relationNo: {
				default: function() {
					return '';
				},
				type: String
			},
			// userId: {
			// 	default: function() {
			// 		return '';
			// 	},
			// 	type: String
			// },
			whNo: {
				default: function() {
			 	return '';
				},
				type: String
			},
		},
		components: {
			// cusSelFuzzy,
			whNoSelect,
			ValueAddService
			// BillingRecords
		},
		data() {
			return {
				activeName: 'second',
				openTime2:"",
				
				dialogFile: false,
				loadingFile: false,
				
				firstTotal:"",
				secondTotal:"",
				// cusUserId: '',
				//打开选择框
				// dialogSelVisible: false,

				// dialogAddVisible: false,
				// selBillData: {},
				// dialogFormVisible: false,
				// dialogFormVisibleMsg: '',
				multipleSelection: [],

				// pickerOptions: {
				// 	shortcuts: [{
				// 			text: 'Latest Week',
				// 			onClick(picker) {
				// 				const end = new Date();
				// 				const start = new Date();
				// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
				// 				picker.$emit('pick', [start, end]);
				// 			}
				// 		},
				// 		{
				// 			text: 'Last Month',
				// 			onClick(picker) {
				// 				const end = new Date();
				// 				const start = new Date();
				// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
				// 				picker.$emit('pick', [start, end]);
				// 			}
				// 		},
				// 		{
				// 			text: 'Last Three M',
				// 			onClick(picker) {
				// 				const end = new Date();
				// 				const start = new Date();
				// 				start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
				// 				picker.$emit('pick', [start, end]);
				// 			}
				// 		}
				// 	]
				// },

				selectOption: {
					wh_no: [],
					wh_qa_type: [],
					wh_qa_biz_type: [],
					// wh_op_status: [],
					// wh_fin_bill_status: [],
					// wh_op_record_type: [],
					// // wh_op_is_bill:[],
					// statusList: [{
					// 		value: '',
					// 		label: this.$t('i18nn_16853bda54120bf1')
					// 	},
					// 	{
					// 		value: '10',
					// 		label: this.$t('i18nn_7e14d2cd3996dcd2')
					// 	},
					// 	{
					// 		value: '20',
					// 		label: this.$t('i18nn_2b65059530ec3ed5')
					// 	},
					// 	{
					// 		value: '30',
					// 		label: this.$t('i18nn_1fb4422ebabb847a')
					// 	},
					// 	{
					// 		value: '40',
					// 		label: this.$t('i18nn_0eb9b2e0d01ad12b')
					// 	}
					// ]
				},
				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//表格数据
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				//详情数据
				loading_det: false,

				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					// workNo: '', //this.$t('i18nn_daf169d1f7dceda0'),
					qaNo: "",

					whNo: '', //this.$t('i18nn_5e7740c52e22eefc'),
					qaType: "",
					status: "",
					// wh_op_is_bill:'',
					// daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this.$Utils.fomatterDate_YYYYMMdd(new Date())],
					// daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this
					// 	.$Utils.fomatterDate_YYYYMMdd(new Date())
					// ],
					daterange: [],
					relationNo: "",
					wh_qa_biz_type: "",
					// wh_op_status: '',
					// wh_fin_bill_status: '',
					// plStatus:'',
					// bizType: '10',
					// opNo: ''
					// plName: ''
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.activeName = 'second';
				this.firstTotal = "";
				this.secondTotal = "";
				this.initData();
				this.initData2();
			}
		},
		activated() {
			// console.log('activated');
			// if (this.$route.query && this.$route.query.cusUserId) {
			// 	this.cusUserId = this.$route.query.cusUserId;
			// }
			// this.$nextTick(() => {
			// 	this.$refs.cusSelFuzzy.init(this.cusUserId);
			// })
			// this.initData();
		},
		//创建时
		created() {
			console.log('created');
			// if (this.$route.query && this.$route.query.cusUserId) {
			// 	this.cusUserId = this.$route.query.cusUserId;
			// }
			// this.$nextTick(() => {
			// 	this.$refs.cusSelFuzzy.init(this.cusUserId);
			// })
			// this.initData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			// this.initData2();
			//数据字典
			getDicData(['wh_qa_type', 'wh_qa_biz_type'],
				(data)=>{
					this.selectOption.wh_qa_type = data['wh_qa_type'];
					this.selectOption.wh_qa_biz_type = data['wh_qa_biz_type'];
			});
		},
		methods: {
			handleClick(tab, event) {
				console.log(this.activeName, tab, event);
				if('first'==this.activeName){
					this.initData();
				} else if('second'==this.activeName){
					this.initData2();
				}
			},
			
			initData2(){
				// this.activeName = 'second';
				this.openTime2 = new Date().getTime();
			},
			valueAddSuccessBack(val){
				this.secondTotal = val;
			},
			
			initData() {
				// this.activeName = 'first';
				
				this.pagination.current_page = 1;
				this.currentSel = {};
				this.multipleSelection = [];

				// this.cusUserId = this.userId;
				this.filterData.whNo = this.whNo;
				this.filterData.wh_qa_biz_type = this.type;
				this.filterData.relationNo = this.relationNo;
				this.filterData.relationId = this.relationId;

				this.$nextTick(() => {
					// this.$refs.cusSelFuzzy.init(this.cusUserId);
					this.$refs.whNoSelect.init(this.whNo);
				})
				this.getPageData();

				this.$nextTick(() => {
					this.$refs.multipleTable.doLayout();
				})
			},
			// changCus(data) {
			// 	console.log('changCus', data);
			// 	this.cusUserId = data.userId;
			// 	this.initData();
			// },
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},

			handleSelectStatus(key, keyPath) {
				// console.log(key, keyPath);
				this.filterData.status = key;
				this.initData();
			},
			
			//查看QA
			toQA(event, row){
				event.stopPropagation();
				// this.dialogAddVisible = false;
				// this.$emit('close');
				this.$router.push({
					name:"QARecList",
					query:{
						qaNo: row.qaNo
					}
				})
			},
			// changWhNo2(data) {
			// 	console.log('changWhNo', data);
			// 	this.form.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	// this.initData();
			// },
			// changCus2(data) {
			// 	console.log('changCus', data);
			// 	this.form.cusName = data.companyName;

			// 	this.form.cusNo = data.cusNo;
			// },

			//分页的筛选项数据
			pageFilterData() {
				// let startTime = '';
				// let endTime = '';
				// if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
				// 	startTime = this.filterData.daterange[0];
				// 	endTime = this.filterData.daterange[1];
				// } else {
				// 	// this.$message.warning(this.$t('tips.Please_Sel_date'));
				// }
				return {
					// userId: this.cusUserId ? this.cusUserId : null,
					// plStatus: this.filterData.plStatus? this.filterData.plStatus:null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,
					qaType: this.filterData.qaType ? this.filterData.qaType : null,
					qaNo: this.filterData.qaNo ? this.filterData.qaNo : null,
					// workNo: this.filterData.workNo ? this.filterData.workNo : null,
					// wh_op_is_bill: this.filterData.wh_op_is_bill? this.filterData.wh_op_is_bill:null,
					// isBill: this.filterData.wh_fin_bill_status ? this.filterData.wh_fin_bill_status : null,
					status: this.filterData.status ? this.filterData.status : null,

					qaBizType: this.filterData.wh_qa_biz_type ? this.filterData.wh_qa_biz_type : null,
					relationNo: this.filterData.relationNo ? this.filterData.relationNo : null,
					// opNo: this.filterData.opNo ? this.filterData.opNo : null,

					// startOpTime: startTime ? startTime : null,
					// endOpTime: endTime ? endTime : null,

					// bizType: this.filterData.bizType ? this.filterData.bizType : null,
					// "plName": this.filterData.plName? this.filterData.plName:null,
					// "goodsType": this.queryParamObj.goodsType
				};
			},
			//请求分页数据
			getPageData() {
				let filterData = Object.assign({
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目
				}, this.pageFilterData())

				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhQARecPageList, filterData)
					.then(({
						data
					}) => {
						
						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.tableData = data.rows;

							//当前数据总条数
							this.pagination.total = parseInt(data.total);
							
							this.firstTotal = this.pagination.total;

						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error('列表数据，请求失败！');
						this.loading_load = false;
					});
			},

			//打开新增
			openAddNew() {
				event.stopPropagation();
				this.dialogFile = false;
				this.$router.push({
					name: 'QARecAdd',
					query:{
						qaBizType: this.type,
						relationNo: this.relationNo,
						relationId: this.relationId,
						// cusNo: this.userId,
						whNo: this.whNo,
					}
				});
			},

			//打开编辑
			openRecProgress(event, row) {
				event.stopPropagation();
				// this.openDioalog(row, this.$t('i18nn_6267f3aedf895209'));
				this.dialogFile = false;
				this.$router.push({
					name: 'QARecProgress',
					query: {
						id: row.id,
						qaNo: row.qaNo
					}
				});
			},

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},
			//删除
			delAction(event, row) {
				this.$confirm('确定删除吗?', this.$t('i18nn_daaaeb1b7b22b126'), {
						type: 'warning'
					})
					.then(() => {
						this.delDataAction(event, row);
					})
					.catch(() => {});
			},
			//删除
			delDataAction(event, row) {
				event.stopPropagation();
				console.log('delAction', row);
				let parm = [];
				parm = {};
				this.postData(this.$urlConfig.WhQARecDel + '/' + row.id, parm, 'delete', () => {
					this.initData();
					this.$message.success(this.$t('9f30371831a98237'));
				});
			},

			//提交数据
			postData(url, formData, type, callback) {
				// let _this = this;
				this.loading = true;

				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							callback();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading = false;
						this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

			//查询数据字典
			// getDicData() {
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_qa_type', 'wh_qa_biz_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_qa_type = data.data['wh_qa_type'];
			// 				this.selectOption.wh_qa_biz_type = data.data['wh_qa_biz_type'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.errorData');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error('查询数据字典接口失败,请重试！');
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	
</style>
