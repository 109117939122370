<template>
	<div>
		<el-drawer :wrapperClosable="false" :title="$t('i18nn_24c8f46012a25c89')+$t('i18nn_ce764b74be1b9a90')" append-to-body :visible.sync="dialogAddVisible" :direction="'rtl'"
			size="1000px">
			<!-- <div style=""> -->
			<!-- <div> -->
			<el-card style="" v-loading="loading">
				<div slot="header">
					<h3>{{$t('i18nn_f80dedf7c601059f')}}</h3>
				</div>
				<!-- <el-form :model="form" label-width="200px" v-loading="loading"> -->

				<div>
					<el-button @click="addLe2" type="success" size="small"
						icon="el-icon-plus">{{$t('i18nn_24c8f46012a25c89')}}</el-button>

					<el-table :data="form.addServiceList" stripe :border="true" style="width: 100%" size="small">
						<el-table-column type="index" width="50" align="center" :label="$t('7b1c2b1adc920d9c')">
						</el-table-column>
						<el-table-column prop="serviceType" :label="$t('i18nn_f1d6776242105f03')">
							<template slot-scope="scope">
								<el-select filterable clearable size="small" v-model="scope.row.serviceType"
									:placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 200px;">
									<el-option v-for="item in selectOption.wh_op_add_service" :key="item.code"
										:label="$Utils.i18nCodeText(item)" :value="item.code">
										<div class="sel_option_s1" style="">
											<span class="sel_option_name">{{ $Utils.i18nCodeText(item) }}</span>
											<span class="sel_option_code" style="">{{ item.code }}</span>
										</div>
									</el-option>
								</el-select>
							</template>
						</el-table-column>

						

						<el-table-column prop="quantity" :label="$t('i18nn_f9db93b87e08763b')+'(和'+$t('i18nn_f1d6776242105f03')+'相关)'">
							<template slot-scope="scope">
								<el-input-number v-model="scope.row.quantity" size="small" controls-position="right"
									style="width: 200px;"></el-input-number>
							</template>
						</el-table-column>
						
						<el-table-column prop="goodsSku" :label="'SKU'+'(非必填)'">
							<template slot-scope="scope">
								<div>
									<el-button type="primary" size="small" icon="el-icon-magic-stick"
										@click="addRow($event,scope.$index)">{{$t('i18nn_7fb6ada66a1fccca')}}</el-button>
									<!-- <el-input v-model="scope.row.goodsSku" size="small"
										style="width: 200px;"></el-input> -->
									<span>{{scope.row.goodsSku}}</span>
								</div>
							</template>
						</el-table-column>
						
						<el-table-column :label="$t('i18nn_93f5ca01b006206c')" width="200px">
							<template slot-scope="scope">
								<el-button @click="delLe2($event, scope.$index)" type="warning" size="small"
									icon="el-icon-minus">{{ $t('i18nn_e33c9b93c36fd250') }}</el-button>
							</template>
						</el-table-column>
					</el-table>

					<!-- <el-form-item :label="$t('i18nn_15b3627faddccb1d')" prop=""> -->
					<div style="margin: 20px 0;">
						<div style="font-size: 16px; margin: 10px 0;"><span>{{$t('i18nn_ce764b74be1b9a90')}}</span><span>{{$t('i18nn_15b3627faddccb1d')}}</span></div>
						<el-input type="textarea" v-model="form.remark" :maxlength="2000" show-word-limit ></el-input>
					</div>
					<div style="margin: 20px 0;">
						<div style="font-size: 16px; margin: 10px 0;"><span>{{$t('i18nn_ce764b74be1b9a90')}}</span><span>{{$t('i18nn_f5d43732e3f6cf4d')}}</span></div>
						<div v-loading="loadingUploadFile">
							<el-button type="primary" size="small" icon="el-icon-paperclip"
								@click="openAddFile()">{{$t('i18nn_ad36057ff6a7ce6b')}}</el-button>
							<ul>
								<li v-for="(item,index) in form.attachments" :key="index">
									<el-image style="width: 50px; height: 50px;vertical-align: middle;" :z-index="9999" :fit="'contain'"
										:src="item.url" :preview-src-list="form.attachments.map(itemPre=> { return itemPre.url})">
										<div slot="error" class="image-slot">
											<i class="el-icon-document"></i>
										</div>
									</el-image>
									<a :href="item.url" :title="item.url" target="_blank">{{ item.fileName }}</a>

									<!-- <el-button @click="delFile($event, item)" type="text" size="mini" icon="el-icon-delete"
										style="padding: 0 10px;">{{$t('i18nn_e33c9b93c36fd250')}}</el-button> -->
									
									<el-button @click="delFileAction($event, item,index)" type="text" size="mini"
										icon="el-icon-delete" style="padding: 0 10px;" v-if="item.id">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
									<el-button @click="delFile($event, index)" type="text" size="mini" icon="el-icon-delete"
										style="padding: 0 10px;" v-else>{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
										
								</li>
							</ul>
						</div>
					</div>
				</div>
			</el-card>

			<div class="drawer-footer">
				<el-button type="primary" icon="el-icon-tickets" @click="submitForm()">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button>
				<!-- <el-button type="primary" plain @click="dialogAddVisible = false">{{ $t('i18nn_4e9fc68608c60999') }} -->
				</el-button>
			</div>
		</el-drawer>

		<!-- 选择SKU -->
		<dialogWSku :openTime="skuInvOpenTime" @sure="sureSel"></dialogWSku>

		<!-- 附件 -->
		<dialogFileUpload ref="dialogFileUpload" :folder="'storage/addValService/'" :fileKey="'addValServiceFile'"
			:openTime="FileUploadOpenTime" @success="FileUploadSuccess"></dialogFileUpload>
	</div>
</template>

<script>
	import {
		getDicData
	} from '@/axios/common.js';
	import dialogWSku from '@/components/StorageCenter/WSkuProduct/dialogWSku.vue';

	import dialogFileUpload from '@/components/StorageCenter/components/dialogFileUpload.vue';
	export default {
		props: {
			openTime: {},
			relationId: {
				default: function() {
					return '';
				},
				type: String
			},
			// userId:{},
			qaType: {},
			whNo: {
				default: function() {
					return '';
				},
				type: String
			},
			id: {}
		},
		components: {
			dialogWSku,
			dialogFileUpload
		},
		data() {
			return {
				// dialogFile: false,
				// loadingFile: false,
				// cusUserId: '',
				//打开选择框
				// dialogSelVisible: false,

				dialogAddVisible: false,

				FileUploadOpenTime: '',

				skuInvOpenTime: '',
				skuOpenIndex: '',

				// selBillData: {},
				// dialogFormVisible: false,
				// dialogFormVisibleMsg: '',
				// multipleSelection: [],
				// {"relationId":"","addServiceList":[],"attachments":[],"bizType":""}
				loadingUploadFile: false,
				
				form: {
					"remark": "",
					attachments: [],
					"addServiceList": [
						// {
						// 	"goodsSku":"",
						// 	"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
						// 	"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
						// },
					]
				},


				selectOption: {
					wh_op_add_service: [],
				},
				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//表格数据
				tableData: [],

				//详情数据
				loading_det: false,

			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				// this.dialogFile = true;
				this.initData();
			}
		},
		activated() {

		},
		//创建时
		created() {
			console.log('created');
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_op_add_service'],
				(data)=>{
					this.selectOption.wh_op_add_service = data['wh_op_add_service'];
			});
		},
		methods: {
			initData() {

				// this.tableData = [];
				// this.getPageData();
				this.dialogAddVisible = true;

				this.form = {
					"remark": "",
					attachments: [],
					"addServiceList": [{
						"goodsSku": "",
						"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
						"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
					}]
				};
			},


			//新增
			addLe2() {
				this.form.addServiceList.push({
					"goodsSku": "",
					"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
					"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
				});
			},
			// //删除
			delLe2(event, index) {
				event.stopPropagation();
				this.form.addServiceList.splice(index, 1);
			},

			// 选择SKU
			addRow(event, index) {
				event.stopPropagation();
				// if (!this.whNo) {
				// 	this.$message.warning(this.$t('FormMsg.Select_long_wh'))
				// 	return;
				// }
				// this.skuInvWhNo = this.form.senderAddr;
				this.skuInvOpenTime = new Date().getTime();
				this.skuOpenIndex = index;
			},
			// selSkuInv(val){
			// 	val.forEach(item=>{
			// 		let hasSku = this.form.addServiceList.some(item2=> item.goodsSku == item2.goodsSku);
			// 		//是否已经存在此SKU
			// 		if(!hasSku){//不存在
			// 			this.form.addServiceList.push({
			// 				goodsSku: item.goodsSku,
			// 				"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
			// 				"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
			// 			});
			// 		}
			// 	})
			// },
			sureSel(val) {
				val.forEach((item, index) => {
					// let hasSku = this.form.addServiceList.some(item2=> item.goodsSku == item2.goodsSku);
					//是否已经存在此SKU
					// if(!hasSku){//不存在
					if (0 == index) { //第一条直接录入
						// this.form.addServiceList[this.skuOpenIndex].goodsSku = item.goodsSku;
						let oneAdd = this.form.addServiceList[this.skuOpenIndex];
						oneAdd.goodsSku = item.goodsSku;
						this.$set(this.form.addServiceList, this.skuOpenIndex, oneAdd)
					} else { //其他插入新数据
						this.form.addServiceList.push({
							goodsSku: item.goodsSku,
							"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
							"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
						});
					}
					// }
				})
			},


			//添加附件
			openAddFile() {
				// this.FileUploadOpenIndex = index;
				this.FileUploadOpenTime = new Date().getTime();
			},
			FileUploadSuccess(data) {
				console.log('FileUploadSuccess', data);
				let fileList = data.map(item => {
					return {
						fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
						url: item.filePath, //this.$t('i18nn_8758fd50c87d6c9c')
						// type: "90"
					}
				});
				if(!this.form.attachments){
					this.form.attachments = [];
				}
				// this.form.attachments = fileList;
				this.form.attachments = this.form.attachments.concat(fileList);
				this.$forceUpdate();
			},
			//删除附件
			delFile(event, index) {
				event.stopPropagation();
				this.form.attachments.splice(index, 1);
				this.$forceUpdate();
			},

			//后端删除
			delFileAction(event,row,index) {
				event.stopPropagation();
			  this.loadingUploadFile = true;
			  this.$http
			    .delete(this.$urlConfig.WhFileUploadDel + '/' + row.id, {})
			    .then(({ data }) => {
			      this.loadingUploadFile = false;
			      if (200 == data.code) {
							this.form.attachments.splice(index, 1);
							this.$forceUpdate();
							this.$message.success(this.$t('i18nn_de017dafc266aa03'));
			      } else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_54dc58d9d0377bb5'));
			      }
			    })
			    .catch(error => {
			      console.log(error);
						this.loadingUploadFile = false;
						this.$message.warning(data.msg ? data.msg : '删除失败!');
			    });
			},
			//打开新增
			// 		openAddNew() {
			// 			event.stopPropagation();
			// 			this.dialogAddVisible = true;

			// 			this.form = {
			// 				"remark": "",
			// 				attachments:[],
			// 				"addServiceList": [{
			// 					"goodsSku":"",
			// 					"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
			// 					"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
			// 				}]
			// 			};

			// 		},

			// //打开编辑
			// openRecProgress(event, row) {
			// 	event.stopPropagation();
			// 	// this.openDioalog(row, this.$t('i18nn_6267f3aedf895209'));
			// 	this.dialogFile = false;
			// 	this.$router.push({
			// 		name: 'QARecProgress',
			// 		query: {
			// 			id: row.id,
			// 			qaNo: row.qaNo
			// 		}
			// 	});
			// },
			fomatterQaTypeToBizType(qaType) {
				// bizType
				//code: "SDS", codeText: this.$t('i18nn_684a2afb069b6016'),
				//code: "SRE", codeText: this.$t('i18nn_199f5bded0b23976'), 
				//code: "STF", codeText: "自提/快递", 
				//code: "SCC", codeText: this.$t('i18nn_c39fd3d48389bea7'), 
				//code: "STR", codeText: "中转/转运"

				// qaBizType
				// code: "10", codeText: this.$t('i18nn_684a2afb069b6016'),
				// code: "20", codeText: this.$t('i18nn_6373cb1d1204d580'),
				// code: "30", codeText: this.$t('i18nn_57495c18e2baebdc'), 
				// code: "40", codeText: this.$t('i18nn_21e42bbe9894aafc'), 
				// code: "99", codeText: this.$t('i18nn_97e49d00bcc4c17c'),
				let bizType = '';
				if ('10' == qaType) {
					bizType = 'SDS';
				} else if ('20' == qaType) {
					bizType = 'STF';
				} else if ('30' == qaType) { //STR->TSCC
					bizType = 'TSCC';
				} else if ('40' == qaType) {
					bizType = 'SCC';
				} else if ('50' == qaType) {
					bizType = 'SRE';
				} else if ('60' == qaType) {
					bizType = 'STR';
				}
				return bizType;
			},
			//提交信息
			submitForm() {
				let bizType = this.fomatterQaTypeToBizType(this.qaType);
				let formData = Object.assign({}, this.form);
				formData.relationId = this.relationId;
				formData.bizType = bizType;
				this.postData(this.$urlConfig.WhValAddServiceBizAdd, formData, '', () => {
					this.dialogAddVisible = false;
					this.$emit("success")
					// this.getPageData();
					// this.$message.success(this.$t('9f30371831a98237'));
				});
			},


			//提交数据
			postData(url, formData, type, callback) {
				// let _this = this;
				this.loading = true;

				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							callback();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading = false;
						this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

			//查询数据字典
			// getDicData() {
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_op_add_service'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_op_add_service = data.data['wh_op_add_service'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.errorData');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error('查询数据字典接口失败,请重试！');
			// 		});
			// }
		}
	};
</script>

<style>
</style>