<template>
	<div>
		<el-drawer :wrapperClosable="false" :title="$t('i18nn_c09a4ec2fe473b0a')" append-to-body :visible.sync="dialogFile"
			:direction="'rtl'" size="500px" v-loading="loadingUpload">
			<!-- <el-dialog :title="$t('c09a4ec2fe473b0a')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1000px" > -->
			<el-form ref="form" :inline="true" :rules="formRules" :model="form" label-width="150px" v-loading="loading"
				:element-loading-text="$t('i18nn_1b4525c800280581')">
				<el-card style="margin-top: 10px;">
					<div slot="header" class="">
						<h3>
							<i class="el-icon-folder-opened"></i>
							<span>共{{selTableData.length}}条</span>
						</h3>
					</div>
					<div>
						<div>
							<el-tag v-for="(item,index) in selTableData" :key="index">
								{{item.sendNo}}
							</el-tag>
						</div>
						<!-- <el-form-item :label="$t('i18nn_7f6c721b57ed0e7b')" prop="">
						{{row.sysOrderNo}}
					</el-form-item>

					<el-form-item :label="$t('a2df50399ce1bf10')" prop="">
						{{row.sendNo}}
					</el-form-item> -->
					</div>
				</el-card>

				<div style="margin-top: 10px;">

					<el-form-item :label="$t('i18nn_5165f3ee9377af83')" prop="">
						<el-select filterable clearable v-model="form.carTmsType" :placeholder="$t('2ad108ab2c560530')"
							style="width: 200px;">
							<el-option v-for="item in selectOption.wh_car_tms_type" :key="item.code"
								:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
								<div class="sel_option_s1" style="">
									<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
									<span class="sel_option_code" style="">{{ item.code }}</span>
								</div>
							</el-option>
						</el-select>
					</el-form-item>

				</div>
				<!-- </el-card> -->
			</el-form>

			<div class="drawer-footer">
				<el-button type="primary" plain @click="dialogFile = false">{{ $t('FormMsg.Close') }}</el-button>
				<el-button type="primary" icon="el-icon-tickets"
					@click="submitFormAction()">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button>
				<!-- <el-button type="primary" v-else @click="submitExcelFormAction()">{{ $t('FormMsg.Save') }}</el-button> -->
			</div>
			<!-- </el-dialog> -->
		</el-drawer>

		<!-- 地址 -->
		<!-- <TransportAddressListDialog :openTime="TransportAddressOpenTime" :userId="row.userId" @selSuccess="addrSelSuccess">
		</TransportAddressListDialog> -->
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	// import TransportAddressListDialog from '@/components/StorageCenter/Transport/TransportAddressListDialog.vue';
	// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	export default {
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			// row: {},
			selTableData: {
				default: function() {
					return [];
				},
				type: Array
			},
			// type: {//1提交附件并，提交打托完成，其他仅提交附件
			// 	// default: function() {
			// 	// 	return '';
			// 	// },
			// 	// type: String
			// }
		},
		components: {
			// TransportAddressListDialog
		},
		data() {
			return {
				dialogFile: false,
				loadingUpload: false,

				// relationId: '',
				// userId: '',
				//附件
				// fileWatchKey: '1',
				// fileList: [],
				// fileId: this.$store.getters.getUserInfo.id,

				// saveErr: false,

				// beforeFileList:[],
				// listUrl: [],

				// tableData: [],
				//
				// TransportAddressOpenTime: '',

				loading: false,
				// 出车方式  预计到车日期 出库类型 出库时间 备注
				form: {
					// id: '',
					carTmsType: '',
					// carPlanDate: '',
					// sendOutType: '', //this.$t('i18nn_7edc8f4b9d8f2435'),
					// outWhDate: '',
					// remark: '',
					// palletCount: '', //this.$t('i18nn_18c1c1a3ea76e9d3'),
					// palletLabel: '',
					// waitOutWhDate: '',

					// isFba: '0',
					// toWhNo: "",
					// trAddrInfo: ""
					// fbaNo: "",
					// attachments: []
				},

				formRules: {
					// sendOutType: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_select'),
					// 	trigger: 'change'
					// }]
					// palletCount: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }]
					// feePrice: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }]
				},
				selectOption: {
					// wh_send_out_type: [],
					wh_car_tms_type: []
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
			// this.getDicData();
			//数据字典
			getDicData(['wh_car_tms_type'],
				(data) => {
					// this.selectOption.wh_send_out_type = data['wh_send_out_type'];
					this.selectOption.wh_car_tms_type = data['wh_car_tms_type'];
				});
		},
		methods: {
			initData() {
				this.form = {
					// id: this.row.id,
					carTmsType: null,
					// carPlanDate: this.row.carPlanDate,
					// sendOutType: this.row.sendOutType, //this.$t('i18nn_7edc8f4b9d8f2435'),
					// outWhDate: this.row.outWhDate,
					// waitOutWhDate: this.row.waitOutWhDate,
					// palletCount: this.row.palletCount,
					// palletLabel: this.row.palletLabel,
					// remark: this.row.remark,

					// isFba: this.row.isFba,
					// toWhNo: this.row.toWhNo,
					// trAddrInfo: "",
					// fbaNo: this.row.fbaNo,

				};
			},
			async submitFormAction() {
				// this.submitExcelFormHTTP();
				await this.submitForm();
			},

			//提交信息
			submitForm() {
				// this.$refs[formName].validate(async valid => {
				// if (valid) {
				// let formData = Object.assign({}, this.form);
				//浅拷贝、对象属性的合并
				// if (0 === this.dialogFormStatus) {
				// formData.id = null;
				// formData.userName = null;

				// this.postData(this.$urlConfig.WhAccountBindAdd, formData);
				// let whArr = formData.whNoArr;
				// let userArr = formData.userArr;

				// 	if (whArr.length <= 0 || userArr.length <= 0) {
				// 		this.$message.warning(this.$t('i18nn_17e7c7b16731a7e5'));
				// 		return;
				// 	}

				// 	if (whArr.length * userArr.length > 100) {
				// 		this.$message.warning('绑定数据超过100,请减少绑定数据');
				// 		return;
				// 	}

				// for (let j = 0, lnj = this.selTableData.length; j < lnj; j++) {
					// 账号
					// let Obj = this.selTableData[j];
					let formData = {};
					// if(formData.carTmsType == this.form.carTmsType){//相同的出车方式不用更新
					// 	continue;
					// }
					formData.carTmsType = this.form.carTmsType;
					formData.ids = this.selTableData.map(item=> item.id);
					
					
					this.postData(this.$urlConfig.WhInShipmentsBatchEditTmsType, formData,()=>{
						this.$message.success('提交成功');
						this.$emit('success');
						this.dialogFile = false;
					});
					
					// formData.userName = userObj.userName;
					// formData.bckId = userObj.userId;

					// for (let i = 0, ln = whArr.length; i < ln; i++) {
					// 	//仓库
					// 	let whNo = whArr[i].whNo;
					// 	formData.whNo = whNo;
					// 	this.loading = true;
					// let res = await this.$http.put(this.$urlConfig.WhInShipmentsBatchEditTmsType, formData);
					// if (res.data && 200 == res.data.code) {
					// 	this.$message.success('提交成功');
					// 	// this.$notify({
					// 	// 	title: '编辑成功',
					// 	// 	message: "(" + formData.sendNo + ")",
					// 	// 	type: 'success'
					// 	// });
					// } else {
					// 	this.$message.warning('提交失败');
					// 	// this.$notify({
					// 	// 	title: '编辑失败',
					// 	// 	message: "(" + formData.sendNo + ")",
					// 	// 	type: 'warning',
					// 	// 	duration: 0
					// 	// });
					// }
					// }
					//结束遍历
					// if (j == (this.selTableData.length - 1)) {
					// 	this.loading = false;
					// 	this.dialogFile = false;
					// 	// this.getPageData();
					// 	this.$emit('success')
					// }
				// }

				// } else {
				// 	// formData.userId = this.UserInfo.id;
				// 	// this.postData(this.$urlConfig.WhMyGoodsSkuUpdate+'/'+this.cusUserId, formData);
				// }
				// } else {
				// 	console.log('error submit!!');
				// 	this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
				// 		type: 'warning'
				// 		//confirmButtonText: this.$t('204ffab8a6e01870'),
				// 	});
				// 	return false;
				// }
				// });
			},

			//提交-//打托完成
			// submitExcelFormHTTP() {
			// 	let formData = Object.assign({}, this.form);
			// 	// formData.attachments = attachments;

			// 	this.loadingUpload = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhInShipmentEditUpdate, formData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			this.loadingUpload = false;
			// 			if (200 == data.code) {
			// 				this.dialogFile = false;
			// 				this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
			// 				this.$emit('success');
			// 				// }
			// 			} else {
			// 				this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.submitError'));
			// 			// this.saveErr = true;
			// 			this.loadingUpload = false;
			// 			this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('204ffab8a6e01870'),
			// 			});
			// 		});
			// },

			//提交数据
			postData(url, formData, callback) {
				// let _this = this;
				this.loading = true;

				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);

						this.loading = false;
						if (200 == data.code) {
							callback();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>