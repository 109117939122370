
// 深拷贝
export function deepClone(data){
    try{
      //浅拷贝指向内存地址
      //Object.assets({}) 为浅拷贝
      //Array.cancat([]) 为浅拷贝
      return JSON.parse(JSON.stringify(data));
    }catch(e){
      console.log(e);
      alert("深拷贝失败 copy error");
      return null;
      //TODO handle the exception
    }
}

// 清除对象的值
export const clearObjectVal = (obj) => {
	return clearOneObjectVal(obj);
}
// 清除对象的值。对象层数不要太多会一直循环
function clearOneObjectVal(obj){
	let re = obj;
	Object.keys(re).forEach(key => {
		let typeKey = Object.prototype.toString.call(re[key]);
		if(typeKey === '[object String]' || typeKey === '[object Null]'|| typeKey === '[object Number]'){
			re[key] = null;
		} else if(typeKey === '[object Object]'){
			clearOneObjectVal(re[key])
		} else if(typeKey === '[object Array]'){
			re[key] = [];
		} else {
			re[key] = null;
		}
	});
	return re;
}