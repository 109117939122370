import Vue from 'vue'
import axios from 'axios'
import JSZip from 'jszip'
import FileSaver from 'file-saver'

import {
	Loading
} from 'element-ui';

function getFile(url) {
	return new Promise((resolve, reject) => {
		axios({
				method: 'get',
				url,
				responseType: 'blob'
				// responseType: 'arraybuffer'
			})
			.then(data => {
				console.log(data)
				resolve(data.data)
			})
			.catch(error => {
				reject(error.toString())
			})
	})
}

// 压缩文件并下载
export function fileZipAndDownload(fileList, zipName) {
	const zip = new JSZip()
	// const cache = {}
	const promises = [];
	let loadingInstance = Loading.service({
		text: "loading compress..."
	});
	fileList.forEach(item => {
		// let loadingInstance = Loading.service({
		// 	text:"正在下载..."
		// });
		const promise = getFile(item).then(data => {
			// Vue.propoty.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
			// loadingInstance.close();
			// });
			let file_name_arr_pre = item.split('?')[0];
			let file_name_arr = file_name_arr_pre.split('/');
			const file_name = file_name_arr[file_name_arr.length - 1];
			zip.file(file_name, data, {
				binary: true
			})
			// cache[file_name] = data
		})
		promises.push(promise)
	});
	// let loadingInstance = Loading.service({
	// 	text:"正在压缩..."
	// });
	Promise.all(promises).then(() => {
		zip.generateAsync({
			type: 'blob'
		}).then(content => {
			let dateStr = Vue.prototype.$Utils.fomatterDate_YYYYMMdd(new Date());
			loadingInstance.close();
			// 生成二进制流
			FileSaver.saveAs(content, zipName + '_' + dateStr + '.zip') // 利用file-saver保存文件  自定义文件名
			// saveAs(content, '文件下载.zip') // 利用file-saver保存文件  自定义文件名
		})
	})
}

// 重命名文件名称-并压缩文件并下载
export function fileZipAndDownloadReName(fileList, zipName) {
	const zip = new JSZip()
	// const cache = {}
	const promises = [];
	let loadingInstance = Loading.service({
		text: "loading compress..."
	});
	fileList.forEach(item => {
		// let loadingInstance = Loading.service({
		// 	text:"正在下载..."
		// });
		const promise = getFile(item.url).then(data => {
			// Vue.propoty.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
			// loadingInstance.close();
			// });
			let file_name_arr_pre = item.url.split('?')[0];
			let file_name_arr = file_name_arr_pre.split('/');
			let old_file_name = file_name_arr[file_name_arr.length - 1];
			let old_file_name_suffix = old_file_name.split('.')[1];
			let file_name = item.fileName+'.'+old_file_name_suffix;
			zip.file(file_name, data, {
				binary: true
			});
		})
		promises.push(promise)
	});
	// let loadingInstance = Loading.service({
	// 	text:"正在压缩..."
	// });
	Promise.all(promises).then(() => {
		zip.generateAsync({
			type: 'blob'
		}).then(content => {
			let dateStr = Vue.prototype.$Utils.fomatterDate_YYYYMMdd(new Date());
			loadingInstance.close();
			// 生成二进制流
			FileSaver.saveAs(content, zipName + '_' + dateStr + '.zip') // 利用file-saver保存文件  自定义文件名
			// saveAs(content, '文件下载.zip') // 利用file-saver保存文件  自定义文件名
		})
	})
}

//下载文件
// 这里的filename自己定义
export function downloadPdfFile(fileName, content) {
	// let aLink = document.createElement("a");
	// // let blob = base64ToBlob(content);
	// let blob = PdfDataURLtoBlob(content);
	// let evt = document.createEvent("HTMLEvents");
	// console.log("点击下载", evt);
	// evt.initEvent("click", true, true);
	// aLink.download = fileName;
	// aLink.href = URL.createObjectURL(blob);
	// aLink.click();

	// base64Data 是服务器获取到的数据
	// let file = b64toFile(content, fileName, 'application/pdf');
	// let dateStr = Vue.prototype.$Utils.fomatterDate_YYYYMMdd(new Date());
	// // loadingInstance.close();
	// // 生成二进制流
	// FileSaver.saveAs(file, fileName + '_' + dateStr + '.pdf') // 利用file-saver保存文件  自定义文件名
	// 利用FileSaver.js 下载文件为Excel文件
	// FileSaver.saveAs(file, fileName+".xls");

	let aLink = document.createElement('a');
	let base64 = "data:application/pdf;base64," + content;
	let blob = base64ToBlob(base64); //new Blob([content]);

	let evt = document.createEvent("HTMLEvents");
	evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
	aLink.download = fileName;
	aLink.href = URL.createObjectURL(blob);
	aLink.dispatchEvent(new MouseEvent('click', {
		bubbles: true,
		cancelable: true,
		view: window
	})); //兼容火狐
}
// base64转blob
function base64ToBlob(code) {
	let parts = code.split(";base64,");
	let contentType = parts[0].split(":")[1];
	let raw = window.atob(parts[1]);
	let rawLength = raw.length;
	let uInt8Array = new Uint8Array(rawLength);
	for (let i = 0; i < rawLength; ++i) {
		uInt8Array[i] = raw.charCodeAt(i);
	}
	return new Blob([uInt8Array], {
		type: contentType
	});
}

// function b64toFile(b64Data, filename, contentType) {
// 	let sliceSize = 512;
// 	let byteCharacters = atob(b64Data);
// 	let byteArrays = [];

// 	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
// 		let slice = byteCharacters.slice(offset, offset + sliceSize);
// 		let byteNumbers = new Array(slice.length);

// 		for (let i = 0; i < slice.length; i++) {
// 			byteNumbers[i] = slice.charCodeAt(i);
// 		}
// 		let byteArray = new Uint8Array(byteNumbers);
// 		byteArrays.push(byteArray);
// 	}

// 	let file = new File(byteArrays, filename, {
// 		type: contentType
// 	});
// 	return file;
// }
//base64
// function getBase64File(url) {
// 	return new Promise((resolve, reject) => {
// 		axios({
// 				method: 'get',
// 				url,
// 				responseType: 'blob'
// 				// responseType: 'arraybuffer'
// 			})
// 			.then(data => {
// 				console.log(data)
// 				resolve(data.data)
// 			})
// 			.catch(error => {
// 				reject(error.toString())
// 			})
// 	})
// }
//将base64转换为文件
// function dataURLtoFile(base64) {
// 	var arr = base64.split(','),
// 		bstr = atob(arr[1]),
// 		n = bstr.length,
// 		u8arr = new Uint8Array(n);
// 	while (n--) {
// 		u8arr[n] = bstr.charCodeAt(n)
// 	}
// 	return new File([u8arr], file.name, {
// 		type: 'pdf'
// 	})
// }

function dataURLtoBlob(dataurl) {
	return new Promise((resolve, reject) => {
		let arr = dataurl.split(","),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		resolve(new Blob([u8arr], {
			type: mime
		}))
	});
	// var arr = dataurl.split(","),
	// 	mime = arr[0].match(/:(.*?);/)[1],
	// 	bstr = atob(arr[1]),
	// 	n = bstr.length,
	// 	u8arr = new Uint8Array(n);
	// while (n--) {
	// 	u8arr[n] = bstr.charCodeAt(n);
	// }
	// return new Blob([u8arr], {
	// 	type: mime
	// });
}

//pdf base64转blob
function PdfDataURLtoBlob(dataurl) {
	return new Promise((resolve, reject) => {
		let mime = 'application/pdf',
			bstr = atob(dataurl),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		resolve(new Blob([u8arr], {
			type: mime
		}))
	});
}

// 压缩文件并下载
export function filePdfBase64ZipAndDownload(fileList, zipName) {
	const zip = new JSZip();
	const promises = [];
	let loadingInstance = Loading.service({
		text: "loading compress..."
	});
	fileList.forEach((item, index) => {
		const promise = PdfDataURLtoBlob(item.data).then(data => {
			console.log(data);
			let file_name = "";
			if (item.name) {
				file_name = item.name + '.pdf';
			} else {
				file_name = 'UnknownName_' + index + '.pdf';
			}
			zip.file(file_name, data, {
				binary: true
			});
		})
		promises.push(promise)
	});
	Promise.all(promises).then(() => {
		zip.generateAsync({
			type: 'blob'
		}).then(content => {
			let dateStr = Vue.prototype.$Utils.fomatterDate_YYYYMMdd(new Date());
			loadingInstance.close();
			// 生成二进制流
			FileSaver.saveAs(content, zipName + '_' + dateStr + '.zip') // 利用file-saver保存文件  自定义文件名
		})
	})
}
