<template>
	<div>
		<el-drawer :wrapperClosable="false" :title="$t('i18nn_8758fd50c87d6c9c')" append-to-body :visible.sync="dialogAddVisible" :direction="'rtl'"
			size="1000px">

			<div class="mainTem">
				<!-- <div class="buyerHeader">
				<h3 class="buyerHeaderTit">{{$t('i18nn_2f7a4906f66bffd1')}}</h3>
			</div> -->
				<div class="tableConTop">
					<el-row>
						<el-col :span="5" class="tableConTopLeft">
							<h3>
								<span class="tct_tit">{{$t('i18nn_8758fd50c87d6c9c')}}</span>
								<!-- <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
								<el-button type="warning" circle icon="el-icon-download" size="mini"
									@click="exportExcelAction()"></el-button>
							</el-tooltip> -->
							</h3>
						</el-col>
						<el-col :span="19" class="tableConTopRig">
							<!-- <ul class="actionConList"> -->
								<!-- <li> -->
									<el-button type="success" @click="openAddVal()" size="small" icon="el-icon-plus">{{$t('i18nn_24c8f46012a25c89')}}</el-button>
							
								<!-- </li> -->
							<!-- </ul> -->
							
							<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
							</el-button>
						</el-col>
					</el-row>
				</div>
				<!-- <div style="text-align: center; margin-top: 10px;">
		    <el-button-group>
		      <el-button type="primary"  size="medium" style="width: 200px;">{{$t('i18nn_5b14580d813b4a45')}}</el-button>
		      <el-button type="primary" plain size="medium" style="width: 200px;" @click="toPageUrl('PartnerBillSettlementDet')">{{$t('i18nn_1b613e926a1e65dd')}}</el-button>
		    </el-button-group>
		  </div> -->

				<div class="filterCon" style="">
					<!-- <div class="filterBarList">
						<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
						  <el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">
								<span>{{i18nFormatter(item.value)}}</span>
							</el-menu-item>
						</el-menu>
				</div> -->
					<div class="filterAction">
						<!-- <ul class="actionConList">
							<li>
								<el-button type="success" @click="openAddVal()" size="small" icon="el-icon-plus">{{$t('i18nn_24c8f46012a25c89')}}</el-button>

							</li>
						</ul> -->
						<ul class="filterConList">
							<!-- <li>
								<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
								<SelAgentUser @change="changeAgentUser"></SelAgentUser>
							</li> -->
							<!-- <li>
							<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
							<whNoSelect ref="whNoSelect" @changeData="changWhNo"></whNoSelect>
						</li> -->
							<!-- <li>
							<span>{{$t('i18nn_f566f50ef680920c')}}</span>
							<el-select filterable clearable v-model="filterData.orderType" :placeholder="$t('FormMsg.Please_select')" size="small" style="width: 100px;" @change="initData()">
								<el-option v-for="item in selectOption.wh_work_order_type" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
							</el-select>
						</li> -->
							<!-- <li>
							<span>{{$t('i18nn_6235565b185f0725')}}</span>
								<el-input :placeholder="$t('FormMsg.Please_Enter')" size="small" v-model="filterData.workNo" maxlength="50" :clearable="true" style="width: 220px;"></el-input>
							
						</li> -->
							<li>
								<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
								<el-input type="text" v-model="filterData.keyword" size="small" clearable
									@keyup.enter.native="initData()" maxlength="50" :placeholder="$t('i18nn_3ee4c9b76289e93a')"
									style="width: 180px;" />
							</li>
							<!-- <li>
							<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
							<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
								maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;"></el-input>
						</li> -->

							<li>
								<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
							</li>
							<!-- <li>
							<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()"></el-button>
						</li> -->
						</ul>
					</div>
				</div>

				<!-- "name": "", //"地址名称",
				"address": "", //"地址",
				"city": "", //"城市",
				"state": "", //"州",
				"zipCode": "", //"邮编",
				"country": "US", //"US"
				"phone": null, //"联系方式", -->


				<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
					<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
						:max-height="$store.state.frameConHeightWh1">
						<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
							:label="$t('Storage.tableColumn.no')"></el-table-column>
						<el-table-column prop="code" :label="$t('i18nn_5e7740c52e22eefc')">
						</el-table-column>

						<el-table-column prop="name" :label="$t('i18nn_20650eaf339e1fe6')"></el-table-column>
						
						
						<el-table-column prop="address" :label="$t('i18nn_8758fd50c87d6c9c')"></el-table-column>
						<el-table-column prop="city" :label="$t('i18nn_e05b5d049b64b040')"></el-table-column>
						<el-table-column prop="state" :label="$t('i18nn_7cab79716236ebb0')">
						</el-table-column>
						<el-table-column prop="zipCode" :label="$t('i18nn_c4913ab43009b365')">
						</el-table-column>
						<el-table-column prop="country" :label="$t('i18nn_b166e4e8fe9513fa')">
						</el-table-column>
						<el-table-column prop="phone" :label="$t('i18nn_c2d9cc88561f8cdc')">
						</el-table-column>

						<el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')"></el-table-column>

						<el-table-column :label="$t('Storage.tableColumn.operation')" width="220px" fixed="right" align="left">
							<template slot-scope="scope">
								<div>
									<div style="margin-bottom: 10px;">


										<el-button @click="selAction($event, scope.row)" type="primary" size="mini"
											icon="el-icon-magic-stick">{{$t('i18nn_7fb6ada66a1fccca')}}</el-button>

										<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
											icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>

										<!-- <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
											icon="el-icon-edit">
											{{ $t('FormMsg.Edit') }}
										</el-button> -->
									</div>

								</div>

							</template>
						</el-table-column>
					</el-table>

					<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination"
							v-on:updatePageData="getPageData"></hy-page-pagination></div>
				</div>
			</div>
		</el-drawer>

		<TransportAddressEdit :openTime="addValOpenTime" :id="addValId" @success="successBack"></TransportAddressEdit>
	</div>


</template>
<script>
	// import {
	// 	workOrderList_status
	// } from '@/i18n/langStatus.js';
	import {
		i18nStatus
	} from '@/i18n/FormatI18n.js';

	// import dialogLogList from '@/components/StorageCenter/workOrder/dialogLogList.vue';

	// import whFileUpload from '@/components/StorageCenter/components/whFileUpload.vue';

	import TransportAddressEdit from '@/components/StorageCenter/Transport/TransportAddressEdit.vue';

	// import workOrderDet from '@/components/StorageCenter/workOrder/workOrderDet.vue';
	// import workOrderFileUpload from '../components/workOrderFileUpload.vue';
	// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
	// import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	// import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	export default {
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			row: {
				default: function() {
					return {};
				},
				type: Object
			},
		},
		components: {
			// whFileUpload,
			// workOrderDet,
			// dialogLogList,
			// whLabelFileUpload,
			// SelAgentUser,
			// whNoSelect,
			TransportAddressEdit
			// workOrderFileUpload
		},
		data() {
			return {

				dialogAddVisible: false,

				// dialogShowDet: false,
				// workId: null,
				// workDetOpenTime: '',
				addValOpenTime: "",
				addValId: "",

				// fileRelationId: '',
				// FileUploadOpenTime: '',

				//处理日志
				// dialogLogOpenTime: '',
				// OpenLogData: {},

				// fileWorkNo: '',
				// FileUploadOpenTime: '',
				// FileUploadKey:'',

				// isShowFileUpload: false,
				// fileRelationId: '',
				// FileUploadOpenTime: '',
				// activeName: 'second',
				// isShowFrom:false,
				// pickerOptions1: {
				//     disabledDate(time) {
				//       return time.getTime() > Date.now();
				//     },
				//     shortcuts: [{
				//       text: this.$t('i18nn_0200bd47bb4bb83d'),
				//       onClick(picker) {
				//         picker.$emit('pick', new Date());
				//       }
				//     }, {
				//       text: this.$t('i18nn_84b73bfc6dada445'),
				//       onClick(picker) {
				//         const date = new Date();
				//         date.setTime(date.getTime() - 3600 * 1000 * 24);
				//         picker.$emit('pick', date);
				//       }
				//     }, {
				//       text: this.$t('i18nn_e3f3138cf5ba3f6d'),
				//       onClick(picker) {
				//         const date = new Date();
				//         date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
				//         picker.$emit('pick', date);
				//       }
				//     }]
				//   },
				//打开入库选择框
				// dialogSelVisible: false,
				// openRowData: {},
				// openRowIndex: {},

				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改
				dialogFormMsg: '',

				//导入excel
				// dialogUploadVisible: false,
				// loadingExcel: false,
				// fileExcel: '',
				// excelData: [],
				// excelFileName: '',

				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',

				loading: false,

				loading_det: false,

				// form: {
				//   id: '', //"数据ID",
				//   packingNo: '', //this.$t('i18nn_b7a10fe4e9528362'),
				//   leavePort: '', //this.$t('i18nn_ed47d0c198c61d01'),
				//   arrivePort: '', //this.$t('i18nn_d3d95bf498ae6cdb'),
				//   leaveDate: '', //this.$t('i18nn_04e92fdefcddec84'),
				//   arriveDate: '', //this.$t('i18nn_b5c03c6f1b9b1e28'),
				//   packageType: '', //this.$t('i18nn_b799f516e3940e3f'),
				//   packRecordList: [
				//     {
				//       goodsSku: '', //this.$t('i18nn_31f887c1ada79a99'),
				//       // cusCode: '', //this.$t('i18nn_64fed2851f79d875'),
				//       goodsName: '', //this.$t('i18nn_b23004db5349dfd2'),
				//       goodsNameEn: '', //this.$t('i18nn_05141ac0139eaf01'),
				//       goodsImg: '', //this.$t('i18nn_877c5a0e44a0eb07'),
				//       // defCode: '', //this.$t('i18nn_791e36963e37617a'),
				//       // hashCode: '', //this.$t('i18nn_198738759379a3c0'),
				//       // declareNameCh: '', //this.$t('i18nn_73d237ea940222ce'),
				//       // declareNameEn: '', //this.$t('i18nn_9894786aad05f849'),
				//       declarePrice: '', //this.$t('i18nn_fe28507421c4c3b3'),
				//       goodsWeight: '', //this.$t('i18nn_11e41b2ec3d872ed'),
				//       // weightUnit: '', //this.$t('i18nn_8e01866868930d09'),
				//       sizeUnit: '', //this.$t('i18nn_38bc71c0dc55904b'),
				//       goodsLength: '', //this.$t('i18nn_2cba96917484569a'),
				//       goodsWidth: '', //this.$t('i18nn_9e24e0e4745f4948'),
				//       goodsHeight: '', //this.$t('i18nn_c5fa0857bc0df2d6'),
				//       safeStock: '', //this.$t('i18nn_004617dc47191cb8'),
				//       packCount: '', //this.$t('i18nn_b9d4d3f6183bf3c1'),
				//       proxyCus: '' //货代客人
				//       // "id":"",//"数据id"
				//     }
				//   ]
				// },
				//附件
				// fileWatchKey: '1',
				// fileList: [],
				// fileId: this.$store.getters.getUserInfo.id,

				// form: {
				// 	id: '', //"数据ID",
				// 	title: '', //this.$t('i18nn_7431e76029678ec7'),
				// 	orderType: '', //this.$t('i18nn_f566f50ef680920c'),
				// 	contentStr: '', //this.$t('i18nn_3e358d140504ab46'),
				// 	attachments: [
				// 		{
				// 			id: '', //"数据ID",
				// 			url: '', //"文件URL",
				// 			fileName: '' //this.$t('i18nn_89221ad15d2ec113')
				// 		}
				// 	]
				// },

				// formRules: {
				// 	title: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	contentStr: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	orderType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }]

				// 	// defCode: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	// hashCode: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	// declareNameCh: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	// declareNameEn: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	// declarePrice: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	// goodsWeight: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	// weightUnit: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }],
				// 	// goodsLength: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	// goodsWidth: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	// goodsHeight: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	// volumeUnit: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }],
				// 	// whFeeType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	// whFeeAmt: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// },
				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					// wh_no: [],
					// wh_vol_unit: [],
					// wh_goods_fee_type: [],
					wh_work_order_type: [],
					// wh_packing_type: [],
					// statusList: workOrderList_status
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					// agentUser: "",
					// "accountPeriod":"",
					// "billNo":""
					// status: '',
					// packingNo: '',
					// orderType: '',
					// whNo: '',
					keyword: ''
					// putWhNo: '',
					// goodsSku: '',
					// hashCode: '',
					// goodsName: '',
					// declareNameCh: ''
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogAddVisible = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();
			// if (this.$route.query.state) {
			// 	this.filterData.status = this.$route.query.state;
			// 	// this.initData();
			// }
		},
		//编译挂载前
		mounted() {
			// this.getDicData();

			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.tableData = [];
				this.getPageData();
				// this.getDicData();
			},
			// i18nFormatter(value) {
			// 	return i18nStatus(value, this.selectOption.statusList)
			// },
			// changeAgentUser(val) {
			// 	this.filterData.agentUser = val;
			// 	this.initData();
			// },
			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.filterData.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	this.initData();
			// },

			openAddVal() {
				this.addValOpenTime = new Date().getTime();
				this.addValId = "";
			},

			//打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.addValOpenTime = new Date().getTime();
			// 	this.addValId = row.id;
			// },

			successBack() {
				this.initData();
			},

			//处理日志
			// openDioalogLog(event, row){
			// 	event.stopPropagation();
			// 	this.dialogLogOpenTime = new Date().getTime();
			// 	this.OpenLogData = row;
			// },
			// LogSubmitSuccess(data) {
			// 	this.initData();
			// },

			//打开查看
			// openView(event, row, index) {
			// 	event.stopPropagation();
			// 	// if('40'==row.orderType){//一件代发
			// 	// 	this.$router.push({'name':'WarehouseDropShipping',query:{workNo:row.workNo}})
			// 	// } else if('30'==row.orderType){//自提单
			// 	// 	this.$router.push({'name':'WarehouseShipments',query:{workNo:row.workNo}})
			// 	// } else if('60'==row.orderType){//入库申请
			// 	// 	this.$router.push({'name':'InWarehouse',query:{workNo:row.workNo}})
			// 	// } else if('50'==row.orderType){//退货换标
			// 	// 	this.$router.push({'name':'WarehouseExchangeReturn',query:{workNo:row.workNo}})
			// 	// } else {//其他工单
			// 	// 	this.$router.push({'name':'createWorkOrder',query:{id:row.id,orderType:row.orderType}})
			// 	// }
			// 	// this.$router.push({'name':'workOrderDet',query:{id:row.id}})
			// 	// this.openDioalog(row, this.$t('FormMsg.Edit'));
			// 	// this.getPageDetData(row.id);

			// 	this.dialogShowDet = true;
			// 	this.workId = row.id;
			// 	this.workDetOpenTime = new Date().getTime();
			// },

			//打开附件上传
			// openUploadFile(event, row){
			//   event.stopPropagation();
			//   // this.isShowFileUpload = true;
			//   this.fileRelationId = row.id;
			//   this.FileUploadOpenTime = new Date().getTime();
			// },
			// //附件上传成功回调
			// FileUploadSuccess(data){
			//   this.initData();
			//   // event.stopPropagation();
			//   // this.isShowFileUpload = true;
			//   // this.fileRelationId = row.id;
			//   // this.FileUploadOpenTime = new Date().getTime();
			// },
			selAction(event, row) {
				event.stopPropagation();
				this.dialogAddVisible = false;
				this.$emit('selSuccess', row)
			},


			//删除
			delAction(event, row) {
				event.stopPropagation();
				this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					})
					.then(() => {
						this.delDataAction(event, row);
					})
					.catch(() => {

					});
			},
			//删除
			delDataAction(event, row) {
				event.stopPropagation();
				console.log('delDataAction', row);

				// this.postData(this.$urlConfig.WhWorkOrderDel + '/' + row.id, {}, 'delete');

				this.postData(this.$urlConfig.WhTransferAddressDel + "/" + row.id, {}, 'delete');

			},

			//打开label上传
			// openUploadLabelFile(event, row) {
			//   event.stopPropagation();
			//   this.fileWorkNo = row.workNo;
			// 	// this.FileUploadKey = row.orderNumber;
			//   this.FileUploadOpenTime = new Date().getTime();
			// },

			//label上传成功回调
			// FileUploadSuccess(data) {
			//   // this.initData();
			// 	console.log(data);
			// },

			//提交工单
			// commitAction(event, row) {
			//   event.stopPropagation();

			//   // this.$confirm(this.$t('Storage.DropShipping.sure_submit_1')+parm.length+this.$t('Storage.DropShipping.sure_submit_2'), this.$t('tips.tipsTitle'), {
			//   //   // confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//   //   // cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//   //   type: 'warning'
			//   // })
			//   //   .then(() => {
			//       this.postData(this.$urlConfig.WhWorkOrderSubmit+'/'+row.id, {});
			//     // })
			//     // .catch(() => {});
			// },



			//提交信息
			postData(url, formData, type) {
				// HttpTypelet _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					
					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						this.getPageData();

						if ("delete" == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
								type: 'success'
							});
						}

					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},


			//查询数据
			// serPageData() {
			// 	this.pagination.current_page = 1;
			// 	this.getPageData();
			// },
			handleSelectStatus(key, keyPath) {
				// console.log(key, keyPath);
				this.filterData.status = key;
				this.initData();
			},
			//请求分页数据
			getPageData() {
				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhTransferAddressPageList, {
						// sortAsc: this.filterData.sortAsc,
						// orderBy: this.filterData.orderBy,
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目

						// workNo: this.filterData.workNo ? this.filterData.workNo : null,
						// proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
						// status: this.filterData.status ? this.filterData.status : null,
						// orderType: this.filterData.orderType ? this.filterData.orderType : null,
						// whNo: this.filterData.whNo ? this.filterData.whNo : null,
						keyword: this.filterData.keyword ? this.filterData.keyword : null,
					})
					.then(({
						data
					}) => {
						console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

			// toDropShipping($event, row) {
			// 	$event.stopPropagation();
			// 	this.$router.push({name:'WarehouseDropShipping',query:{workOrderId:row.id}})

			// },
			//
			// showDetList($event, row) {
			// 	$event.stopPropagation();
			// 	this.dialogShowDet = true;
			// 	this.workId = row.id;
			// },

			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_work_order_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				this.selectOption.wh_work_order_type = data.data['wh_work_order_type'];
			// 				// this.selectOption.wh_no = data.data['wh_no'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-input-number,
	// .el-select {
	// 	// width: 100px;
	// 	.el-input__inner {
	// 		text-align: left;
	// 	}
	// }
	// .form_msg {
	// 	color: #e6a23c;
	// }
</style>