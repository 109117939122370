// import html2canvas from "html2canvas";
// import {
// 	jsPDF
// } from "jspdf";


import axios from 'axios'

import saveAs from 'file-saver';

import {
	Loading,
	// Notification,
	MessageBox
} from 'element-ui'; //项目已经全局引入element的话可以不单独引入

//pdfjsLib
var pdfjsLib = window['pdfjs-dist/build/pdf'] || window.pdfjsLib;
// pdfjsLib.GlobalWorkerOptions.workerSrc = '/lib/pdfjs-2.12.313-dist/build/pdf.worker.js';
try {
	pdfjsLib.GlobalWorkerOptions.workerSrc = '/lib/pdfjs-2.12.313-dist/build/pdf.worker.js';
} catch (e) {
	//TODO handle the exception
	console.log(e);
}
let HY_BASEURL = "";
if (process.env.NODE_ENV === 'development') {
	// dev 开发 run dev
	HY_BASEURL = "/baseUrl";
} else {
	// build 线上和测试 run build
	HY_BASEURL = "";
}

const Axios = axios.create({
	// baseURL: 'https://some-domain.com/api/',
	timeout: 60000,
	// headers: {'X-Custom-Header': 'foobar'}
	// 和后端 Content-Type 统一，跨域有option请求
	// headers:{'Content-Type':'application/x-www-form-urlencoded'}
	// headers: {'Content-Type':'application/json'}
	// headers: {'Content-Type':'application/json; charset=utf-8'} //默认
});

let loading //定义loading变量

function startLoading() { //使用Element loading-start 方法
	loading = Loading.service({
		lock: true,
		text: 'loading……',
		background: 'rgba(0, 0, 0, 0.7)'
	})
}

function endLoading() { //使用Element loading-close 方法
	loading.close()
}
// html2canvas(document.getElementById("content"), {
//        onrendered: function(canvas) {

//            //通过html2canvas将html渲染成canvas，然后获取图片数据
//            var imgData = canvas.toDataURL('image/jpeg');

//            //初始化pdf，设置相应格式
//            var doc = new jsPDF("p", "mm", "a4");

//            //这里设置的是a4纸张尺寸
//            doc.addImage(imgData, 'JPEG', 0, 0,210,297);

//            //输出保存命名为content的pdf
//            doc.save('content.pdf');
//        }
//    });
//拆分pdf，并展示文件名
export function pdfSplitView(pdfData) {
	// startLoading();
	console.log('----start pdfSplitView----');
	return new Promise(async (resolve, reject) => {
		let pdfDoc = {};
		pdfDoc = await pdfjsLib.getDocument({
			data: getUint8Array(pdfData.data)
		}).promise.catch(err => {
			console.log(err);
			// endLoading();
			reject(err);
		});
		// console.log('pdfDoc',pdfDoc,pdfDoc.numPages);

		let pdfView = document.getElementById('pdf_view');
		// let ul =document.createElement("ul");
		// pdfView.appendChild(ul);

		for (var i = 1; i <= pdfDoc.numPages; i++) {
			let pdfPage = await pdfDoc.getPage(i);
			// console.log('pdfPage',pdfPage);
			// console.log('pdfPage.commonObjs',pdfPage.commonObjs);
			// console.log('getTextContent',pdfPage.getTextContent());
			// let TextContent = await pdfPage.getTextContent();
			// console.log('getTextContent',TextContent);
			// console.log('streamTextContent',pdfPage.streamTextContent());

			let scale = 1;
			let viewport = pdfPage.getViewport({
				scale: scale
			});
			// console.log('viewport',viewport);
			// if(viewport.height > window.screen.height){
			//   scale = (window.screen.height / viewport.height).toFixed(1);
			//   viewport = pdfPage.getViewport(scale);
			// }
			let li = document.createElement("li");
			let canvas = document.createElement('canvas');
			let canvasContext = canvas.getContext('2d');
			canvas.height = viewport.height;
			canvas.width = viewport.width;
			// 名称
			// canvasContext.fillStyle = "#409EFF";//设置字体的颜色
			// canvasContext.font = "28px";
			// canvasContext.fillText(pdfData.name, 5, 5);
			// canvas.height = 500;
			// canvas.width = 300;
			// console.log('canvas',canvas);
			li.textContent = pdfData.name + '.pdf';
			li.appendChild(canvas);
			pdfView.appendChild(li);
			// 将页面呈现到画布上
			let renderContext = {
				canvasContext: canvasContext,
				viewport: viewport
			}
			pdfPage.render(renderContext);
		}
		// endLoading();
		resolve(pdfDoc);

	})
}


// function renderPDF2Canvas(eachPDF) {
// 	const viewport = eachPDF.getViewport(8);
// 	const canvas = document.createElement("canvas");
// 	const context = canvas.getContext('2d');
// 	canvas.height = viewport.height;
// 	canvas.width = viewport.width;
// 	const renderPromise = eachPDF.render({
// 		canvasContext: context,
// 		viewport: viewport
// 	})
// 	return {
// 		canvas,
// 		renderPromise
// 	}
// }

// function renderPDFS(allPDF) {
// 	return allPDF.map(eachPDF => {
// 		return renderPDF2Canvas(eachPDF)
// 	})
// }

// function pdf2img(pdfFile) {

//     // eslint-disable-next-line
//     return pdfjsLib.getDocument(pdfFile).then((pdf) => {
//         const numPages = pdf.numPages;
//         const allPromise = [];
//         for (let i = 1; i <= numPages; i++) {
//             allPromise.push(pdf.getPage(i))
//         }
// 				// numPages.forEach((item,index)=>{
// 				// 	allPromise.push(pdf.getPage(index))
// 				// })
//         return Promise.all(allPromise)
//     }).then((allPDF) => {
//         return renderPDFS(allPDF)
//     }).then(allRender => {
//         console.log(allRender)
//         const allCanvasOKPromises = allRender.map(render => render.renderPromise);
//         const allCanvas = allRender.map(render => render.canvas);
//         return Promise.all(allCanvasOKPromises).then(() => {
//             return allCanvas.map(canvas => {
//                 return canvas.toDataURL();
//             })
//         })
//     }).then(allBase64 => {

//         return allBase64
//     })
// }

//获取pdf页数
export async function getLabelPdfPageNum(pdfFileList) {
	startLoading();

	// await pdfFileList.forEach(async(item)=>{
	// 	let pdfData = await axiosDownloadPDF2(item.labelUrl);
	// 	let pdf = await pdfjsLib.getDocument(pdfData);

	// 	let numPages = pdf._pdfInfo.numPages;

	// 	item.pdfNum = numPages;
	// })
	let arr = [];
	for (let j = 0; j < pdfFileList.length; j++) {
		let item = pdfFileList[j];
		let pdfData = await axiosDownloadPDF2(item.labelUrl);
		let pdf = await pdfjsLib.getDocument(pdfData);
		let numPages = pdf.numPages;
		item.pdfNum = numPages;
		// pdfFileList[j] = item;
		arr.push(item)
	}
	// let BASE64 = BASE64.substring(28, BASE64.length - 1);
	// let  BASE64_at = base64ToUint8Array(BASE64);
	// let allBase64 = await pdf2img({data:BASE64_at});
	// let pdfData = await axiosDownloadPDF2(item.labelUrl);
	// let pdf = await pdfjsLib.getDocument(pdfData);

	// let numPages = pdf._pdfInfo.numPages;
	// console.log('pdf',pdf);
	endLoading();
	// return pdf._pdfInfo.numPages;
	// console.log('pdfFileList',pdfFileList);
	return arr;


	// return pdfjsLib.getDocument(pdfData).then((pdf) => {
	//     const numPages = pdf.numPages;
	// 		console.log('numPages',numPages);

	// 		endLoading();
	// 		return numPages;
	// });

}

async function axiosDownloadPDF2(url) {
	// if (!(/^https?:/i.test(url))) {
	// 	return url;
	// }
	// Axios.post();
	// let HY_BASEURL = "/baseUrl";
	let config_url = HY_BASEURL + "/wh/download/label";
	let params = {
		"url": url
	};
	// let res = "";
	// /wh/download/label  PUT   {"url":""}
	let res = await Axios.put(config_url, params, {
		responseType: 'arraybuffer'
	});
	// console.log('res', res);
	return res.data;
	// const blob = new Blob([res.data]); //处理文档流
	// return await blobToDataURI(blob);
}
// function base64ToUint8Array(base64String) {
//     let padding = '='.repeat((4 - base64String.length % 4) % 4);
//     let base64 = (base64String + padding)
//         .replace(/\-/g, '+')
//         .replace(/_/g, '/');

//     let rawData = window.atob(base64);
//     let outputArray = new Uint8Array(rawData.length);

//     for (var i = 0; i < rawData.length; ++i) {
//         outputArray[i] = rawData.charCodeAt(i);
//     }
//     return outputArray;
// }
//BOL下载
export async function downloadPdfBuffer(url, fileName) {
	// let blob = new Blob([buffer]);
	// const url = URL.createObjectURL(blob);
	// // 借助第一类方式：location.href、iframe.src、a[download].click()
	// window.open(url);
	// let config_url = url;
	// let params = {
	// 	"url": url
	// };
	// let res = "";
	// /wh/download/label  PUT   {"url":""}
	// let res = await Axios.get(config_url, {}, 
	// // {
	// // 	responseType: 'arraybuffer'
	// // },
	// );

	let xhr = {};
	if (window.XMLHttpRequest) {
		xhr = new XMLHttpRequest();
	}
	// else var xhr = new ActiveXObject("MSXML2.XMLHTTP");
	xhr.open('GET', url, false); //同步方式
	let arrybuffer = false;
	if (xhr.overrideMimeType) {
		try {
			xhr.responseType = 'arraybuffer';
			// xhr.responseType = 'blob';
			arrybuffer = true;
		} catch (err) {
			xhr.overrideMimeType('text/plain; charset=x-user-defined');
		}
		xhr.send(null);
	}

	let data = xhr.response || xhr.responseBody;
	let buffer = data;
	let dataArray = [];
	if (typeof Uint8Array !== 'undefined') {
		if (arrybuffer) {
			dataArray = new Uint8Array(data);
		} else {
			dataArray = new Uint8Array(data.length);
			for (let i = 0; i < dataArray.length; i++) {
				dataArray[i] = data.charCodeAt(i);
			}
		}
	} else {

	}

	// let dataArray = new Uint8Array(data);
	let base64_pdf = demoGetBASE64(dataArray);
	console.log(base64_pdf);
	// saveAs(
	// 	new Blob([buffer], {
	// 		type: 'application/pdf',
	// 	}),
	// 	fileName + '.' + 'pdf'
	// );
	// var file = new File(dataArray, 'test.pdf', {type: 'application/pdf'});
	// saveAs(
	// 	file,
	// 	fileName + '.' + 'pdf'
	// );
	saveAs(
		b64toFile(base64_pdf, 'test.pdf', 'application/pdf'),
		fileName + '.' + 'pdf'
	);
}

function b64toFile(b64Data, filename, contentType) {
	var sliceSize = 512;
	var byteCharacters = atob(b64Data);
	var byteArrays = [];

	for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		var slice = byteCharacters.slice(offset, offset + sliceSize);
		var byteNumbers = new Array(slice.length);

		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		var byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
	var file = new File(byteArrays, filename, {
		type: contentType
	});
	return file;
}

export const base64toFile = (b64Data, filename, contentType) => {
	return b64toFile(b64Data, filename, contentType);
}

function demoGetBASE64(dataArray) {
	var digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
	var strData = "";
	for (var i = 0, ii = dataArray.length; i < ii; i += 3) {
		if (isNaN(dataArray[i])) break;
		var b1 = dataArray[i] & 0xFF,
			b2 = dataArray[i + 1] & 0xFF,
			b3 = dataArray[i + 2] & 0xFF;
		var d1 = b1 >> 2,
			d2 = ((b1 & 3) << 4) | (b2 >> 4);
		var d3 = i + 1 < ii ? ((b2 & 0xF) << 2) | (b3 >> 6) : 64;
		var d4 = i + 2 < ii ? (b3 & 0x3F) : 64;
		strData += digits.substring(d1, d1 + 1) + digits.substring(d2, d2 + 1) + digits.substring(d3, d3 + 1) + digits
			.substring(d4, d4 + 1);
	}
	return strData;
}
// export function htmlToPdf(domId, pdfName) {
// 	console.log("htmlToPdf", domId, pdfName);
// 	// var target = document.getElementsByClassName("right-aside")[0];
// 	var target = document.getElementById(domId);
// 	// var target = domId;
// 	// target.style.background = "#FFFFFF";

// 	// console.log("target", target);
// 	html2canvas(target).then(canvas => {

// 		//通过html2canvas将html渲染成canvas，然后获取图片数据
// 		//             var imgData = canvas.toDataURL('image/jpeg');

// 		//             //初始化pdf，设置相应格式
// 		//             var doc = new jsPDF("p", "mm", "a4");

// 		//             //这里设置的是a4纸张尺寸
// 		//             doc.addImage(imgData, 'JPEG', 0, 0,210,297);

// 		//             //输出保存命名为content的pdf
// 		//             doc.save('content.pdf');


// 		let dataURL = canvas.toDataURL("image/png");
// 		console.log('dataURL', dataURL);
// 		// this.imgUrl = dataURL;
// 		// if (this.imgUrl !== "") {
// 		//   this.dialogTableVisible = true;
// 		// }
// 		console.log("onrendered", canvas);
// 		var contentWidth = canvas.width;
// 		var contentHeight = canvas.height;

// 		//一页pdf显示html页面生成的canvas高度;
// 		var pageHeight = contentWidth / 592.28 * 841.89;
// 		//未生成pdf的html页面高度
// 		var leftHeight = contentHeight;
// 		//页面偏移
// 		var position = 0;
// 		//a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
// 		var imgWidth = 595.28;
// 		var imgHeight = 592.28 / contentWidth * contentHeight;

// 		var pageData = canvas.toDataURL('image/jpeg', 1.0);

// 		var pdf = new jsPDF('', 'pt', 'a4');

// 		//有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
// 		//当内容未超过pdf一页显示的范围，无需分页
// 		if (leftHeight < pageHeight) {
// 			pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
// 		} else {
// 			while (leftHeight > 0) {
// 				pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
// 				leftHeight -= pageHeight;
// 				position -= 841.89;
// 				//避免添加空白页
// 				if (leftHeight > 0) {
// 					pdf.addPage();
// 				}
// 			}
// 		}

// 		pdf.save(pdfName + ".pdf");
// 	});
// 	// html2canvas(target, {
// 	// 	onrendered: function(canvas) {
// 	// 		console.log("onrendered",canvas);
// 	// 		var contentWidth = canvas.width;
// 	// 		var contentHeight = canvas.height;

// 	// 		//一页pdf显示html页面生成的canvas高度;
// 	// 		var pageHeight = contentWidth / 592.28 * 841.89;
// 	// 		//未生成pdf的html页面高度
// 	// 		var leftHeight = contentHeight;
// 	// 		//页面偏移
// 	// 		var position = 0;
// 	// 		//a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
// 	// 		var imgWidth = 595.28;
// 	// 		var imgHeight = 592.28 / contentWidth * contentHeight;

// 	// 		var pageData = canvas.toDataURL('image/jpeg', 1.0);

// 	// 		var pdf = new jsPDF('', 'pt', 'a4');

// 	// 		//有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
// 	// 		//当内容未超过pdf一页显示的范围，无需分页
// 	// 		if (leftHeight < pageHeight) {
// 	// 			pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
// 	// 		} else {
// 	// 			while (leftHeight > 0) {
// 	// 				pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
// 	// 				leftHeight -= pageHeight;
// 	// 				position -= 841.89;
// 	// 				//避免添加空白页
// 	// 				if (leftHeight > 0) {
// 	// 					pdf.addPage();
// 	// 				}
// 	// 			}
// 	// 		}

// 	// 		pdf.save(pdfName+".pdf");
// 	// 	}
// 	// })
// }


// import saveAs from 'file-saver';

// import Excel from 'exceljs'

// let workbook;
// let worksheet;

// function init() {
// 	workbook = new Excel.Workbook();
// 	workbook.created = new Date();
// 	workbook.modified = new Date();
// 	worksheet = workbook.addWorksheet('sheet1');
// }


// function exportExcel(columns, Data, fileName) {
// 	init();

// 	columns = columns.map((item) => {
// 		return Object.assign({}, {
// 				header: item.title,
// 				width: item.types == 'date' || item.types == 'image' ? 60 : 20,
// 				// style: { font: { bold: true } }
// 			},
// 			item
// 		);
// 	});
// 	worksheet.columns = columns;

// 	  for (let i = 0; i < Data.length; i++) {
// 	    let list = [];
// 	    let rowData = {};
// 	    for (let j = 0; j < columns.length; j++) {
// 	      if (columns[j].types == 'text' || columns[j].types == undefined) {
// 	        rowData[columns[j].key] = Data[i][columns[j].key];
// 	      } else if (columns[j].types == 'date') {
// 	        // rowData[columns[j].key] = timeFormat(Data[i][columns[j].key]);
// 	      } else if (columns[j].types == 'custom') {
// 					// console.log(Data[i]);
// 					// console.log(columns[j]);
// 					try {
// 						rowData[columns[j].key] = columns[j].formatter(Data[i]);
// 					} catch(err){
// 						console.log(err);
// 					}
// 	      } else if (columns[j].types == 'image') {
// 				let base64 = Data[i][columns[j].key];
// 				if(base64){
// 					let imageId2 = workbook.addImage({
// 					  base64: base64,
// 					  extension: 'jpeg',
// 					});
// 					worksheet.addImage(imageId2, {
// 					  tl: { col: 0.5 + j, row: 1.5 + i },
// 					  ext: { width: 376, height: 142 },
// 					});
// 				}

// 	      }
// 	    }
// 		 // 添加一行数据
// 		 list.push(rowData);
// 		 worksheet.addRows(list);
// 		 // 获取最后一行
// 		 let row = worksheet.lastRow;
// 		 // 设置特定的行高
// 		 row.height = 142;
// 		 row.commit();

// 		 // 设置单元格样式
// 		 for (let n = 0; n < columns.length; n++) {
// 		   let dobCol = worksheet.getColumn(n + 1);
// 		   dobCol.eachCell(function(cell, rowNumber) {
// 		     cell.alignment = { vertical: 'middle', horizontal: 'center' };
// 		   });
// 		 }
// 	}

// 	console.log("workbook", workbook);
// 	workbook.xlsx.writeBuffer().then((buffer) => {
// 		saveAs(
// 			new Blob([buffer], {
// 				type: 'application/octet-stream',
// 			}),
// 			fileName + '.' + 'xlsx'
// 		);
// 	});
// }

/**
 * 函数名：getUint8Array
 * 简介：将base64 格式的字符串转换成 uint8Array （pdf.js 无法直接接受base64 格式的参数）
 * 参数：base64_string
 * return：Array
 */
function getUint8Array(base64Str) {
	let data = base64Str.replace(/[\n\r]/g, ''); // 替换多余的空格和换行
	// console.log('getUint8Array',data);
	var raw = window.atob(data);
	var rawLength = raw.length;
	var array = new Uint8Array(new ArrayBuffer(rawLength));
	for (var i = 0; i < rawLength; i++) {
		array[i] = raw.charCodeAt(i)
	}
	return array
}
// export default {
// 	htmlToPdf,
// };