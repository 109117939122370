<template>
	<div class="">
		<!-- <el-drawer :wrapperClosable="false" :title="$t('i18nn_10bc8a2b45aa5636')" append-to-body :visible.sync="dialogFile" :custom-class="'drawerStyle1'" v-loading="loadingFile"> -->
		<!-- <el-dialog :title="$t('i18nn_10bc8a2b45aa5636')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1000px" top="0" v-loading="loadingFile"> -->

		<!--本页切换列表-->
		<div>
			<div class="tableCon" v-loading="loading_load" element-loading-text="加载中...">
				<div class="tableConTable">
					<div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<el-button type="primary" icon="el-icon-plus" size="small" @click="openAddNew()">
									{{ $t('i18nn_e8564657bbe9ca53') }}
								</el-button>
							</el-col>
							<el-col :span="19" class="tableConTopRig">
								<span>
									<span><span>{{$t('i18nn_f085596674018f3e')}}</span>ID</span>:
									{{relationId}}
								</span>
								<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
							</el-col>
						</el-row>
					</div>

					<!-- <div>
						<div class="filterCon" style="">
							<ul class="filterConList">
								<li>
									<span>关联ID</span>:
									{{relationId}}
								</li>
							</ul>
						</div>
					</div> -->

					<div>
						<el-table :data="tableData" stripe :border="true" :max-height="$store.state.frameConHeightWh3"
							style="width: 100%" size="small">

							<el-table-column type="index" width="50" align="center" :label="$t('7b1c2b1adc920d9c')">
							</el-table-column>

							<el-table-column prop="workNo" :label="$t('i18nn_6235565b185f0725')">
								<template slot-scope="scope">
								  <div>
								    <!-- <el-link type="primary" @click="toWorkOrder($event,scope.row)">{{scope.row.workNo}}</el-link> -->
										
										<div class="link-copy">
											<span class="link-text" @click="toWorkOrder($event, scope.row, scope.$index)">
												{{ scope.row.workNo }}
											</span>
											<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')" placement="top">
											  <i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.workNo)"></i>
											</el-tooltip>
										</div>
								  </div>
								</template>
							</el-table-column>

							<el-table-column prop="sysOrderNo" :label="$t('i18nn_52a8c38184d84581')"></el-table-column>
							<el-table-column prop="relationNo" :label="$t('i18nn_158db9252e21d1a6')"></el-table-column>
							<el-table-column prop="bizTypeName" :label="$t('i18nn_32b164c53fa35e6d')"></el-table-column>

							<el-table-column prop="serviceTypeName" :label="$t('i18nn_61fc9e07fff97930')"></el-table-column>
							
							<el-table-column prop="quantity" :label="$t('i18nn_f9db93b87e08763b')+'(和'+$t('i18nn_61fc9e07fff97930')+'相关)'"></el-table-column>
							<el-table-column prop="goodsSku" :label="'SKU'+'(非必填)'"></el-table-column>
							<!-- <el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
								<template slot-scope="scope">
									<el-popover placement="top" trigger="hover">
										<div class="pre-text">{{ scope.row.remark }}</div>
										<span class="over_ellipsis red" slot="reference">{{scope.row.remark}}</span>
									</el-popover>
								</template>
							</el-table-column> -->

							<el-table-column prop="createTime" :label="$t('i18nn_2594059036dfede5')" min-width="150">
								<template slot-scope="scope">
									<div>
										<div><span>{{$t('i18nn_e1911e9360047dcb')}}</span>：{{ scope.row.updateTime }}</div>
										<div><span>{{$t('i18nn_e8564657bbe9ca53')}}</span>：{{ scope.row.createTime }}</div>
									</div>
								</template>
							</el-table-column>
							
							<el-table-column :label="$t('93f5ca01b006206c')" width="150px" fixed="right">
								<template slot-scope="scope">
									<div>
										<el-button type="primary" size="mini" icon="el-icon-right"
											@click="toWorkOrder($event,scope.row)">{{$t('i18nn_287b771e159a7dc2')}}</el-button>
									</div>
								</template>
							</el-table-column>

							<!-- <el-table-column :label="$t('Storage.tableColumn.operation')" width="220px" fixed="right" align="left">
								<template slot-scope="scope">
									<div>
										<div style="margin-bottom: 10px;">
											<el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus"
												v-if="'0' == scope.row.status">{{ $t('FormMsg.Delete') }}</el-button>

											<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
												icon="el-icon-edit"
												v-if="'0' == scope.row.status||'10' == scope.row.status||'90'==scope.row.status">
												{{ $t('FormMsg.Edit') }}
											</el-button>
										</div>


									</div>

								</template>
							</el-table-column> -->

						</el-table>
						
						<div class="tableConPagination">
							<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData">
							</hy-page-pagination>
						</div>
					</div>



					<!-- <el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
							:height="$store.state.tableMaxHeight5" @row-click="handleCurrentChange"
							@selection-change="handleSelectionChange" style="width: 100%" size="small">

							<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
								:label="$t('7b1c2b1adc920d9c')"></el-table-column>
							
							<el-table-column prop="status" :label="$t('i18nn_6cdece641436d7ab')">
								<template slot-scope="scope">
									<div>
										<el-tag type="warning" v-if="'10' == scope.row.status">
											{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
										<el-tag type="success" v-else-if="'20' == scope.row.status">
											{{ $Utils.i18nKeyText(scope.row,'statusName') }}
										</el-tag>
										<el-tag type="primary" v-else-if="'30' == scope.row.status">
											{{ $Utils.i18nKeyText(scope.row,'statusName') }}
										</el-tag>
										<el-tag type="danger" v-else-if="'40' == scope.row.status">
											{{ $Utils.i18nKeyText(scope.row,'statusName') }}
										</el-tag>
										<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
									</div>
								</template>
							</el-table-column>


							<el-table-column prop="qaNo" :label="'QA编号'"></el-table-column>
							
							<el-table-column prop="client" :label="$t('i18nn_3af42243b3f54e9b')">
								<template slot-scope="scope">
									<div>
										<el-tag type="success" effect="plain" size="small" v-if="'SYS'==scope.row.client">{{$t('i18nn_05682dbef5eeb0a8')}}</el-tag>
										<el-tag type="warning" effect="plain" size="small" v-else-if="'BIZ'==scope.row.client">{{$t('i18nn_18d7892498938435')}}</el-tag>
										<el-tag type="info" effect="plain" size="small" v-else-if="scope.row.client">{{scope.row.client}}</el-tag>
									</div>
								</template>
							</el-table-column>
							
							<el-table-column prop="qaTypeName" :label="$t('i18nn_4dc6f04018e0f73b')"></el-table-column>
							

							<el-table-column prop="title" :label="$t('i18nn_7431e76029678ec7')"></el-table-column>

							<el-table-column prop="progressCount" :label="$t('i18nn_f4c5829c71fcd8bf')"></el-table-column>

							<el-table-column prop="applyName" :label="$t('i18nn_627df7b14df32cdb')"></el-table-column>
							<el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')"></el-table-column>

							<el-table-column prop="createTime" :label="$t('i18nn_2594059036dfede5')" min-width="150">
								<template slot-scope="scope">
									<div>
										<div>更新：{{ scope.row.updateTime }}</div>
										<div>创建：{{ scope.row.createTime }}</div>
									</div>

								</template>
							</el-table-column>
							
							<el-table-column :label="$t('i18nn_93f5ca01b006206c')" width="300px" fixed="right">
								<template slot-scope="scope">
									<div>

										<el-button @click="openRecProgress($event, scope.row)" type="success"
											size="mini" icon="el-icon-view">{{$t('i18nn_10a6a2a6124bbb9a')}}</el-button>

										<el-button type="danger" size="mini" icon="el-icon-minus"
											@click="delAction($event, scope.row)" v-if="'10' == scope.row.status">
											{{ $t('i18nn_e33c9b93c36fd250') }}
										</el-button>

									</div>
									
								</template>
							</el-table-column>
						</el-table> -->
				</div>
				<!-- <div class="tableConPagination">
						<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData">
						</hy-page-pagination>
					</div> -->
			</div>
		</div>
		<!-- <div slot="footer" class="dialog-footer">
		    <el-button type="primary" plain @click="dialogFile = false">{{ $t('FormMsg.Close') }}</el-button>
		  </div>
		</el-dialog> -->
		<!-- </el-drawer> -->
		<!-- <el-dialog :title="$t('i18nn_24c8f46012a25c89')" append-to-body :close-on-click-modal="false" :visible.sync="dialogAddVisible"
			width="1000px" top="0"> -->
		<!-- 新增-编辑 -->
		<ValueAddServiceEdit :openTime="openTimeAdd" :whNo="whNo" :relationId="relationId" :qaType="qaType" :id="editId"
			@success="initData()"></ValueAddServiceEdit>


		<!-- 选择SKU -->
		<!-- <dialogWSku :openTime="skuInvOpenTime" :whNo="whNo" @sure="sureSel"></dialogWSku> -->

		<!-- 附件 -->
		<!-- <dialogFileUpload ref="dialogFileUpload" :folder="'storage/addValService/'" :fileKey="'addValServiceFile'"
			:openTime="FileUploadOpenTime" @success="FileUploadSuccess"></dialogFileUpload> -->
	</div>
</template>
<script>
	import ValueAddServiceEdit from '@/components/StorageCenter/QA/ValueAddServiceEdit.vue';

	// import dialogFileUpload from '@/components/StorageCenter/components/dialogFileUpload.vue';
	export default {
		// name: 'HomeConfFrame',
		//meta信息seo用
		// metaInfo: {
		// 	title: 'HYTX-WMS-ADMIN'
		// },
		props: {
			openTime: {},
			// type: {
			// 	default: function() {
			// 		return '';
			// 	},
			// 	type: String
			// },
			relationId: {
				default: function() {
					return '';
				},
				type: String
			},
			// userId:{},
			qaType: {},
			// relationNo: {
			// 	default: function() {
			// 		return '';
			// 	},
			// 	type: String
			// },
			// userId: {
			// 	default: function() {
			// 		return '';
			// 	},
			// 	type: String
			// },
			whNo: {
				default: function() {
					return '';
				},
				type: String
			},
		},
		components: {
			ValueAddServiceEdit
			// dialogWSku,
			// dialogFileUpload
		},
		data() {
			return {
				// dialogFile: false,
				// loadingFile: false,
				// cusUserId: '',
				//打开选择框
				// dialogSelVisible: false,

				// dialogAddVisible: false,
				openTimeAdd: "",
				editId: "",

				// FileUploadOpenTime: '',

				// skuInvOpenTime: '',
				// skuOpenIndex:'',

				// selBillData: {},
				// dialogFormVisible: false,
				// dialogFormVisibleMsg: '',
				// multipleSelection: [],
				// {"relationId":"","addServiceList":[],"attachments":[],"bizType":""}
				// form: {
				// 	"remark": "",
				// 	attachments:[],
				// 	"addServiceList": [
				// 		// {
				// 		// 	"goodsSku":"",
				// 		// 	"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
				// 		// 	"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
				// 		// },
				// 	]
				// },

				//code: "SDS", codeText: this.$t('i18nn_684a2afb069b6016'), 
				//code: "SRE", codeText: this.$t('i18nn_199f5bded0b23976'), 
				//code: "STF", codeText: "自提/快递", 
				//code: "SCC", codeText: this.$t('i18nn_c39fd3d48389bea7'), 
				//code: "STR", codeText: "中转/转运"

				// "bizType":"",


				selectOption: {
					// wh_op_add_service: [],
					// wh_op_status: [],
					// wh_fin_bill_status: [],
					// wh_op_record_type: [],
					// // wh_op_is_bill:[],
					// statusList: [{
					// 		value: '',
					// 		label: this.$t('i18nn_16853bda54120bf1')
					// 	},
					// 	{
					// 		value: '10',
					// 		label: this.$t('i18nn_7e14d2cd3996dcd2')
					// 	},
					// 	{
					// 		value: '20',
					// 		label: this.$t('i18nn_2b65059530ec3ed5')
					// 	},
					// 	{
					// 		value: '30',
					// 		label: this.$t('i18nn_1fb4422ebabb847a')
					// 	},
					// 	{
					// 		value: '40',
					// 		label: this.$t('i18nn_0eb9b2e0d01ad12b')
					// 	}
					// ]
				},
				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//表格数据
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				//分页数据
				// pagination: {
				// 	current_page: 1,
				// 	page_sizes: [5, 10, 20, 30],
				// 	page_size: 20,
				// 	total: 0
				// },
				//详情数据
				loading_det: false,

				//查询，排序方式
				// filterData: {
				// 	orderBy: 'id_', //排序字段
				// 	sortAsc: 'desc', //desc降序，asc升序

				// 	// workNo: '', //this.$t('i18nn_daf169d1f7dceda0'),
				// 	qaNo: "",

				// 	whNo: '', //this.$t('i18nn_5e7740c52e22eefc'),
				// 	qaType: "",
				// 	status: "",
				// 	// wh_op_is_bill:'',
				// 	// daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this.$Utils.fomatterDate_YYYYMMdd(new Date())],
				// 	// daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this
				// 	// 	.$Utils.fomatterDate_YYYYMMdd(new Date())
				// 	// ],
				// 	daterange: [],
				// 	relationNo: "",
				// 	wh_qa_biz_type: "",
				// 	// wh_op_status: '',
				// 	// wh_fin_bill_status: '',
				// 	// plStatus:'',
				// 	// bizType: '10',
				// 	// opNo: ''
				// 	// plName: ''
				// }
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				// this.dialogFile = true;
				this.initData();
			}
		},
		activated() {

		},
		//创建时
		created() {
			console.log('created');
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			if(this.relationId){
				this.initData();
			}
		},
		methods: {
			initData() {
				// this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];

				// this.cusUserId = this.userId;
				// this.filterData.whNo = this.whNo;
				// this.filterData.wh_qa_biz_type = this.type;
				// this.filterData.relationNo = this.relationNo;
				// this.filterData.relationId = this.relationId;

				// this.$nextTick(() => {
				// 	this.$refs.cusSelFuzzy.init(this.cusUserId);
				// 	this.$refs.whNoSelect.init(this.whNo);
				// })
				// this.getPageData();



				// this.getDetData();
				this.tableData = [];
				this.pagination.current_page = 1;
				this.getPageData();

				// this.$nextTick(() => {
				// 	this.$refs.multipleTable.doLayout();
				// })
			},
			
			//查看工单
			toWorkOrder(event, row){
				event.stopPropagation();
				this.$router.push({
					name:"workOrderList",
					query:{
						workNo: row.workNo
					}
				})
			},

			//新增
			// addLe2() {
			// 	this.form.addServiceList.push({
			// 		"goodsSku":"",
			// 		"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
			// 		"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
			// 	});
			// },
			// // //删除
			// delLe2(event, index) {
			// 	event.stopPropagation();
			// 	this.form.addServiceList.splice(index, 1);
			// },

			// 新建-表格
			// addRow(event,index) {
			// 	event.stopPropagation();
			// 	if(!this.whNo){
			// 		this.$message.warning(this.$t('FormMsg.Select_long_wh'))
			// 		return;
			// 	}
			// 	// this.skuInvWhNo = this.form.senderAddr;
			// 	this.skuInvOpenTime = new Date().getTime();
			// 	this.skuOpenIndex = index;
			// },
			// selSkuInv(val){
			// 	val.forEach(item=>{
			// 		let hasSku = this.form.addServiceList.some(item2=> item.goodsSku == item2.goodsSku);
			// 		//是否已经存在此SKU
			// 		if(!hasSku){//不存在
			// 			this.form.addServiceList.push({
			// 				goodsSku: item.goodsSku,
			// 				"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
			// 				"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
			// 			});
			// 		}
			// 	})
			// },
			// sureSel(val){
			// 	val.forEach((item,index)=>{
			// 		// let hasSku = this.form.addServiceList.some(item2=> item.goodsSku == item2.goodsSku);
			// 		//是否已经存在此SKU
			// 		// if(!hasSku){//不存在
			// 			if(0==index){//第一条直接录入
			// 				// this.form.addServiceList[this.skuOpenIndex].goodsSku = item.goodsSku;
			// 				let oneAdd = this.form.addServiceList[this.skuOpenIndex];
			// 				oneAdd.goodsSku = item.goodsSku;
			// 				this.$set(this.form.addServiceList,this.skuOpenIndex,oneAdd)
			// 			} else {//其他插入新数据
			// 				this.form.addServiceList.push({
			// 					goodsSku: item.goodsSku,
			// 					"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
			// 					"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
			// 				});
			// 			}
			// 		// }
			// 	})
			// },


			//添加附件
			// openAddFile() {
			// 	// this.FileUploadOpenIndex = index;
			// 	this.FileUploadOpenTime = new Date().getTime();
			// },
			// FileUploadSuccess(data) {
			// 	console.log('FileUploadSuccess', data);
			// 	let fileList = data.map(item => {
			// 		return {
			// 			fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
			// 			url: item.filePath, //this.$t('i18nn_8758fd50c87d6c9c')
			// 			// type: "90"
			// 		}
			// 	});
			// 	// this.form.attachments = fileList;
			// 	this.form.attachments = this.form.attachments.concat(fileList);
			// 	this.$forceUpdate();
			// },
			//删除附件
			// delFile(event, index) {
			// 	event.stopPropagation();
			// 	this.form.attachments.splice(index, 1);
			// 	this.$forceUpdate();
			// },
			// changCus(data) {
			// 	console.log('changCus', data);
			// 	this.cusUserId = data.userId;
			// 	this.initData();
			// },
			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.filterData.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	this.initData();
			// },

			// handleSelectStatus(key, keyPath) {
			// 	// console.log(key, keyPath);
			// 	this.filterData.status = key;
			// 	this.initData();
			// },
			// changWhNo2(data) {
			// 	console.log('changWhNo', data);
			// 	this.form.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	// this.initData();
			// },
			// changCus2(data) {
			// 	console.log('changCus', data);
			// 	this.form.cusName = data.companyName;

			// 	this.form.cusNo = data.cusNo;
			// },

			// getDetData() {
			// 	this.loading_load = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhValAddServiceGetByRel + "/" + this.relationId, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			this.loading_load = false;
			// 			if (200 == data.code) {
			// 				console.log(data);
			// 				//表格显示数据
			// 				// this.tableData = data.rows;

			// 				//当前数据总条数
			// 				// this.pagination.total = parseInt(data.total);

			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error('列表数据，请求失败！');
			// 			this.loading_load = false;
			// 		});
			// },
			//打开新增
			openAddNew() {
				event.stopPropagation();
				this.openTimeAdd = new Date().getTime();
				this.editId = "";
				// this.dialogAddVisible = true;

				// this.form = {
				// 	"remark": "",
				// 	attachments:[],
				// 	"addServiceList": [{
				// 		"goodsSku":"",
				// 		"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
				// 		"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
				// 	}]
				// };

				// this.$router.push({
				// 	name: 'QARecAdd',
				// 	query: {
				// 		qaBizType: this.type,
				// 		relationNo: this.relationNo,
				// 		relationId: this.relationId,
				// 		cusNo: this.userId,
				// 		whNo: this.whNo,
				// 	}
				// });
			},
			//打开编辑
			openEdit() {
				// event.stopPropagation();
				this.openTimeAdd = new Date().getTime();
				this.editId = this.id;
			},
			//删除
			delAction(event, row) {
				event.stopPropagation();
				this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {

						type: 'warning'
					})
					.then(() => {
						this.delDataAction(event, row);
					})
					.catch(() => {

					});
			},
			//删除
			delDataAction(event, row) {
				event.stopPropagation();
				console.log('delDataAction', row);

				this.postData(this.$urlConfig.WhWorkOrderDel + '/' + row.id, {}, 'delete');
			},

			//分页的筛选项数据
			pageFilterData() {
				// let startTime = '';
				// let endTime = '';
				// if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
				// 	startTime = this.filterData.daterange[0];
				// 	endTime = this.filterData.daterange[1];
				// } else {
				// 	// this.$message.warning(this.$t('tips.Please_Sel_date'));
				// }
				return {
					relationId: this.relationId ? this.relationId : null,

				};
			},
			//请求分页数据
			getPageData() {
				let filterData = Object.assign({
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目
				}, this.pageFilterData())

				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhValAddServicePageList, filterData)
					.then(({
						data
					}) => {
						
						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.tableData = data.rows;

							//当前数据总条数
							this.pagination.total = parseInt(data.total);
							this.$emit("success",this.pagination.total);
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error('列表数据，请求失败！');
						this.loading_load = false;
					});
			},


			// //打开编辑
			// openRecProgress(event, row) {
			// 	event.stopPropagation();
			// 	// this.openDioalog(row, this.$t('i18nn_6267f3aedf895209'));
			// 	this.dialogFile = false;
			// 	this.$router.push({
			// 		name: 'QARecProgress',
			// 		query: {
			// 			id: row.id,
			// 			qaNo: row.qaNo
			// 		}
			// 	});
			// },
			// fomatterQaTypeToBizType(qaType){
			// 	// bizType
			// 	//code: "SDS", codeText: this.$t('i18nn_684a2afb069b6016'),
			// 	//code: "SRE", codeText: this.$t('i18nn_199f5bded0b23976'), 
			// 	//code: "STF", codeText: "自提/快递", 
			// 	//code: "SCC", codeText: this.$t('i18nn_c39fd3d48389bea7'), 
			// 	//code: "STR", codeText: "中转/转运"

			// 	// qaBizType
			// 	// code: "10", codeText: this.$t('i18nn_684a2afb069b6016'),
			// 	// code: "20", codeText: this.$t('i18nn_6373cb1d1204d580'),
			// 	// code: "30", codeText: this.$t('i18nn_57495c18e2baebdc'), 
			// 	// code: "40", codeText: this.$t('i18nn_21e42bbe9894aafc'), 
			// 	// code: "99", codeText: this.$t('i18nn_97e49d00bcc4c17c'),
			// 	let bizType = '';
			// 	if('10'==qaType){
			// 		bizType = 'SDS';
			// 	} else if('20'==qaType){
			// 		bizType = 'STF';
			// 	} else if('30'==qaType){//STR->TSCC
			// 		bizType = 'TSCC';
			// 	} else if('40'==qaType){
			// 		bizType = 'SCC';
			// 	} else if('50'==qaType){
			// 		bizType = 'SRE';
			// 	} else if('60'==qaType){
			// 		bizType = 'STR';
			// 	}
			// 	return bizType;
			// },
			//提交信息
			// submitForm() {
			// 	let bizType = this.fomatterQaTypeToBizType(this.qaType);
			// 	let formData = Object.assign({}, this.form);
			// 	formData.relationId = this.relationId;
			// 	formData.bizType = bizType;
			// 	this.postData(this.$urlConfig.WhValAddServiceBizAdd, formData, '', () => {
			// 		this.dialogAddVisible = false;
			// 		this.getPageData();
			// 		// this.$message.success(this.$t('9f30371831a98237'));
			// 	});
			// },

			//选择行
			// handleCurrentChange(row, event, column) {
			// 	// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			// },
			// //多选
			// handleSelectionChange(val) {
			// 	console.log(val);
			// 	// this.multipleSelection = val;
			// },
			//删除
			// delAction(event, row) {
			// 	this.$confirm('确定删除吗?', this.$t('i18nn_daaaeb1b7b22b126'), {
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			this.delDataAction(event, row);
			// 		})
			// 		.catch(() => {});
			// },
			// //删除
			// delDataAction(event, row) {
			// 	event.stopPropagation();
			// 	console.log('delAction', row);
			// 	let parm = [];
			// 	parm = {};
			// 	this.postData(this.$urlConfig.WhQARecDel + '/' + row.id, parm, 'delete', () => {
			// 		this.initData();
			// 		this.$message.success(this.$t('9f30371831a98237'));
			// 	});
			// },

			//提交数据
			// postData(url, formData, type, callback) {
			// 	// let _this = this;
			// 	this.loading = true;

			// 	let HttpType = {};
			// 	if ('delete' == type) {
			// 		HttpType = this.$http.delete(url, formData);
			// 	} else {
			// 		HttpType = this.$http.put(url, formData);
			// 	}
			// 	HttpType
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			if (200 == data.code) {
			// 				callback();
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('dbe331ab679cd67f');
			// 				}
			// 				this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('tips.submitError'));
			// 			this.loading = false;
			// 			this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },

			//查询数据字典
			// getDicData() {
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_op_add_service'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_op_add_service = data.data['wh_op_add_service'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.errorData');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error('查询数据字典接口失败,请重试！');
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>