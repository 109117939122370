<template>
	<!--  -->
	<el-dialog :title="$t('i18nn_c09a4ec2fe473b0a')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="800px"  v-loading="loadingUpload">
		<el-form ref="form" :inline="true" :rules="formRules" :model="form" label-width="150px" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">
			<!-- <el-card style="margin-top: 10px;"> -->
			<!-- <div slot="header" class="">
					<h3>
						<i class="el-icon-folder-opened"></i>
						<span>{{$t('i18nn_94d68bad9f60445f')}}</span>
					</h3>
				</div> -->
			<!-- 出车方式  预计到车日期 出库类型 出库时间 备注 -->
			<div>
				<!-- <el-form-item label="undefined" prop="">
					<el-select filterable clearable v-model="form.carTmsType" :placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 200px;">
						<el-option v-for="item in selectOption.wh_car_tms_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</el-form-item> -->

				<el-form-item :label="$t('i18nn_d12a2c11456e7f47')" prop="">
					<el-date-picker v-model="form.carPlanDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="12:00:00" :placeholder="$t('FormMsg.Select_date_time')"></el-date-picker>
				</el-form-item>

				<!-- <el-form-item label="undefined" prop="">
					<el-select filterable clearable v-model="form.sendOutType" :placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 200px;">
						<el-option v-for="item in selectOption.wh_send_out_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</el-form-item> -->
				
				<!-- <el-form-item :label="$t('i18nn_716dcf4d38910c35')" prop="">
					<el-date-picker v-model="form.outWhDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" :placeholder="$t('FormMsg.Select_date_time')"></el-date-picker>
				</el-form-item>
				
				<el-form-item :label="$t('i18nn_4429a015eeb36653')" prop="">
					<el-input-number v-model="form.palletCount" :placeholder="$t('i18nn_5a9aefbc03c778f7')"></el-input-number>
				</el-form-item>
				<el-form-item :label="$t('i18nn_f88b93f4fd02974c')" prop="">
					<el-input-number v-model="form.palletLabel" :placeholder="$t('i18nn_5a9aefbc03c778f7')"></el-input-number>
				</el-form-item>
				
				<el-form-item :label="$t('i18nn_da7eef74bb8e0f6d')" prop="">
					<el-date-picker v-model="form.waitOutWhDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" :placeholder="$t('FormMsg.Select_date_time')"></el-date-picker>
				</el-form-item>
				<div>
					<el-form-item :label="$t('i18nn_15b3627faddccb1d')" prop=""><el-input style="width: 100%;" type="textarea" :placeholder="$t('i18nn_5b27f3993d58438d')" v-model="form.remark"></el-input></el-form-item>
				</div> -->
			</div>
			<!-- </el-card> -->
		</el-form>

		<div slot="footer" class="dialog-footer">
			<el-button type="primary" plain @click="dialogFile = false">{{ $t('FormMsg.Close') }}</el-button>
			<el-button type="primary" icon="el-icon-tickets" @click="submitFormAction()">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button>
			<!-- <el-button type="primary" v-else @click="submitExcelFormAction()">{{ $t('FormMsg.Save') }}</el-button> -->
		</div>
	</el-dialog>
</template>
<script>
	// import {
	// 	getDicData
	// } from '@/axios/common.js';
	
// import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';
export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	props: {
		openTime: {
			// default: function() {
			//   return '';
			// },
			// type: String
		},
		row: {}
		// type: {//1提交附件并，提交打托完成，其他仅提交附件
		// 	// default: function() {
		// 	// 	return '';
		// 	// },
		// 	// type: String
		// }
	},
	components: {
		// HyElWhUpLoad
	},
	data() {
		return {
			dialogFile: false,
			loadingUpload: false,

			// relationId: '',
			// userId: '',
			//附件
			// fileWatchKey: '1',
			// fileList: [],
			// fileId: this.$store.getters.getUserInfo.id,

			// saveErr: false,

			// beforeFileList:[],
			// listUrl: [],

			// tableData: [],

			loading: false,
			// 出车方式  预计到车日期 出库类型 出库时间 备注
			form: {
				id: '',
				// carTmsType: '',
				carPlanDate: '',
				// sendOutType: '', //this.$t('i18nn_7edc8f4b9d8f2435'),
				// outWhDate: '',
				// remark: '',
				// palletCount: '', //this.$t('i18nn_18c1c1a3ea76e9d3'),
				// palletLabel:'',
				// waitOutWhDate:'',
				// attachments: []
			},

			formRules: {
				sendOutType: [{ required: true, message: this.$t('FormMsg.Please_select'), trigger: 'change' }]
				// palletCount: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }]
				// feePrice: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }]
			},
			selectOption: {
				wh_send_out_type: [],
				wh_car_tms_type: []
			}
		};
	},
	watch: {
		openTime: function(newVal, oldVal) {
			console.log('openTime');
			this.dialogFile = true;
			this.initData();
		}
	},
	//创建时
	created() {
		// this.getPageData();
	},
	//编译挂载前
	mounted() {
		// this.initData();
		// this.getDicData();
		//数据字典
		// getDicData(['wh_send_out_type', 'wh_car_tms_type'],
		// 	(data)=>{
		// 		this.selectOption.wh_send_out_type = data['wh_send_out_type'];
		// 		this.selectOption.wh_car_tms_type = data['wh_car_tms_type'];
		// });
	},
	methods: {
		initData() {
			// this.relationId = this.row.id;
			// this.userId = this.row.userId;
			//初始化上传控件
			// this.listUrl = [];
			// this.tableData = [];

			this.form = {
				id: this.row.id,
				// carTmsType: this.row.carTmsType,
				carPlanDate: this.row.carPlanDate,
				// sendOutType: this.row.sendOutType, //this.$t('i18nn_7edc8f4b9d8f2435'),
				// outWhDate: this.row.outWhDate,
				// waitOutWhDate: this.row.waitOutWhDate,
				// palletCount: this.row.palletCount,
				// palletLabel: this.row.palletLabel,
				// remark: this.row.remark
				
			};

			// this.$nextTick(() => {
			// 	try {
			// 		this.$refs.HyElWhUpLoad.initData();
			// 		this.$refs.HyElWhUpLoad.clearFileList();
			// 	} catch (err) {
			// 		console.log(err);
			// 	}
			// });
			// if (this.relationId) {
			// 	this.getListData();
			// }
		},
		//上传回调
		// UploadSuccessUrl(val) {
		// 	console.log('UploadSuccessUrl', val);
		// 	// console.log(this.listUrl);return;
		// 	// if(val.length<=0){//当没有附件，仅提交-打托完成
		// 	// 	this.PalletComplate([]);
		// 	// 	return;
		// 	// }

		// 	let listUrl = val.map(item => {
		// 		// "filePath":"","fileName"：“”
		// 		return {
		// 			filePath: item.url,
		// 			fileName: item.name
		// 		};
		// 	});
		// 	console.log('listUrl', listUrl);
		// 	// this.$alert('上传成功！共上传 ' + listUrl.length + ' 个附件', this.$t('tips.tipsTitle'), {
		// 	//   type: 'success'
		// 	//   //confirmButtonText: this.$t('204ffab8a6e01870'),
		// 	// });
		// 	this.listUrl = listUrl;

		// 	// this.myForm.dataList = listUrl;
		// 	// this.myForm.dataList = val;

		// 	// this.submitExcelFormAction();
		// 	this.submitExcelFormData();
		// 	// this.PalletComplate(listUrl);
		// },
		//预备上传的文件
		// fileChange(beforeFileList){
		// 	console.log('fileChange',beforeFileList);
		// 	this.beforeFileList = beforeFileList;
		// },
		submitFormAction() {
			// if (!this.form.sendOutType) {
			// 	this.$message.warning(this.$t('i18nn_f1c38661e72ae864'));
			// 	return;
			// }
			// if (10==this.form.sendOutType && !this.form.palletCount) {
			// 	this.$message.warning(this.$t('i18nn_e9942ae46d0c7434'));
			// 	return;
			// }
			// if (this.saveErr || this.beforeFileList.length<=0) {
			// 	this.submitExcelFormData();
			// } else {
			// 	this.$nextTick(() => {
			// 		try {
			// 			this.$refs.HyElWhUpLoad.submitUpload();
			// 		} catch (err) {
			// 			console.log(err);
			// 		}
			// 	});
			// }
			this.submitExcelFormHTTP();
		},
		// submitExcelFormData() {
		// 	let formData = [];
		// 	this.listUrl.forEach(item => {
		// 		formData.push({
		// 			// id: this.relationId,
		// 			type: '70',
		// 			fileName: item.fileName,
		// 			url: item.filePath
		// 		});
		// 	});
		// 	this.submitExcelFormHTTP(formData);
		// 	// this.PalletComplate(formData);
		// },
		//打托完成
		// PalletComplate(attachments) {
		// 	// this.postData(this.$urlConfig.WhInPalletComplete + '/' + this.row.id, {}, () => {
		// 	// 	// this.initData();
		// 	// 	this.$message.success(this.$t('9f30371831a98237'));
		// 	// 	this.dialogFile = false;
		// 	// 	this.$emit('success');
		// 	// });
		// 	// //
		// 	let formData = Object.assign({}, this.form);
		// 	formData.attachments = attachments;
		// 	this.postData(this.$urlConfig.WhTransferOrderPalletComplete, formData, () => {
		// 		this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
		// 		// this.dialogFormVisible = false;
		// 		this.dialogFile = false;
		// 		this.$emit('success');
		// 	});
		// },
		//提交-
		submitExcelFormHTTP() {
			let formData = Object.assign({}, this.form);
			// formData.attachments = attachments;

			this.loadingUpload = true;
			this.$http
				.put(this.$urlConfig.WhInShipmentEditModify, formData)
				.then(({ data }) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					this.loadingUpload = false;
					if (200 == data.code) {
						// if(1==this.type){//当有附件，并提交-打托完成
						// this.PalletComplate();
						// this.getListData();
						// } else {//当提交附件
						// this.getListData();
						this.dialogFile = false;
						this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
						this.$emit('success');
						// }
					} else {
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('204ffab8a6e01870'),
						});
						// this.saveErr = true;
						// this.initData();
					}
				})
				.catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					// this.saveErr = true;
					this.loadingUpload = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('204ffab8a6e01870'),
					});
				});
		},
		//删除
		// delAction(event, row) {
		// 	event.stopPropagation();
		// 	this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
		// 		// confirmButtonText: this.$t('204ffab8a6e01870'),
		// 		// cancelButtonText: this.$t('4b3340f7872b411f'),
		// 		type: 'warning'
		// 	})
		// 		.then(() => {
		// 			this.delData(this.$urlConfig.WhFileUploadDel + '/' + row.id+ '/' + row.userId, row);
		// 		})
		// 		.catch(() => {

		// 		});
		// },
		//提交信息
		// delData(url, formData) {
		// 	this.loadingUpload = true;
		// 	this.$http
		// 		.delete(url, {})
		// 		.then(({ data }) => {
		// 			console.log(this.$t('i18nn_bc868e024b80d2e3'));
		// 			console.log(data);
		// 			this.loadingUpload = false;
		// 			if (200 == data.code) {
		// 				this.getListData();
		// 			} else {
		// 				this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
		// 					type: 'warning'
		// 					//confirmButtonText: this.$t('204ffab8a6e01870'),
		// 				});
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log(this.$t('tips.submitError'));
		// 			this.loadingUpload = false;
		// 			this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
		// 				type: 'warning'
		// 				//confirmButtonText: this.$t('204ffab8a6e01870'),
		// 			});
		// 		});
		// },
		//查询 /wh/bizWhFile/query/{relationId}
		// getListData() {
		// 	this.loadingUpload = true;
		// 	this.$http
		// 		.get(this.$urlConfig.WhFileUploadList + '/' + this.relationId+'/70', {})
		// 		.then(({ data }) => {
		// 			console.log(this.$t('i18nn_4195755cd53e871a'));
		// 			console.log(data);
		// 			this.loadingUpload = false;
		// 			if (200 == data.code) {
		// 				// if (data.rows) {
		// 				this.tableData = data.rows;
		// 				// } else {
		// 				//   this.$alert('查询失败,请重试', this.$t('tips.tipsTitle'), {
		// 				//     type: 'warning'
		// 				//     //confirmButtonText: this.$t('204ffab8a6e01870'),
		// 				//   });
		// 				// }
		// 			} else {
		// 				this.$alert('查询失败,请重试', this.$t('tips.tipsTitle'), {
		// 					type: 'warning'
		// 					//confirmButtonText: this.$t('204ffab8a6e01870'),
		// 				});
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			// console.log(this.$t('tips.submitError'));
		// 			this.loadingUpload = false;
		// 			this.$alert('查询失败,请重试！', this.$t('tips.tipsTitle'), {
		// 				type: 'warning'
		// 				//confirmButtonText: this.$t('204ffab8a6e01870'),
		// 			});
		// 		});
		// },
		//提交信息
		// submitForm(formName) {
		// 	this.$refs[formName].validate(valid => {
		// 		if (valid) {
		// 			let formData = Object.assign({}, this.form);
		// 					//编辑
		// 					this.postData(this.$urlConfig.WhTransferOrderPalletComplete, formData, () => {
		// 						this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
		// 						// this.dialogFormVisible = false;
		// 						this.getPageData();
		// 					});

		// 		} else {
		// 			console.log('error submit!!');
		// 			this.$alert(this.$t('i18nn_b9221c0e13214d9c'), this.$t('i18nn_daaaeb1b7b22b126'), {
		// 				type: 'warning'
		// 				//confirmButtonText: this.$t('204ffab8a6e01870'),
		// 			});
		// 			return false;
		// 		}
		// 	});
		// },
		//提交数据
		postData(url, formData, callback) {
			// let _this = this;
			this.loading = true;

			this.$http
				.put(url, formData)
				.then(({ data }) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);
					
					this.loading = false;
					if (200 == data.code) {
						callback();
					} else {
						if (!data.msg) {
							data.msg = this.$t('dbe331ab679cd67f');
						}
						this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
							type: 'warning'
						});
					}
				})
				.catch(error => {
					console.log(error);
					console.log(this.$t('i18nn_a7d2e953195a5588'));
					this.loading = false;
					this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
						type: 'warning'
					});
				});
		},
		//查询数据字典
		// getDicData() {
		// 	// let _this = this;
		// 	// console.log(keyword);

		// 	// this.loading_load = true;
		// 	this.$http
		// 		.put(this.$urlConfig.HyDicQueryList, ['wh_send_out_type', 'wh_car_tms_type'])
		// 		.then(({ data }) => {
		// 			console.log('查询数据字典，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.data) {
		// 				this.selectOption.wh_send_out_type = data.data['wh_send_out_type'];
		// 				this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = '查询数据字典失败,请重试';
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('查询数据字典接口，请求失败');
		// 			this.$message.error('查询数据字典接口失败,请重试！');
		// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
