<template>
	<div class="mainTem">
		<el-tabs v-model="detTabActiveName" type="border-card">
			<el-tab-pane label="" name="first" v-loading="loading">
				<span slot="label"><i class="el-icon-document"></i> <span>{{$t('i18nn_e6a250894a04db4c')}}</span></span>
				<!-- <div class="buyerHeader">
      <h3 class="buyerHeaderTit">{{$t('i18nn_51177b2d7ce0d883')}}</h3>
    </div> -->
				<!-- <div class="tableConTop">
					<el-row>
						<el-col :span="5" class="tableConTopLeft">
							<h3>
								<span class="tct_tit">{{ $t('i18nn_51177b2d7ce0d883') }}</span>
							</h3>
						</el-col>
						<el-col :span="19" class="tableConTopRig">
							<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
							</el-button>
						</el-col>
					</el-row>
				</div> -->

				<div class="filterCon">
					<ul class="filterConList">
						<!-- <li>
							<span>{{$t('i18nn_a2df50399ce1bf10')}}</span>:
							<strong v-if="tableData.length>0">{{ tableData[0].sendNo }}</strong>
						</li> -->
						<!-- <li>
							<span>ID</span>:
							<span>{{ filterData.sendId }}</span>
						</li> -->
						<!-- <li>
							<span>{{$t('6cdece641436d7ab')}}</span>
							<el-select filterable clearable v-model="filterData.status"
								:placeholder="$t('2ad108ab2c560530')" size="small" style="width: 100px;" @change="initData()">
								<el-option v-for="item in selectOption.statusList" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</li> -->
						<li>
							<span>SKU</span>
							<el-input type="text" v-model="filterData.goodsSku" size="small" clearable
								@keyup.enter.native="initData()" maxlength="50" :placeholder="$t('hytxs0000001')"
								style="width: 250px;" />
						</li>

						<li>
							<span>{{$t('i18nn_f353b1ebbdc25be4')}}</span>
							<el-input type="text" v-model="filterData.keyword" size="small" clearable @keyup.enter.native="initData()"
								maxlength="50" style="width: 180px;" />
						</li>
						<li><el-button icon="el-icon-search" size="small" type="primary"
								@click="initData()">{{$t('1e7246dd6ccc5539')}}</el-button></li>
					</ul>
				</div>


				<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">

					<el-table border :data="tableData" size="small" style=" width: 100%;">

						<el-table-column type="expand" fixed="left">
							<template slot-scope="props">
								<div style="padding:0 200px 0 100px;">

									<el-descriptions :column="3" v-if="props.row.mySkuDto">

										<el-descriptions-item :label="'SKU'">
											{{props.row.mySkuDto.goodsSku}}
										</el-descriptions-item>

										<!-- <el-descriptions-item :label="$t('6cdece641436d7ab')">
											<el-tag type=""
												v-if="'0' == props.row.mySkuDto.status">{{ $Utils.i18nKeyText(props.row.mySkuDto,'statusName') }}</el-tag>
											<el-tag type="success"
												v-else-if="'10' == props.row.mySkuDto.status">{{ $Utils.i18nKeyText(props.row.mySkuDto,'statusName') }}</el-tag>
											<el-tag type="warning"
												v-else-if="'20' == props.row.mySkuDto.status">{{ $Utils.i18nKeyText(props.row.mySkuDto,'statusName') }}</el-tag>
											<el-tag type="danger"
												v-else-if="'30'==props.row.mySkuDto.status">{{ $Utils.i18nKeyText(props.row.mySkuDto,'statusName') }}</el-tag>
											<el-tag type="info" v-else>{{ $Utils.i18nKeyText(props.row.mySkuDto,'statusName') }}</el-tag>
										</el-descriptions-item> -->
										<!-- <el-descriptions-item :label="$t('hytxs0000060')">
											{{props.row.mySkuDto.cusName}}
										</el-descriptions-item> -->

										<el-descriptions-item :label="$t('Storage.skuInfo.SKU_title')">
											<div>
												<div>{{props.row.mySkuDto.goodsName}}</div>
												<div v-if="props.row.mySkuDto.goodsName!=props.row.mySkuDto.goodsNameEn">
													{{props.row.mySkuDto.goodsNameEn}}
												</div>
											</div>
										</el-descriptions-item>
										<el-descriptions-item :label="$t('Storage.skuInfo.unit')">
											{{props.row.mySkuDto.sizeUnitName}}
										</el-descriptions-item>
										<el-descriptions-item
											:label="$t('i18nn_a85af4ba66084d28')+'('+$t('Storage.skuInfo.length_width_height')+')'">
											<div>
												<span>{{props.row.mySkuDto.goodsLength}}</span>*
												<span>{{props.row.mySkuDto.goodsWidth}}</span>*
												<span>{{props.row.mySkuDto.goodsHeight}}</span>
											</div>
										</el-descriptions-item>
										<el-descriptions-item :label="$t('Storage.skuInfo.weight')">
											{{props.row.mySkuDto.goodsWeight}}
										</el-descriptions-item>
										
										<el-descriptions-item :label="$t('i18nn_96f1a4b17e75892d')+'/'+$t('i18nn_d5d972e6063a3180')+'(in/lb)'">
											<div>
												<div>
													<span>{{$t('i18nn_0e60303b30d5d4b4')}}</span>:<span>{{props.row.mySkuDto.goodsINCLength}}</span>
												</div>
												<div>
													<span>{{$t('i18nn_6cd31871f8528dd5')}}</span>:<span>{{props.row.mySkuDto.goodsINCWidth}}</span>
												</div>
												<div>
													<span>{{$t('i18nn_93c9ea4a0e52c641')}}</span>:<span>{{props.row.mySkuDto.goodsINCHeight}}</span>
												</div>
												<div>
													<span>{{$t('i18nn_96f1a4b17e75892d')}}</span>:<span>{{props.row.mySkuDto.goodsLBWeight}}</span>
												</div>
											</div>
										</el-descriptions-item>
										<!-- <el-descriptions-item :label="$t('i18nn_6d8f9b0c398319ff')">
											<div v-if="props.row.mySkuDto.isBattery">
												{{props.row.mySkuDto.isBatteryName}}
											</div>
										</el-descriptions-item> -->

										<!-- <el-descriptions-item :label="$t('Storage.skuInfo.price')">
											{{props.row.mySkuDto.declarePrice}}
										</el-descriptions-item> -->
										<el-descriptions-item :label="$t('15b3627faddccb1d')">
											{{props.row.mySkuDto.remark}}
										</el-descriptions-item>
										<!-- <el-descriptions-item :label="$t('Storage.skuInfo.safety_stock')">
											{{props.row.mySkuDto.safeStock}}
										</el-descriptions-item> -->
										<!-- <el-descriptions-item :label="'SKU CODE'" :span="2">
											<barcode :code="props.row.mySkuDto.goodsSku"></barcode>
										</el-descriptions-item> -->
										<el-descriptions-item :label="$t('Storage.skuInfo.SKU_pictures')" :span="2">
											<el-image lazy style="width: auto; height: 50px" fit="contain" :src="props.row.mySkuDto.goodsImg"
												:preview-src-list="[props.row.mySkuDto].map(itemPre=> { return itemPre.goodsImg})">
												<div slot="error" class="image-slot" style="font-size: 50px;"><i
														class="el-icon-picture-outline"></i>
												</div>
											</el-image>
										</el-descriptions-item>

									</el-descriptions>
								</div>
							</template>
						</el-table-column>
						<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
							:label="$t('Storage.tableColumn.no')"></el-table-column>

						<el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
							<template slot-scope="scope">
								<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<el-tag type="success"
									v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							</template>
						</el-table-column>

						<!-- <el-table-column prop="sendNo" label="shipmentNo"></el-table-column> -->
						<el-table-column prop="goodsSku" label="SKU"></el-table-column>
						<el-table-column prop="sendNo" :label="$t('i18nn_a2df50399ce1bf10')"></el-table-column>
						<el-table-column prop="sendAcount" :label="$t('i18nn_2ec0f364e8bbaa4c')"></el-table-column>
						<el-table-column prop="sendCTN" :label="$t('i18nn_bdc361ba04506136')"></el-table-column>
						<el-table-column prop="ctnPCS" :label="$t('i18nn_4b2b4820019ef897')"></el-table-column>
						<el-table-column prop="orderLabel" :label="$t('i18nn_ac3f40a45db7c05a')"></el-table-column>
						<!-- <el-table-column prop="carTmsTypeName" :label="$t('i18nn_b19f7906dab47f9c')"></el-table-column> -->

						<el-table-column prop="plGoodsTypeName" :label="$t('i18nn_b781fc794861218f')">
						</el-table-column>

						<el-table-column prop="palletCount" :label="$t('i18nn_c8773d8d74202801')">
						</el-table-column>

						<!-- <el-table-column prop="ctnLength">
							<template slot="header">
								<div>
									<span>{{$t('i18nn_eb8af1b7e16e2069')}}</span>(cm)
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="ctnWidth">
							<template slot="header">
								<div>
									<span>{{$t('i18nn_64e6eb209a8a2cff')}}</span>(cm)
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="ctnHeight">
							<template slot="header">
								<div>
									<span>{{$t('i18nn_2a02dbcab12c9ee8')}}</span>(cm)
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="ctnWeight">
							<template slot="header">
								<div>
									<span>{{$t('i18nn_55ed30102ca735c1')}}</span>(kg)
								</div>
							</template>
						</el-table-column> -->
						
						<el-table-column prop="toWhNo">
							<template slot="header">
								<div>
									FBA<span>{{$t('i18nn_b5b5d2437dca1e76')}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="fbaNo" label="FBA NO."></el-table-column>
						<el-table-column prop="shipmentCode" :label="$t('i18nn_fa6ae0aebfee7efa')"></el-table-column>
						<el-table-column prop="markNo" :label="$t('i18nn_32df9cb3d1681477')"></el-table-column>
						<!-- <el-table-column prop="country" :label="$t('i18nn_b166e4e8fe9513fa')"></el-table-column>
				<el-table-column prop="state" label="州/省"></el-table-column>
				<el-table-column prop="city" :label="$t('i18nn_e05b5d049b64b040')"></el-table-column>
				<el-table-column prop="postalCode" :label="$t('i18nn_c4913ab43009b365')"></el-table-column>
				<el-table-column prop="addr" :label="$t('i18nn_8758fd50c87d6c9c')"></el-table-column>
				<el-table-column prop="phone" :label="$t('i18nn_2d13b6f878f30be3')"></el-table-column>
				<el-table-column prop="name" :label="$t('i18nn_e44e9a291d80c593')"></el-table-column> -->

						<el-table-column prop="plTypeName" :label="$t('i18nn_2f9954db5ba06447')"></el-table-column>
						<el-table-column prop="plDate" :label="$t('i18nn_7b1927e9f5c2b1a1')"></el-table-column>

						<!-- <el-table-column prop="remark" :label="$t('i18nn_5fb84776e29f5b72')" show-overflow-tooltip></el-table-column> -->
					</el-table>


					<!-- <el-table
        ref="multipleTable"
        :data="tableData"
        stripe
        :border="true"
        style="width: 100%"
        size="small"
      >
        <el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>

        <el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
          <template slot-scope="scope">
            <el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
            <el-tag type="success" v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
            <el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="goodsSku" label="SKU">

        </el-table-column>

        <el-table-column prop="toWhNo" :label="$t('Storage.invoice_list.arrive_storehouse')"></el-table-column>
        <el-table-column prop="fbaNo" :label="$t('Storage.invoice_list.FBA_number')"></el-table-column>
				
				<el-table-column prop="sendCTN" :label="$t('i18nn_bdc361ba04506136')"></el-table-column>
				<el-table-column prop="ctnPCS" :label="$t('i18nn_85a10d3e5ea0e103')"></el-table-column>
        <el-table-column prop="sendAcount" :label="$t('i18nn_2ec0f364e8bbaa4c')">

        </el-table-column>


        <el-table-column prop="country" :label="$t('i18nn_89413403ccaade4a')"><template slot-scope="scope">
            <div v-if="scope.row.whFc">{{ scope.row.whFc.country }}</div>
          </template></el-table-column>

        <el-table-column prop="state" label="到达省/州"><template slot-scope="scope">
            <div v-if="scope.row.whFc">{{ scope.row.whFc.state }}</div>
          </template></el-table-column>
        <el-table-column prop="city" :label="$t('i18nn_ba8ce6a27a2ce7ef')"><template slot-scope="scope">
            <div v-if="scope.row.whFc">{{ scope.row.whFc.city }}</div>
          </template></el-table-column>
        <el-table-column prop="address" :label="$t('i18nn_aefe1a569c9a9d4c')"><template slot-scope="scope">
            <div v-if="scope.row.whFc">{{ scope.row.whFc.address }}</div>
          </template></el-table-column>
        <el-table-column prop="zipCode" :label="$t('i18nn_daa23bc02afc02a7')"><template slot-scope="scope">
            <div v-if="scope.row.whFc">{{ scope.row.whFc.zipCode }}</div>
          </template></el-table-column>


        <el-table-column prop="goodsName" :label="$t('Storage.skuInfo.Chinese_title')">
          <template slot-scope="scope">
            <div v-if="scope.row.mySkuDto">{{ scope.row.mySkuDto.goodsName }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="goodsNameEn" :label="$t('Storage.skuInfo.English_title')">
          <template slot-scope="scope">
            <div v-if="scope.row.mySkuDto">{{ scope.row.mySkuDto.goodsNameEn }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="goodsImg" :label="$t('Storage.skuInfo.SKU_pictures')">
          <template slot-scope="scope">
            <div v-if="scope.row.mySkuDto">
              <el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.mySkuDto.goodsImg" @click="openBigImg($event, scope.row.mySkuDto.goodsImg)">
                <div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
              </el-image>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="declarePrice" :label="$t('Storage.skuInfo.price')">
          <template slot-scope="scope">
            <div v-if="scope.row.mySkuDto">{{ scope.row.mySkuDto.declarePrice }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="goodsWeight" :label="$t('Storage.skuInfo.weight')">
          <template slot-scope="scope">
            <div v-if="scope.row.mySkuDto">{{ scope.row.mySkuDto.goodsWeight }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="sizeUnitName" :label="$t('Storage.skuInfo.unit')">
          <template slot-scope="scope">
            <div v-if="scope.row.mySkuDto">{{ scope.row.mySkuDto.sizeUnitName }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="goodsLength" :label="$t('Storage.skuInfo.length')">
          <template slot-scope="scope">
            <div v-if="scope.row.mySkuDto">{{ scope.row.mySkuDto.goodsLength }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="goodsWidth" :label="$t('Storage.skuInfo.width')">
          <template slot-scope="scope">
            <div v-if="scope.row.mySkuDto">{{ scope.row.mySkuDto.goodsWidth }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="goodsHeight" :label="$t('Storage.skuInfo.height')">
          <template slot-scope="scope">
            <div v-if="scope.row.mySkuDto">{{ scope.row.mySkuDto.goodsHeight }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column>

      </el-table> -->


					<div class="tableCon_pagination">
						<hy-page-pagination :pagination='pagination' v-on:updatePageData="getPageData"></hy-page-pagination>
					</div>
				</div>

				<!-- 打托完成和出库完成图片/附件 -->
				<div style="display: flex;">
					<el-card style="width: 600px;" :body-style="{'padding':'0'}">
						<div slot="header" class="">
							<h3>
								<i class="el-icon-folder"></i>
								<span><span>{{$t('i18nn_6c1a30d60ca12a5f')}}</span>/<span>{{$t('i18nn_f5d43732e3f6cf4d')}}</span></span>
							</h3>
						</div>

						<div>
							<el-table ref="multipleTable" :data="palletTableData" stripe :border="true" :max-height="400"
								style="width: 100%" size="small">
								<el-table-column type="index" fixed="left" width="50" align="center"
									:label="$t('Storage.tableColumn.no')"></el-table-column>
								<el-table-column prop="fileName" :label="$t('i18nn_ea4756bc1642e0f1')"></el-table-column>
								<el-table-column :label="$t('i18nn_2674282277c3714e')">
									<template slot-scope="scope">
										<el-image style="width: 100px; height: 100px" :z-index="9999" :fit="'contain'" :src="scope.row.url"
											:preview-src-list="palletTableData.map(itemPre=> { return itemPre.url})">
											<div slot="error" class="image-slot">
												<i class="el-icon-document"></i>
											</div>
										</el-image>
									</template>
								</el-table-column>
								<el-table-column prop="typeName" :label="$t('i18nn_184333c81babf2f1')"></el-table-column>
								<el-table-column prop="url" :label="$t('i18nn_d54012286fece209')" show-overflow-tooltip>
									<template slot-scope="scope">
										<a :href="scope.row.url" target="_blank">{{ scope.row.url }}</a>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</el-card>

					<el-card style="width: 600px;" :body-style="{'padding':'0'}">
						<div slot="header" class="">
							<h3>
								<i class="el-icon-folder"></i>
								<span>{{$t('i18nn_8139c1fa7c3b121f')}}</span>/<span>{{$t('i18nn_f5d43732e3f6cf4d')}}</span>
							</h3>
						</div>
						<div>
							<el-table ref="multipleTable" :data="OutWarehouseTableData" stripe :border="true" :max-height="400"
								style="width: 100%" size="small">
								<el-table-column type="index" fixed="left" width="50" align="center"
									:label="$t('Storage.tableColumn.no')"></el-table-column>
								<el-table-column prop="fileName" :label="$t('i18nn_ea4756bc1642e0f1')"></el-table-column>
								<el-table-column :label="$t('i18nn_2674282277c3714e')">
									<template slot-scope="scope">
										<el-image style="width: 100px; height: 100px" :z-index="9999" :fit="'contain'" :src="scope.row.url"
											:preview-src-list="OutWarehouseTableData.map(itemPre=> { return itemPre.url})">
											<div slot="error" class="image-slot">
												<i class="el-icon-document"></i>
											</div>
										</el-image>
									</template>
								</el-table-column>
								<el-table-column prop="typeName" :label="$t('i18nn_184333c81babf2f1')"></el-table-column>
								<el-table-column prop="url" :label="$t('i18nn_d54012286fece209')" show-overflow-tooltip>
									<template slot-scope="scope">
										<a :href="scope.row.url" target="_blank">{{ scope.row.url }}</a>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</el-card>
				</div>
			</el-tab-pane>
			<el-tab-pane label="" name="second">
				<span slot="label"><i class="el-icon-coin"></i> <span>{{$t('i18nn_d7315fb8114eb446')}}</span></span>

				<FeeModelById :id="sendId"></FeeModelById>
			</el-tab-pane>
		</el-tabs>
		<!--查看图片-->
		<el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogImgVisible" width="1000px" top="0">
			<div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" />
			</div>
			<div slot="footer" class="dialog-footer"><el-button type="primary" plain
					@click="dialogImgVisible = false">{{$t('FormMsg.Close')}}</el-button></div>
		</el-dialog>

	</div>
</template>
<script>
	import FeeModelById from '@/components/StorageCenter/Other/FeeModelById.vue';
	export default {

		// name: 'BuyerOrder',
		//meta信息seo用
		// metaInfo: {
		//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
		//   meta: [{ // set meta
		//     name: '互易天下-买家中心-收货账单',
		//     content: '互易天下-厂家共享平台-买家中心-收货账单'
		//   }],
		//   // link: [{                 // set link
		//   //   rel: 'asstes',
		//   //   href: 'https://assets-cdn.github.com/'
		//   // }]
		// },
		// props: ['isDialog'],
		props: {
			// mobile:"",
			// isSel:{
			//   default: function() {
			//     return false
			//   },
			//   type: Boolean
			// },
			sendId: {
				default: function() {
					return ''
				},
				type: String
			},
		},
		components: {
			FeeModelById
		},
		data() {
			return {
				detTabActiveName: 'first',

				// UserInfo: this.$store.getters.getUserInfo,
				dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改

				//图片放大
				dialogImgVisible: false,
				imgUrlBigShow: '',

				//拣货完成图片
				loadingUpload: false,
				palletTableData: [],

				//出库完成图片
				OutWarehouseTableData: [],

				loading: false,
				// form: {
				//     "goodsSku": "",//this.$t('i18nn_31f887c1ada79a99'),
				//     "cusCode":"",//this.$t('i18nn_64fed2851f79d875'),
				//     "goodsName":"",//this.$t('i18nn_b23004db5349dfd2'),
				//     "goodsNameEn":"",//this.$t('i18nn_05141ac0139eaf01'),
				//     "goodsImg":"",//this.$t('i18nn_877c5a0e44a0eb07'),
				//     "defCode":"",//this.$t('i18nn_791e36963e37617a'),
				//     "hashCode":"",//this.$t('i18nn_198738759379a3c0'),
				//     "declareNameCh":"",//this.$t('i18nn_73d237ea940222ce'),
				//     "declareNameEn":"",//this.$t('i18nn_9894786aad05f849'),
				//     "declarePrice":"",//this.$t('i18nn_fe28507421c4c3b3'),
				//     "goodsWeight":"",//this.$t('i18nn_11e41b2ec3d872ed'),
				//     "weightUnit":"",//this.$t('i18nn_8e01866868930d09'),
				//     "goodsLength":"",//this.$t('i18nn_2cba96917484569a'),
				//     "goodsWidth":"",//this.$t('i18nn_9e24e0e4745f4948'),
				//     "goodsHeight":"",//this.$t('i18nn_c5fa0857bc0df2d6'),
				//     "id":"",//"数据id"
				// },

				// formRules: {
				//   goodsSku: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   cusCode: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsName: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsNameEn: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsImg: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }],
				//   defCode: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   hashCode: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   declareNameCh: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   declareNameEn: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   declarePrice: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsWeight: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   weightUnit: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }],
				//   goodsLength: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsWidth: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   goodsHeight: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   volumeUnit: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'change' }],
				//   whFeeType: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				//   whFeeAmt: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// },
				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				// selectOption: {
				//   wh_weight_unit: [],
				//   wh_vol_unit: [],
				//   wh_goods_fee_type:[],
				//   statusList: [
				//     {
				//       value: '',
				//       label: this.$t('i18nn_16853bda54120bf1')
				//     },
				//     {
				//       value: '1',
				//       label: this.$t('i18nn_fdd8426d7b8869c5')
				//     },
				//     {
				//       value: '2',
				//       label: this.$t('i18nn_619d7e563f879811')
				//     },
				//     {
				//       value: '3',
				//       label: this.$t('i18nn_1dfb3a065d3ffe39')
				//     }
				//   ]
				// },
				//查询，排序方式
				filterData: {
					// "orderBy": "id_", //排序字段
					// "sortAsc": "desc", //desc降序，asc升序

					status: '',
					// plNo: '',
					sendId: '',
					goodsSku: '',
					goodsName: '',
					keyword: ""
				}
			}
		},
		watch: {
			sendId: function(newVal, oldVal) {
				console.log('watchKey');
				// if (newVal) {
				// console.log('watch openDateTime HyUpLoadImg.vue');
				this.initData();
				// }
			}
		},
		//创建时
		created() {
			// this.getPageData();

		},
		//编译挂载前
		mounted() {
			// this.getDicData();

			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.tableData = [];
				this.palletTableData = [];
				this.OutWarehouseTableData = [];
				if (this.sendId) {


					this.getPageData();
					this.getPalletListData(this.sendId);
					this.getOutWarehouseListData(this.sendId);
				}
				// this.getDicData();
			},
			//跳转页面
			// toPageUrl(name){
			//   this.$router.push({'name':name});
			// },
			//打开新增编辑，弹窗
			// openDioalog(formParm) {
			//   // console.log(formParm);
			//   this.dialogFormVisible = true;
			//   let form = Object.assign({}, formParm);
			//   console.log('form',form);
			//   // // 重置
			//   this.resetForm('form');
			//   if (null === formParm) { //新增

			//     this.form.weightUnit = '1';
			//     this.form.volumeUnit = '1';
			//     this.form.whFeeType = '1';

			//     this.dialogFormStatus = 0;
			//     // form.subUserId = null;
			//     // form.userSubUserId = null;
			//     // form.state = true;
			//     //浅拷贝、对象属性的合并
			//     this.form = form;

			//   } else { //修改
			//     this.dialogFormStatus = 1;
			//     // form.state = form.state === '0' ? true : false;

			//     //浅拷贝、对象属性的合并
			//     this.form = form;

			//   }
			//   // this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			//   //       type: 'warning',
			//   //       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//   //     });
			// },
			//打开编辑
			// openEdit(event, row, index) {
			//   event.stopPropagation();
			//   this.openDioalog(row, this.$t('FormMsg.Edit'));
			// },

			//删除
			// delAction(event, row) {
			//   this.$confirm(this.$t("FormMsg.confirm_Delete"), this.$t('tips.tipsTitle'), {
			//     // confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     // cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   })
			//     .then(() => {
			//       // this.$message({
			//       //   type: 'success',
			//       //   message: '删除成功!'
			//       // });
			//       this.delDataAction(event, row);
			//     })
			//     .catch(() => {
			//       // this.$message({
			//       //   type: 'info',
			//       //   message: this.$t('i18nn_2e58cb9b52e2a214')
			//       // });
			//     });
			// },
			//删除
			// delDataAction(event, row) {
			//   event.stopPropagation();
			//   console.log('delDataAction', row);
			//   // let parm = [];

			// //   if (!!row) {
			// //     //单条
			// //     parm = [row.id];
			// //   } else {
			// //     //多条
			// // //     let dataList = this.multipleSelection;
			// // //     if (dataList.length < 1) {
			// // //       this.$message.warning(this.$t('i18nn_7b80e66b535a3732'));
			// // //       return;
			// // //     }

			// // //     let dataListParm = dataList.map(v => v.id);
			// // //     parm = dataListParm;
			// //     // console.log('dataListParm', dataListParm);
			// //     // let dataParm = {
			// //     //   ids: dataListParm
			// //     // };
			// //   }

			//   this.postData(this.$urlConfig.WhMySkuDel+'/'+row.id, {}, () => {
			//     this.initData();
			//     //  this.$alert('this.$t('tips.submitSuccess')'tips.submitSuccess')', this.$t('tips.tipsTitle'), {
			//     //   type: 'success',
			//     //   confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     // });
			//     this.$message.success('删除成功！');
			//   });
			// },

			// hyUpLoadImg1: function(childValue) {
			//   // childValue就是子组件传过来的值
			//   this.form.goodsImg = childValue;
			// },

			//查看图片
			// openBigImg(event, imgsrc) {
			//   event.stopPropagation();
			//   console.log('openBigImg', imgsrc);
			//   this.dialogImgVisible = true;
			//   this.imgUrlBigShow = imgsrc;
			// },

			//点击状态为非出账弹出窗
			// noConfirm(){
			//   this.$alert(this.$t('i18nn_e741d17b1fd891c2')已出账this.$t('i18nn_d6f109b7bee2f33f')确认"操作，请核实！', this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			// },
			//提交信息
			// submitForm(formName) {

			//   this.$refs[formName].validate((valid) => {

			//     if (valid) {
			//       let formData = Object.assign({}, this.form);
			//       //浅拷贝、对象属性的合并
			//       if (0 === this.dialogFormStatus) {

			//         formData.id = null;
			//         formData.userId = this.UserInfo.id;

			//         this.postData(this.$urlConfig.WhMySkuAddUpdate, formData);

			//       } else {

			//         formData.userId = this.UserInfo.id;
			//         this.postData(this.$urlConfig.WhMySkuAddUpdate, formData);
			//       }

			//     } else {
			//       console.log('error submit!!');
			//       this.$alert(this.$t("tips.checkSubmitData"), this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//       return false;
			//     }
			//   });
			// },
			//重置输入框
			// resetForm(formName) {
			//   console.log(formName);
			//   // console.log(this.$refs[formName]);

			//   if (this.$refs[formName]) {
			//     this.$refs[formName].resetFields();

			//   } else {
			//     console.log('this.$refs[formName]',this.$refs[formName]);
			//   }
			// },

			//提交信息
			// postData(url, formData) {
			//   // let _this = this;
			//   this.loading = true;

			//   // formData.state = formData.state ? '0' : '1';
			//   this.$http.put(url, formData)
			//     .then(({ data }) => {
			//       console.log(this.$t('tips.submitSuccess'));
			//       console.log(data);
			//       
			//       this.loading = false;
			//       if (200 == data.code) {
			//         this.dialogFormVisible = false;
			//         this.getPageData();
			//         this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
			//           type: 'success',
			//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//         });
			//       } else {
			//         // if (!data.msg) {
			//         //   data.msg = this.$t('tips.submitError');
			//         // }
			//         this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
			//           type: 'warning',
			//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//         });
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log(this.$t("tips.submitError"));
			//       this.loading = false;
			//       this.$alert(this.$t("tips.submitRequestError"), this.$t('tips.tipsTitle'), {
			//         type: 'warning',
			//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//       });
			//     });
			// },
			//查询数据
			serPageData() {
				this.pagination.current_page = 1;
				this.getPageData();
			},
			//请求分页数据
			getPageData() {
				// let _this = this;
				this.loading_load = true;
				this.$http.put(this.$urlConfig.WhShipmentsListDetPageList, {
						// "sortAsc": this.filterData.sortAsc,
						// "orderBy": this.filterData.orderBy,
						"offset": (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						"limit": this.pagination.page_size, //当前页显示数目

						sendId: this.sendId ? this.sendId : null,

						goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
						keyword: this.filterData.keyword ? this.filterData.keyword : null,
						// putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
						// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
						// hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
						// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
						// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
					})
					.then(({
						data
					}) => {

						// console.log(this.$store.getters.getUserInfo);
						console.log("分页，请求成功");
						console.log(data);

						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);


					})
					.catch((error) => {
						console.log(error);
						console.log("分页，请求失败");
						this.loading_load = false;
					});
			},
			//打托完成图片
			getPalletListData(relationId) {
				this.loadingUpload = true;
				this.$http
					.get(this.$urlConfig.WhFileUploadList + '/' + relationId + '/70', {})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_4195755cd53e871a'));
						console.log(data);
						this.loadingUpload = false;
						if (200 == data.code) {
							this.palletTableData = data.rows;
						} else {
							this.$alert(data.msg ? data.msg : this.$t('tips.queryErrorFailed'), this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						this.loadingUpload = false;
						this.$alert(this.$t('tips.queryRequestFailed'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},
			//出库完成图片
			getOutWarehouseListData(relationId) {
				this.loadingUpload = true;
				this.$http
					.get(this.$urlConfig.WhFileUploadList + '/' + relationId + '/20', {})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_4195755cd53e871a'));
						console.log(data);
						this.loadingUpload = false;
						if (200 == data.code) {
							this.OutWarehouseTableData = data.rows;
						} else {
							this.$alert(data.msg ? data.msg : this.$t('tips.queryErrorFailed'), this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						this.loadingUpload = false;
						this.$alert(this.$t('tips.queryRequestFailed'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					});
			},
			//操作
			// sureBillAction(row,msg,state) {
			//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
			//   console.log(row);
			//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   }).then(() => {
			//     //确认
			//     let dataParm = {
			//       ids:[row.id],
			//       state:state,
			//     }
			//     this.updatePageData(dataParm, msg);
			//   }).catch(() => {

			//   });


			// },
			//更新特定数据,
			// updatePageData(dataParm, msg) {
			//   // let _this = this;
			//   this.loading_load = true;
			//   //浅拷贝、对象属性的合并
			//   let dataParmAss = Object.assign({}, dataParm);
			//   // dataParmAss.isDefault = 1;
			//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
			//     .then(({ data }) => {
			//       console.log(msg + "特定数据，请求成功");
			//       console.log(data);
			//       if (200 == data.code) {
			//         this.$message.success(msg + '成功！');
			//         this.getPageData();
			//       } else {
			//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("更新特定数据，请求失败");
			//       this.loading_load = false;
			//       this.$message.warning('' + msg + '失败,请重试！');
			//     });
			// },
			//选择数据后回调
			// selRow(event,row) {
			//   event.stopPropagation();
			//   this.$emit('selectRow',row)
			// },
			//查询数据字典
			// getDicData() {
			//   // let _this = this;
			//   // console.log(keyword);

			//   // this.loading_load = true;
			//   this.$http
			//     .put(this.$urlConfig.HyDicQueryList, ['wh_weight_unit', 'wh_vol_unit','wh_goods_fee_type'])
			//     .then(({ data }) => {
			//       console.log('查询数据字典，请求成功');
			//       console.log(data);
			//       if (200 == data.code && data.data) {
			//         this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			//         this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			//         this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			//       } else {
			//         if (!data.msg) {
			//           data.msg = this.$t("tips.submitError");
			//         }
			//         this.$message.warning(data.msg);
			//       }
			//     })
			//     .catch(error => {
			//       console.log(error);
			//       console.log('查询数据字典接口，请求失败');
			//       this.$message.error(this.$t("tips.submitRequestError"));
			//     });
			// }

		}
	}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	/deep/ .el-input-number,
	.el-select {

		// width: 100px;
		.el-input__inner {
			text-align: left;
		}
	}

	.form_msg {
		color: #e6a23c;
	}
</style>