<template>
	<div>
		<!-- <el-dialog :title="$t('i18nn_24c8f46012a25c89')" append-to-body :close-on-click-modal="false" :visible.sync="dialogAddVisible"
			width="1000px" top="0"> -->
		<el-drawer :wrapperClosable="false" :title="$t('i18nn_24c8f46012a25c89')" append-to-body :visible.sync="dialogAddVisible" :direction="'rtl'" size="800px">
			<!-- <div style=""> -->
			<!-- <div> -->
			<el-card style="" v-loading="loading">
				<div slot="header">
					<h3>{{$t('i18nn_e00cd157c0ac4d1f')}}</h3>
				</div>
				<el-form :model="form" label-width="100px" v-loading="loading_load">
					<el-form-item :label="$t('i18nn_20650eaf339e1fe6')" prop="">
						<el-input type="text" clearable v-model="form.name" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
					</el-form-item>
					<!-- <div style="border: 1px dashed #e5e5e5; padding: 10px 0;"> -->
					<el-form-item :label="$t('i18nn_8758fd50c87d6c9c')" prop="" required>
						<el-input clearable type="textarea" :rows="2" v-model="form.address" :maxlength="1000" show-word-limit  :placeholder="$t('FormMsg.Please_Enter')"
							style="width: 100%;"></el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_c2d9cc88561f8cdc')" prop="">
						<el-input type="text" clearable v-model="form.phone" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_e05b5d049b64b040')" prop="" required>
						<el-input type="text" clearable v-model="form.city" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_898a2833fbeaf9e4')" prop="" required>
						<el-input type="text" clearable v-model="form.state" :placeholder="$t('FormMsg.Please_Enter')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_b166e4e8fe9513fa')" prop="" required>
						<el-input type="text" clearable v-model="form.country" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('i18nn_c4913ab43009b365')" prop="" required>
						<el-input type="text" clearable v-model="form.zipCode"
							:placeholder="$t('FormMsg.Please_Enter')"></el-input>
					</el-form-item>
				</el-form>
			</el-card>


			<!--  -->

			<!-- <el-button type="primary" @click="submitForm()">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button> -->
			<!-- </div> -->
			<!-- </div> -->
			<div class="drawer-footer">
				<el-button type="primary" @click="submitForm()">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button>
				<!-- <el-button type="primary" plain @click="dialogAddVisible = false">{{ $t('i18nn_4e9fc68608c60999') }}
				</el-button> -->
			</div>
		</el-drawer>
		<!-- </el-dialog> -->
		
		<!-- 选择SKU -->
		<!-- <dialogWSku :openTime="skuInvOpenTime" :whNo="form.whNo" @sure="sureSel"></dialogWSku> -->

		<!-- 附件 -->
		<!-- <dialogFileUpload ref="dialogFileUpload" :folder="'storage/addValService/'" :fileKey="'addValServiceFile'"
			:openTime="FileUploadOpenTime" @success="FileUploadSuccess"></dialogFileUpload> -->

	</div>
</template>
<script>
	// import dialogWSku from '@/components/StorageCenter/WSkuProduct/dialogWSku.vue';
	
	// import dialogFileUpload from '@/components/StorageCenter/components/dialogFileUpload.vue';

	// import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	export default {
		props: {

			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			id: {
				default: function() {
					return '';
				},
				type: String
			}
			// relationId: {
			//   default: function() {
			//     return '';
			//   },
			//   type: String
			// }
		},
		components: {
			// whNoSelect,
			// dialogFileUpload,
			// dialogWSku
		},
		data() {
			return {
				loading: false,
				loading_load: false,
				dialogAddVisible: false,

				// FileUploadOpenTime: '',
				
				// skuInvOpenTime: '',
				// skuOpenIndex:'',
				
				form: {
					// isFba:"1",
					// fbaAddrCode:"",
					// name:"",
					// "country": null, //this.$t('i18nn_b166e4e8fe9513fa'),
					// "state": null, //"省/州",
					// "city": null, //this.$t('i18nn_e05b5d049b64b040'),
					// "postalCode": null, //this.$t('i18nn_c4913ab43009b365'),
					// "addr": null, //this.$t('i18nn_8758fd50c87d6c9c'),
					"phone": null, //this.$t('i18nn_c2d9cc88561f8cdc'),
					
					"name": "", //this.$t('i18nn_59764ba919e3d70d'),
					"address": "", //this.$t('i18nn_8758fd50c87d6c9c'),
					"city": "", //this.$t('i18nn_e05b5d049b64b040'),
					"state": "", //this.$t('i18nn_7cab79716236ebb0'),
					"zipCode": "", //this.$t('i18nn_c4913ab43009b365'),
					"country": "US", //"US"
					
				},

				selectOption: {
					// wh_op_add_service: [],
				},
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogAddVisible = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();

		},
		//编译挂载前
		mounted() {
			// this.initData();
			// this.getDicData();
		},
		methods: {
			initData() {
				this.dialogAddVisible = true;

				this.form = {
					"name": "", //this.$t('i18nn_59764ba919e3d70d'),
					"address": "", //this.$t('i18nn_8758fd50c87d6c9c'),
					"city": "", //this.$t('i18nn_e05b5d049b64b040'),
					"state": "", //this.$t('i18nn_7cab79716236ebb0'),
					"zipCode": "", //this.$t('i18nn_c4913ab43009b365'),
					"country": "US", //"US"
					"phone": null, //this.$t('i18nn_c2d9cc88561f8cdc'),
				};
				// this.$nextTick(() => {
				// 	this.$refs.whNoSelect.init("");
				// });
				
				// if (this.id) {
				// 	// this.tableData = [];
				// 	// this.DetData = {};
				// 	this.getDetPageData(this.id);
				// 	this.getAddServiceDetPageData(this.id);
				// }

			},
			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.form.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	// this.initData();
			// },

			//新增
			// addLe2() {
			// 	this.form.addServiceList.push({
			// 		"goodsSku":"",
			// 		"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
			// 		"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
			// 	});
			// },
			
			// //删除
			// delLe2(event, index) {
			// 	event.stopPropagation();
			// 	this.form.addServiceList.splice(index, 1);
			// },
			
			// 新建-表格
			// addRow(event,index) {
			// 	event.stopPropagation();
			// 	if(!this.form.whNo){
			// 		this.$message.warning(this.$t('FormMsg.Select_long_wh'))
			// 		return;
			// 	}
			// 	// this.skuInvWhNo = this.form.senderAddr;
			// 	this.skuInvOpenTime = new Date().getTime();
			// 	this.skuOpenIndex = index;
			// },
			// selSkuInv(val){
			// 	val.forEach(item=>{
			// 		let hasSku = this.form.addServiceList.some(item2=> item.goodsSku == item2.goodsSku);
			// 		//是否已经存在此SKU
			// 		if(!hasSku){//不存在
			// 			this.form.addServiceList.push({
			// 				goodsSku: item.goodsSku,
			// 				"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
			// 				"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
			// 			});
			// 		}
			// 	})
			// },
			// sureSel(val){
			// 	// val.forEach((item,index)=>{
			// 	// 	// let hasSku = this.form.addServiceList.some(item2=> item.goodsSku == item2.goodsSku);
			// 	// 	//是否已经存在此SKU
			// 	// 	// if(!hasSku){//不存在
			// 	// 		if(0==index){//第一条直接录入
			// 	// 			// this.form.addServiceList[this.skuOpenIndex].goodsSku = item.goodsSku;
			// 	// 			let oneAdd = this.form.addServiceList[this.skuOpenIndex];
			// 	// 			oneAdd.goodsSku = item.goodsSku;
			// 	// 			this.$set(this.form.addServiceList,this.skuOpenIndex,oneAdd)
			// 	// 		} else {//其他插入新数据
			// 	// 			this.form.addServiceList.push({
			// 	// 				goodsSku: item.goodsSku,
			// 	// 				"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
			// 	// 				"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
			// 	// 			});
			// 	// 		}
			// 	// 	// }
			// 	// })
			// },
			
			// clearRow(){
			// 	this.form.addServiceList = [];
			// },
			
			

			//添加附件
			// openAddFile() {
			// 	// this.FileUploadOpenIndex = index;
			// 	this.FileUploadOpenTime = new Date().getTime();
			// },
			// FileUploadSuccess(data) {
			// 	console.log('FileUploadSuccess', data);
			// 	let fileList = data.map(item => {
			// 		return {
			// 			fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
			// 			url: item.filePath, //this.$t('i18nn_8758fd50c87d6c9c')
			// 			// type: "90"
			// 		}
			// 	});
			// 	// this.form.attachments = fileList;
			// 	this.form.attachments = this.form.attachments.concat(fileList);
			// 	this.$forceUpdate();
			// },
			// //删除附件
			// delFile(event, index) {
			// 	event.stopPropagation();
			// 	this.form.attachments.splice(index, 1);
			// 	this.$forceUpdate();
			// },
			// fomatterQaTypeToBizType(qaType){
			// 	// bizType
			// 	//code: "SDS", codeText: this.$t('i18nn_684a2afb069b6016'),
			// 	//code: "SRE", codeText: this.$t('i18nn_199f5bded0b23976'), 
			// 	//code: "STF", codeText: "自提/快递", 
			// 	//code: "SCC", codeText: this.$t('i18nn_c39fd3d48389bea7'), 
			// 	//code: "STR", codeText: "中转/转运"

			// 	// qaBizType
			// 	// code: "10", codeText: this.$t('i18nn_684a2afb069b6016'),
			// 	// code: "20", codeText: this.$t('i18nn_6373cb1d1204d580'),
			// 	// code: "30", codeText: this.$t('i18nn_57495c18e2baebdc'), 
			// 	// code: "40", codeText: this.$t('i18nn_21e42bbe9894aafc'), 
			// 	// code: "99", codeText: this.$t('i18nn_97e49d00bcc4c17c'),
			// 	let bizType = '';
			// 	if('10'==qaType){
			// 		bizType = 'SDS';
			// 	} else if('20'==qaType){
			// 		bizType = 'STF';
			// 	} else if('30'==qaType){//已废弃
			// 		bizType = 'STR';
			// 	} else if('40'==qaType){
			// 		bizType = 'SCC';
			// 	} else if('50'==qaType){
			// 		bizType = 'SRE';
			// 	}
			// 	return bizType;
			// },
			
			//请求分页数据
			// getDetPageData(id) {
			// 	// let _this = this;
			// 	this.loading_load = true;
			// 	this.$http
			// 		// .get(this.$urlConfig.WhWorkOrderDet + '/' + id, {})
			// 		.get(this.$urlConfig.WhWorkOrderEditDet + '/' + id, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			// console.log(this.$store.getters.getUserInfo);
			// 			console.log('分页，请求成功');
			// 			console.log(data);
			// 			
			// 			this.loading_load = false;
			// 			//表格显示数据
			// 			// this.form = data.data;
			// 			// this.form = {
			// 			// 	"remark": "",
			// 			// 	title:"",
			// 			// 	whNo: "",
			// 			// 	attachments: [],
			// 			// 	"addServiceList": [
			// 			// 		// {
			// 			// 		// 	"serviceType": null, //this.$t('i18nn_f1d6776242105f03'),
			// 			// 		// 	"goodsSku":"",
			// 			// 		// 	"quantity": null, //this.$t('i18nn_f9db93b87e08763b')
			// 			// 		// },
			// 			// 	]
			// 			// };
			// 			if(200==data.code&&data.data) {
			// 				this.form.id = data.data.id;
			// 				this.form.remark = data.data.remark;
			// 				this.form.title = data.data.title;
							
			// 				this.form.whNo = data.data.whNo;
			// 				this.form.attachments = data.data.attachments;
			// 			}
			// 			// this.$nextTick(() => {
			// 			// 	this.$refs.whNoSelect.init("");
			// 			// });
			// 			this.$nextTick(() => {
			// 				this.$refs.whNoSelect.init(this.form.whNo);
			// 			})
			// 			//当前数据总条数
			// 			// this.pagination.total = parseInt(data.total);
			// 			//当前页数
			// 			// this.pagination.current_page = parseInt(data.current);
			// 			//当前页条数
			// 			// this.pagination.page_size = parseInt(data.size);
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('分页，请求失败');
			// 			this.loading_load = false;
			// 		});
			// },
			//请求分页数据
			// getAddServiceDetPageData(id) {
			// 	// this.tableData = [];
			// 	// let _this = this;
			// 	this.loading = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhWorkAddServiceDet + '/' + id, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			// console.log(this.$store.getters.getUserInfo);
			// 			console.log('分页，请求成功');
			// 			console.log(data);
			// 			
			// 			this.loading = false;
			// 			if(200==data.code && data.rows){
			// 				this.form.addServiceList = data.rows;
			// 			}
						
			// 			//表格显示数据
			// 			// this.tableData = data.rows;
			// 			//当前数据总条数
			// 			// this.pagination.total = parseInt(data.total);
			// 			//当前页数
			// 			// this.pagination.current_page = parseInt(data.current);
			// 			//当前页条数
			// 			// this.pagination.page_size = parseInt(data.size);
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('分页，请求失败');
			// 			this.loading = false;
			// 		});
			// },
			
			//提交信息
			submitForm() {
				// let bizType = this.fomatterQaTypeToBizType(this.qaType);
				let formData = Object.assign({}, this.form);
				// formData.relationId = this.relationId;
				// formData.bizType = bizType;
				this.postData(this.$urlConfig.WhTransferAddressAdd, formData, '', () => {
					this.$emit('success');
					this.dialogAddVisible = false;
					
					// this.getPageData();
					// this.$message.success(this.$t('9f30371831a98237'));
				});
			},
			//提交数据
			postData(url, formData, type, callback) {
				// let _this = this;
				this.loading = true;

				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							callback();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('i18nn_cc62f4bf31d661e3'), {
								type: 'warning'
							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loading = false;
						this.$alert('抱歉，提交失败,请重试！', this.$t('i18nn_cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_op_add_service'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_op_add_service = data.data['wh_op_add_service'];
			// 			} else {
			// 				// if (!data.msg) {
			// 				//   data.msg = this.$t("tips.submitError");
			// 				// }
			// 				// this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			// this.$message.error(this.$t("tips.submitRequestError"));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
