<template>
	<div>
		<!-- <el-dialog append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1000px" top="0"> -->
		<el-drawer :wrapperClosable="false" append-to-body :visible.sync="dialogFile" :direction="'rtl'" size="500px">
			<template slot="title">
				<div><span>{{$t('i18nn_8b3dbf4f5793930b')}}</span></div>
			</template>
			<div class="" v-loading="loading_load">
				<!-- <div> -->
				<el-form ref="form" :model="form" label-width="120px" style="width: 100%;" v-loading="loading"
					:element-loading-text="$t('i18nn_1b4525c800280581')">

					<!-- <el-form-item prop="" required>
						<template slot="label">
							<span>
								<span>{{$t('i18nn_e1d76a34da86da92')}}</span><span>FBA</span>
							</span>
						</template>
						<el-radio-group v-model="form.isFba" :disabled="true">
							<el-radio :label="'0'">{{$t('i18nn_e76d885ae1c74d4f')}}</el-radio>
							<el-radio :label="'1'">{{$t('i18nn_02ccd2cf723f9272')}}</el-radio>
						</el-radio-group>
					</el-form-item> -->

					<!-- <div style="border: 1px dashed #e5e5e5; padding: 10px 0;"> -->
					<div v-show="'1'==row.isFba">
						<el-form-item prop="" >
							<template slot="label">
								<span>
									FBA<span>{{$t('i18nn_5acbec83efb27445')}}</span>
								</span>
							</template>
							<el-input size="" clearable v-model="form.fbaAddrCode">
								<el-button slot="append" type="primary" icon="el-icon-search"
									@click="searchFbaAddress()">{{$t('i18nn_9eefb7335988c499')}}</el-button>
							</el-input>
							<!-- <el-button type="primary" icon="el-icon-search" @click="searchFbaAddress()">{{$t('i18nn_ffe3865fea625e35')}}</el-button> -->

						</el-form-item>

						<el-form-item :label="$t('i18nn_8758fd50c87d6c9c')" prop="">
							<!-- <el-input :disabled="true" type="textarea" :rows="2" v-model="form.addr" :maxlength="1000" show-word-limit
								style="width: 220px;"></el-input> -->
							{{form.addr}},<br />
							{{form.city}},{{form.state}},{{form.postalCode}},<br />
							{{form.country}},<br />
							<div v-if="form.phone">({{form.phone}})</div>
						</el-form-item>
						<!-- <el-form-item :label="$t('i18nn_5a9fb0915ecea987')" prop=""> -->
						<!-- <el-input :disabled="true" type="text" clearable v-model="form.phone">
							</el-input> -->
						<!-- {{form.phone}} -->
						<!-- </el-form-item> -->
						<!-- <el-form-item :label="$t('i18nn_e05b5d049b64b040')" prop=""> -->
						<!-- <el-input :disabled="true" type="text" clearable v-model="form.city">
							</el-input> -->
						<!-- {{form.city}} -->
						<!-- </el-form-item> -->
						<!-- <el-form-item :label="$t('i18nn_898a2833fbeaf9e4')" prop=""> -->
						<!-- <el-input :disabled="true" type="text" clearable v-model="form.state">
							</el-input> -->
						<!-- {{form.state}} -->
						<!-- </el-form-item> -->
						<!-- <el-form-item :label="$t('i18nn_b166e4e8fe9513fa')" prop=""> -->
						<!-- <el-input :disabled="true" type="text" clearable v-model="form.country"></el-input> -->
						<!-- {{form.country}} -->
						<!-- </el-form-item> -->
						<!-- <el-form-item :label="$t('i18nn_c4913ab43009b365')" prop=""> -->
						<!-- <el-input :disabled="true" type="text" clearable v-model="form.postalCode"></el-input> -->
						<!-- {{form.postalCode}} -->
						<!-- </el-form-item> -->
					</div>

					<div v-show="'0'==row.isFba">
						<el-form-item :label="$t('i18nn_e520453b3f22ad66')" prop="">
							<!-- <transferAddressSel ref="transferAddressSel" @changeData="changeTransferAddress"
								@addSuccess="saveToAddress">
							</transferAddressSel> -->
							<!-- <span style="padding-left: 10px;">
								<el-checkbox v-model="isSaveAddress">{{$t('i18nn_4ce9979bfb6576d9')}}</el-checkbox>
							</span> -->
							<el-tag>
								{{form.code}}
							</el-tag>
							<!-- <el-input type="text" disabled v-model="form.toWhNo" :placeholder="$t('FormMsg.Please_select')"
								style="width: 150px;"></el-input> -->
							<el-button type="primary" size="small" @click="openSelAddr()"
								icon="el-icon-magic-stick">{{$t('i18nn_7fb6ada66a1fccca')}}</el-button>
							<el-button type="warning" size="small" @click="clearAddrCode()"
								icon="el-icon-close">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button>
							<!-- <el-button type="warning" size="small" @click="clearAddrCode()"
								icon="el-icon-close">{{$t('i18nn_4c4e546aa3683ff5')}}</el-button> -->
						</el-form-item>
						<!-- <el-form-item :label="$t('i18nn_59764ba919e3d70d')" prop="" > -->
						<!-- <el-input type="text" clearable v-model="addressName"
								:placeholder="$t('i18nn_c6bf739e696c2bfc')"></el-input> -->
						<!-- {{form.code}} -->
						<!-- </el-form-item> -->
						<!-- <div style="border: 1px dashed #e5e5e5; padding: 10px 0;"> -->
						<el-form-item :label="$t('i18nn_8758fd50c87d6c9c')" prop="">
							<!-- <el-input clearable type="textarea" :rows="2" v-model="form.addr" :maxlength="1000" show-word-limit
								:placeholder="$t('FormMsg.Please_Enter')" style="width: 220px;"></el-input> -->
							<!-- {{form.addr}} -->
							{{form.name}},<br />
							{{form.addr}},<br />
							{{form.city}},{{form.state}},{{form.postalCode}},<br />
							{{form.country}},<br />
							<div v-if="form.phone">({{form.phone}})</div>
						</el-form-item>
						<!-- <el-form-item :label="$t('i18nn_5a9fb0915ecea987')" prop=""> -->
						<!-- <el-input type="text" clearable v-model="form.phone" :placeholder="$t('FormMsg.Please_Enter')">
							</el-input> -->
						<!-- {{form.phone}} -->
						<!-- </el-form-item> -->
						<!-- <el-form-item :label="$t('i18nn_e05b5d049b64b040')" prop="" > -->
						<!-- <el-input type="text" clearable v-model="form.city" :placeholder="$t('FormMsg.Please_Enter')">
							</el-input> -->
						<!-- {{form.city}} -->
						<!-- </el-form-item> -->
						<!-- <el-form-item :label="$t('i18nn_898a2833fbeaf9e4')" prop="" > -->
						<!-- <el-input type="text" clearable v-model="form.state" :placeholder="$t('FormMsg.Please_Enter')">
							</el-input> -->
						<!-- {{form.state}} -->
						<!-- </el-form-item> -->
						<!-- <el-form-item :label="$t('i18nn_b166e4e8fe9513fa')" prop="" > -->
						<!-- <el-input type="text" clearable v-model="form.country"
								:placeholder="$t('FormMsg.Please_Enter')"></el-input> -->
						<!-- {{form.country}} -->
						<!-- </el-form-item> -->
						<!-- <el-form-item :label="$t('i18nn_c4913ab43009b365')" prop="" > -->
						<!-- <el-input type="text" clearable v-model="form.postalCode"
								:placeholder="$t('FormMsg.Please_Enter')"></el-input> -->
						<!-- {{form.postalCode}} -->
						<!-- </el-form-item> -->
					</div>
					<!-- </div> -->





				</el-form>
				<!-- </div> -->
			</div>

			<!-- </div> -->

			<!-- </div> -->
			<div class="drawer-footer">
				<!-- <el-button type="primary" plain @click="dialogFile = false">{{ $t('FormMsg.Close') }}</el-button> -->
				<el-button type="primary" icon="el-icon-tickets" @click="submitForm()">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-drawer>

		<!-- 地址 -->
		<TransportAddressListDialog :openTime="TransportAddressOpenTime" @selSuccess="addrSelSuccess">
		</TransportAddressListDialog>
	</div>
	<!-- </el-dialog> -->
</template>
<script>
	// import transferAddressSel from '@/components/StorageCenter/components/transferAddressSel.vue';
	import TransportAddressListDialog from '@/components/StorageCenter/Transport/TransportAddressListDialog.vue';
	import {
		clearObjectVal
	} from '@/utils/common.js';


	export default {
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			row: {
				default: function() {
					return {};
				},
				type: Object
			},
		},
		components: {
			// transferAddressSel,
			TransportAddressListDialog
		},
		data() {
			return {

				dialogFile: false,

				loading: false,

				TransportAddressOpenTime: '',

				loading_load: false,
				// tableData: [],
				// addressName: "",
				form: {
					// isFba: "1",
					fbaAddrCode: "",
					"name":null,
					"country": null, //this.$t('i18nn_b166e4e8fe9513fa'),
					"state": null, //"省/州",
					"city": null, //this.$t('i18nn_e05b5d049b64b040'),
					"postalCode": null, //this.$t('i18nn_c4913ab43009b365'),
					"addr": null, //this.$t('i18nn_8758fd50c87d6c9c'),
					"phone": null, //this.$t('i18nn_c2d9cc88561f8cdc'),
				},

			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.dialogFile = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();

			// this.initData();
		},
		methods: {
			initData() {
				// this.addressName = "";


				this.form = clearObjectVal(this.form);
				// this.form.isFba = "1";
				if ('0' == this.row.isFba) {
					// this.form.isFba = this.row.isFba;
					// "country": null, //this.$t('i18nn_b166e4e8fe9513fa'),
					// "state": null, //"省/州",
					// "city": null, //this.$t('i18nn_e05b5d049b64b040'),
					// "postalCode": null, //this.$t('i18nn_c4913ab43009b365'),
					// "addr": null, //this.$t('i18nn_8758fd50c87d6c9c'),
					// "phone": null, //this.$t('i18nn_c2d9cc88561f8cdc')
					this.form.code = this.row.toWhNo;
					// this.form.name = 
					// this.form.country = this.row.country;
					// this.form.state = this.row.state;
					// this.form.city = this.row.city;
					// this.form.postalCode = this.row.postalCode;
					// this.form.addr = this.row.addr;
					// this.form.phone = this.row.phone;

					this.getDataByCode(this.form.code)

					// this.$nextTick(() => {
					// 	this.$refs.transferAddressSel.init('');
					// 	if (!!this.row.toWhNo) {
					// 		this.$refs.transferAddressSel.sel(this.row.toWhNo);
					// 	}
					// });

				} else if ('1' == this.row.isFba) {
					// this.form.isFba = this.row.isFba;
					this.form.fbaAddrCode = this.row.toWhNo;
					this.searchFbaAddress();
				} else {
					// this.form.isFba = '1';
					this.form.fbaAddrCode = '';
				}

				// this.$nextTick(()=>{
				// 	this.$refs.transferAddressSel.init('');
				// });
				// this.getDicData();
			},
			close() {
				this.dialogFile = false;
			},

			// changeTransferAddress(data) {
			// 	console.log('changeTransferAddress', data);
			// 	// this.form.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	// this.initData();
			// 	this.addressName = data.name;
			// 	this.form.addr = data.address;
			// 	this.form.city = data.city;
			// 	this.form.state = data.state;
			// 	this.form.country = data.country;
			// 	this.form.postalCode = data.zipCode;

			// 	// "name": this.addressName, //this.$t('i18nn_59764ba919e3d70d'),
			// 	// "address": this.form.addr, //this.$t('i18nn_8758fd50c87d6c9c'),
			// 	// "city": this.form.city, //this.$t('i18nn_e05b5d049b64b040'),
			// 	// "state": this.form.state, //this.$t('i18nn_7cab79716236ebb0'),
			// 	// "zipCode": this.form.postalCode, //this.$t('i18nn_c4913ab43009b365'),
			// 	// "country": this.form.country, //"US"
			// },

			//查询FBA地址
			searchFbaAddress() {
				console.log('searchFbaAddress');
				if ('' == this.form.fbaAddrCode) {
					this.$message.warning("请输入FBA仓库号");
					return;
				}
				this.form.country = "";
				this.form.state = "";
				this.form.city = "";
				this.form.postalCode = "";
				this.form.addr = "";
				this.form.phone = "";
				this.form.name = "";
				// this.form = clearObjectVal(this.form);
				
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhTransferQueryFba + '?fbaCode=' + this.form.fbaAddrCode, {})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_776496ec446f21f9'));
						console.log(data);
						this.loading = false;
						if (200 == data.code && data.data) {
							// "country": null, //this.$t('i18nn_b166e4e8fe9513fa'),
							// "state": null, //"省/州",
							// "city": null, //this.$t('i18nn_e05b5d049b64b040'),
							// "postalCode": null, //this.$t('i18nn_c4913ab43009b365'),
							// "addr": null, //this.$t('i18nn_8758fd50c87d6c9c'),
							// "phone": null, //this.$t('i18nn_c2d9cc88561f8cdc'),
							let addrData = data.data;
							this.form.country = addrData.country;
							this.form.state = addrData.state;
							this.form.city = addrData.city;
							this.form.postalCode = addrData.zipCode;
							this.form.addr = addrData.address;
							this.form.phone = addrData.phone;

							// this.fbaAddress = data.data;

						} else {
							// if (!data.msg) {
							// 	data.msg = this.$t('i18nn_d74d0bd89431d6d5');
							// }
							// this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.errorData'));
						this.loading = false;
						// this.$message.error('查询失败!');
					});
			},

			// submitData(fbaAddrCode) {
			// 	// console.log('form', this.form);
			// 	let formData = {
			// 		"id": this.row.id,
			// 		"outWhCode": fbaAddrCode
			// 	};
			// 	// if(fbaAddrCode){
			// 	// formData.outWhCode = fbaAddrCode;
			// 	// }
			// 	console.log('formData', formData);
			// 	// if (this.editId) {
			// 	// formData.id = this.editId;
			// 	this.postData(this.$urlConfig.WhTransferUpdataAddress, formData, () => {
			// 		console.log('submitSuccess');

			// 		this.$message.success(this.$t('i18nn_bc868e024b80d2e3'));
			// 		this.dialogFile = false;

			// 		this.$emit('success');
			// 		// this.goBack();
			// 	});
			// 	// } else {
			// 	// 	this.postData(this.$urlConfig.WhTransferOutAdd, formData, () => {
			// 	// 		console.log('submitSuccess');
			// 	// 		// this.$emit('submitSuccess');
			// 	// 		this.goBack();
			// 	// 	});
			// 	// }
			// },
			//提交信息
			submitForm() {
				//不是FBA
				if ('0' == this.row.isFba) {
					this.$emit('success', this.form.code);
					// this.saveAddress();
				} else {
					this.$emit('success', this.form.fbaAddrCode);
					// this.dialogFile = false;
				}
			},

			// saveAddress() {
			// 	this.$nextTick(() => {
			// 		let id = this.$refs.transferAddressSel.getId();
			// 		let address_formData = {
			// 			"id": id ? id : null, //"数据ID",
			// 			"name": this.addressName, //this.$t('i18nn_59764ba919e3d70d'),
			// 			"address": this.form.addr, //this.$t('i18nn_8758fd50c87d6c9c'),
			// 			"city": this.form.city, //this.$t('i18nn_e05b5d049b64b040'),
			// 			"state": this.form.state, //this.$t('i18nn_7cab79716236ebb0'),
			// 			"zipCode": this.form.postalCode, //this.$t('i18nn_c4913ab43009b365'),
			// 			"country": this.form.country, //"US"
			// 			// phone: this.form.phone, //"phone"
			// 		};
			// 		this.$refs.transferAddressSel.addData(address_formData);
			// 	})
			// },

			//保存地址回调
			// saveToAddress(data) {
			// 	console.log('saveToAddress', data);
			// 	if (data && 200 == data.code && data.data && data.data.code) {
			// 		// this.submitData(data.data.code);
			// 		this.$emit('success', data.data.code);
			// 		// this.dialogFile = false;
			// 	} else {
			// 		// this.submitData();
			// 		if (data && data.msg) {
			// 			// this.$message.warning(data.msg);
			// 			this.$alert(data.msg, this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			});
			// 		} else {
			// 			// this.$message.warning(this.$t('i18nn_771752e23324e1c4'));
			// 			this.$alert(this.$t('i18nn_771752e23324e1c4'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			});
			// 		}

			// 	}
			// },

			//清空地址
			clearAddrCode() {
				// this.form.toWhNo = "";
				// this.form.trAddrInfo = "";
				this.form = clearObjectVal(this.form);
				// this.form.id = null;
				// this.form.name = null;
				// this.form.code = null;
				// this.form.addr = null;
				// this.form.city = null;
				// this.form.state = null;
				// this.form.country = null;
				// this.form.postalCode = null;
			},
			//打开地址选择
			openSelAddr(index) {
				// this.TransportOpenIndex = index;
				this.TransportAddressOpenTime = new Date().getTime();
			},
			addrSelSuccess(data) {
				// this.form.toWhNo = data.code;
				// this.form.trAddrInfo = [data.address, data.city, data.state, data.country, data.zipCode].join(',');
				this.form.id = data.id;
				this.form.code = data.code;
				this.form.name = data.name;
				
				this.form.addr = data.address;
				this.form.city = data.city;
				this.form.state = data.state;
				this.form.country = data.country;
				this.form.postalCode = data.zipCode;
				this.form.phone = data.phone;
			},

			//查询单个
			getDataByCode(code) {
				this.loading = true;
				this.$http
					.put(this.$urlConfig.WhTransferAddressPageList, {
						code: code, //去除空格
						offset: 0,
						limit: 100
					})
					.then(({
						data
					}) => {
						console.log('名称搜索，请求成功');
						console.log(data);

						this.loading = false;
						if (200 == data.code && data.rows.length == 1) {
							// this.list = data.rows;
							let addrData = data.rows[0];
							this.code = addrData.id;

							// 	// this.initData();
							// this.addressName = addrData.name;
							this.form.name = addrData.name;
							this.form.addr = addrData.address;
							this.form.city = addrData.city;
							this.form.state = addrData.state;
							this.form.country = addrData.country;
							this.form.postalCode = addrData.zipCode;
							this.form.phone = addrData.phone;
							// this.$emit('changeData', data.rows[0]);
						} else {
							// this.$message.warning(data.msg ? data.msg : this.$t('tips.queryErrorFailed'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('搜索，请求失败');
						// this.$message.error(this.$t('tips.queryRequestFailed'));
						this.loading = false;
					});
			},

			//提交信息
			// postData(url, formData, callback) {
			// 	// HttpTypelet _this = this;
			// 	this.loading = true;
			// 	// this.loading_load = true;
			// 	let HttpType = {};
			// 	// if ('delete' == type) {
			// 	// 	HttpType = this.$http.delete(url, formData);
			// 	// } else {
			// 	HttpType = this.$http.put(url, formData);
			// 	// }
			// 	HttpType.then(({
			// 		data
			// 	}) => {
			// 		console.log(this.$t('i18nn_bc868e024b80d2e3'));
			// 		console.log(data);
			// 		this.loading = false;
			// 		if (200 == data.code) {
			// 			// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
			// 			// 	type: 'success'
			// 			// });
			// 			callback();
			// 			// }
			// 		} else {
			// 			// if (!data.msg) {
			// 			//   data.msg = this.$t('tips.submitError');
			// 			// }
			// 			this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			});
			// 		}
			// 	}).catch(error => {
			// 		console.log(error);
			// 		console.log(this.$t('tips.submitError'));
			// 		this.loading = false;
			// 		// this.loading_load = false;
			// 		this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 			//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 		});
			// 	});
			// },

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>